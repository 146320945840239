import React, { useRef, useState, useEffect } from 'react';
import { Text } from 'react-native-magnus';
import './Map.css';
import MapContext from './MapContext';
import * as ol from 'ol';
import XYZ from 'ol/source/XYZ';
import TileLayer from 'ol/layer/Tile';
import { useFertilitiesFromArea } from '../../hooks/FertilityFromArea';
import { useSatelliteFromArea } from '../../hooks/SatelliteFromArea';
import { MAP_TYPE } from '../MapComponent';

import { Point } from 'ol/geom';
import { transform, fromLonLat, toLonLat } from 'ol/proj';
import { Style, Icon, Text as OlTextStyle, Fill, Stroke } from 'ol/style';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import Overlay from 'ol/Overlay';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';

interface MapProps {
  children: React.ReactNode;
  zoom: number;
  center: Array<number>;
  onClick: Function;
  hasToCenterFarm: boolean;
  setHasToCenterFarm: Function;
  mapType: MAP_TYPE;
  setLocation: (args: any) => void;
  isModalReportOpen: boolean;
}

const Map = ({
  children,
  zoom,
  center,
  hasToCenterFarm,
  setHasToCenterFarm,
  mapType,
  setLocation,
  isModalReportOpen,
}: MapProps) => {
  const mapRef = useRef();
  const [map, setMap] = useState<any>(null);
  const { selectedFertility } = useFertilitiesFromArea();
  const { selectedSatellite } = useSatelliteFromArea();

  let startTime: number;
  let endTime: number;

  useEffect(() => {
    const key = 'StUDEvodJ8hd93H2kUID'; // MAPTILER key for change map view
    const attributions =
      '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> ' +
      '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';
    const roadLayer = new TileLayer({
      source: new XYZ({
        attributions: attributions,
        url: `https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=${key}`,
        tileSize: 512,
        maxZoom: 22,
      }),
    });

    const aerialLayer = new TileLayer({
      source: new XYZ({
        attributions: attributions,
        url: `https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=${key}`,
        maxZoom: 20,
      }),
    });
    let options = {
      view: new ol.View({ center, zoom }),
      layers: [aerialLayer],
      controls: [],
      overlays: [],
    };

    let mapObject = new ol.Map(options);
    mapObject.setTarget(mapRef.current);
    if (mapType === MAP_TYPE.SATELLITE) {
      mapObject.setLayers([aerialLayer]);
    }
    if (mapType === MAP_TYPE.STREET) {
      mapObject.setLayers([roadLayer]);
    }
    setMap(mapObject);

    return () => mapObject.setTarget(undefined);
  }, [mapType]);

  useEffect(() => {
    if (!map) return;

    map.getView().setZoom(zoom);
  }, [zoom]);

  useEffect(() => {
    if (!map) return;

    if (selectedFertility || selectedSatellite) {
      map.getView().setCenter(center);
      map.getView().setZoom(15);
    }

    if ((selectedFertility || selectedSatellite) && hasToCenterFarm) {
      map.getView().setCenter(center);
      setHasToCenterFarm(false);
      map.getView().setZoom(15);
    }

    if (!selectedFertility && !selectedSatellite) {
      map.getView().setCenter(center);
      map.getView().setZoom(15);
    }
  }, [center]);

  useEffect(() => {
    if (!map) return;

    map.on('pointermove', function () {});

    map.on('pointerdown', function () {
      startTime = new Date().getTime();
    });
    map.on('pointerup', function () {
      endTime = new Date().getTime();
      console.log(endTime - startTime);
    });

    if (isModalReportOpen) {
      map.on('singleclick', (evt: any) => {
        map
          .getOverlays()
          .getArray()
          .forEach(function (overlay) {
            map.removeOverlay(overlay);
          });

        const featurePixel = map.forEachFeatureAtPixel(
          evt.pixel,
          (feature: any) => {
            return feature.getProperties();
          },
          {
            hitTolerance: 1,
          },
        );

        //  if (!featurePixel) return;

        let features = [];

        const [longitude, latitude] = evt.coordinate;

        const location = toLonLat([longitude, latitude]);
        setLocation({
          coords: {
            latitude: location[1],
            longitude: location[0],
          },
        });

        const iconFeature = new ol.Feature({
          geometry: new Point([longitude, latitude]),
        });

        const iconStyle = new Style({
          image: new Icon({
            anchor: [0.5, 1],
            src: 'http://cdn.mapmarker.io/api/v1/pin?text=P&size=30&hoffset=1',
          }),
        });

        iconFeature.setStyle(iconStyle);
        features.push(iconFeature);

        const vectorSource = new VectorSource({
          features: features,
        });

        const vectorLayer = new VectorLayer({
          source: vectorSource,
        });

        vectorLayer.setZIndex(999);

        map.addLayer(vectorLayer);

        return;
      });
    }
  }, [map, isModalReportOpen]);

  return (
    <MapContext.Provider value={{ map }}>
      <div ref={mapRef} className="ol-map">
        {children}
      </div>
    </MapContext.Provider>
  );
};

export default Map;
