import React, { useRef, useState, useEffect } from 'react';

import MapComponent from '../../../components/MapComponent';
import { ModalTalhoes } from '../../../components/ModalTalhoes';
import { ModalFazendas } from '../../../components/ModalFazendas';
import { ModalAgricultores } from '../../../components/ModalAgricultores';
import { ModalTalhaoSelected } from '../../../components/ModalTalhaoSelected';

import { useAuth } from '../../../hooks/Auth';
import { userRoles } from '../../../services/api/Auth';
import FlatListTop, { TopFlatList } from '../../../components/FlatListTop';
import ModalReport from '../../../components/ModalReport';
import { useRoute } from '@react-navigation/core';
import CustomBottomSheet from './CustomBottomSheet';

interface RouteParams {
  params?: {
    openModalReport: boolean;
  };
}

export enum Routes {
  FARMER = 'Farmer',
  FARM = 'Farm',
  AREA = 'Area',
  AREA_SELECTED = 'AreaSelected',
  REPORT = 'Report',
}

export function AgricultorScreen() {
  const modalizeRef = useRef(null);

  const modalizeFarmerRef = useRef(null);
  const modalizeFarmRef = useRef(null);
  const modalizeAreaRef = useRef(null);
  const modalizeAreaSelectedRef = useRef(null);
  const modalizeReport = useRef(null);
  const [location, setLocation] = useState({
    coords: { latitude: -47.92972, longitude: -15.77972 },
  });

  const route = useRoute() as RouteParams;

  const { params } = route;

  const { userGrants } = useAuth();
  const { role } = userGrants;

  const [modalOpen, setModalOpen] = useState(role && role === userRoles.FARMER ? Routes.FARM : Routes.FARMER);
  const [selectedId, setSelectedId] = useState(TopFlatList.Fertility);

  const navigate = (route: Routes) => {
    switch (route) {
      case Routes.FARMER:
        setModalOpen(Routes.FARMER);
        break;

      case Routes.FARM:
        setModalOpen(Routes.FARM);
        break;

      case Routes.AREA:
        setModalOpen(Routes.AREA);
        break;

      case Routes.AREA_SELECTED:
        setModalOpen(Routes.AREA_SELECTED);
        modalizeRef.current?.slideDown();
        break;
      default:
        break;
    }
  };

  const closeAll = () => {
    modalizeFarmerRef.current?.close();
    modalizeFarmRef.current?.close();
    modalizeAreaRef.current?.close();
    modalizeAreaSelectedRef.current?.close();
    modalizeReport.current?.close();
  };

  const openModalReport = () => {
    if (modalOpen === Routes.REPORT) {
      return;
    }
    // closeAll();
    modalizeReport.current?.open();
    setModalOpen(Routes.REPORT);
  };

  const closeModalReport = () => {
    // closeAll();
    modalizeAreaSelectedRef.current?.open();
    setModalOpen(Routes.AREA_SELECTED);
  };

  useEffect(() => {
    if (params?.openModalReport) {
      // closeAll();
      modalizeReport.current?.open();
      setModalOpen(Routes.REPORT);
    }
  }, [params]);

  return (
    <>
      {modalOpen === Routes.AREA_SELECTED && <FlatListTop selectedId={selectedId} setSelectedId={setSelectedId} />}
      <MapComponent
        navigate={navigate}
        route={modalOpen}
        closeModalReport={closeModalReport}
        openModalReport={openModalReport}
        selectedId={selectedId}
        location={location}
        setLocation={setLocation}
      />

      <CustomBottomSheet ref={modalizeRef} visible={true}>
        {modalOpen === Routes.FARMER && <ModalAgricultores navigate={navigate} />}
        {modalOpen === Routes.FARM && <ModalFazendas navigate={navigate} />}
        {modalOpen === Routes.AREA && <ModalTalhoes navigate={navigate} />}
        {modalOpen === Routes.AREA_SELECTED && <ModalTalhaoSelected navigate={navigate} selectedId={selectedId} />}
        {modalOpen === Routes.REPORT && (
          <ModalReport location={location} closeModalReport={closeModalReport} modalizeRef={modalizeRef} />
        )}
      </CustomBottomSheet>
    </>
  );
}
