import React from 'react';
import Svg, { Path, SvgProps, Circle, Rect } from 'react-native-svg';

const AmostragensIcon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width={props.width} height={props.height} viewBox="0 0 24 24" fill="none">
        <Rect width="24" height="24" rx="4" fill="#F6DFF1"/>
        <Path d="M8.69835 14H15.3016M7.19211 16.0806C6.70721 16.8791 7.19211 18 8.06627 18H15.9337C16.8079 18 17.2928 16.8791 16.8079 16.0806C16.1268 14.959 14.4848 13.2434 13.7483 11.2819C13.5395 10.7258 13.3112 9.89254 13.3112 9.36253V7.44317C14.3907 6.25805 13.7483 6.00353 13.3112 6.00353C13.0416 5.99558 12.3091 6.00355 12 6.00355C11.6909 6.00355 10.9584 5.99558 10.6888 6.00353C10.2517 6.00353 9.60932 6.25805 10.6888 7.44317V9.36253C10.6888 9.89254 10.4605 10.7258 10.2517 11.2819C9.51515 13.2434 7.87316 14.959 7.19211 16.0806Z" stroke="#D35FB7"/>
        <Circle cx="10.5" cy="15.5" r="0.5" fill="#D35FB7"/>
        <Circle cx="12.5" cy="16.5" r="0.5" fill="#D35FB7"/>
        <Circle cx="13.5" cy="15.5" r="0.5" fill="#D35FB7"/>
        </Svg>


    );
}

export default AmostragensIcon;