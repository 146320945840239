import { NotesResponse } from '../components/NotasScreen/NotasList';
import { Area } from '../services/api/Areas';
import { Client } from '../services/api/Clients';
import { Farm } from '../services/api/Farms';

export type Data = ReadonlyArray<Farm | Area | Client>;

export const search = (searchQuery: string, data: Data) =>
  !!searchQuery
    ? data.filter((value) => {
        return value.name.toLowerCase().includes(searchQuery.toLowerCase());
      })
    : data;

export const searchNote = (searchQuery: string, data: NotesResponse[]) =>
  !!searchQuery
    ? data.filter((value) => {
        return value.versions[0].description.toLowerCase().includes(searchQuery.toLowerCase());
      })
    : data;
