import React from 'react';
import { Div, Text } from 'react-native-magnus';
import theme from '../../global/styles/theme';

export const Hour = ({ formattedHour }: { formattedHour: string }) => {
  return (
    <Div
      flexDir="row"
      w="100%"
      flex={1}
      pl={16}
      pr={16}
      alignItems="center"
      borderTopWidth={1}
      borderBottomWidth={1}
      borderColor={theme.colors.grey2}
      justifyContent="space-between"
    >
      <Text color={theme.colors.grey5} fontWeight="700">
        Hora
      </Text>
      <Text fontWeight="700" color={theme.colors.blue2}>
        {formattedHour}
      </Text>
    </Div>
  );
};
