import React, { useCallback, useEffect, useState } from 'react';
import { Div } from 'react-native-magnus';
import { useAreas } from '../../../../hooks/Area';
import { useMapElements } from '../../../../hooks/Map';
import { useSatelliteFromArea } from '../../../../hooks/SatelliteFromArea';
import { image, ValueSatellite } from '../../../../services/api/ElementsFromMap';
import { ChangeElements, Data } from '../ChangeElements';
import { FlatListElements } from '../FlatListElements';

const Satellite: React.FC = () => {
  const { satellites, selectedSatellite, setSelectedSatellite } = useSatelliteFromArea();
  const { setElement } = useMapElements();
  const { selectedArea } = useAreas();
  const [years, setYears] = useState<Data>({ value: '', position: 0 });
  const [elements, setElements] = useState<ValueSatellite[]>([]);
  const [names, setNames] = useState<any[]>([]);

  useEffect(() => {
    setNames(satellites?.map((item) => item.name));
  }, [satellites, selectedArea]);

  useEffect(() => {
    setYears({ value: names && parseDate(names[0]), position: 0 });
  }, [names]);

  useEffect(() => {
    if (selectedSatellite) {
      setElementInMapState(selectedSatellite.images[0]);
    }
  }, [selectedSatellite]);

  useEffect(() => {
    if (years && years.value) {
      const findElement = satellites?.find((item) => parseDate(item.name) === years.value);
      setElements(findElement?.values as ValueSatellite[]);
    }
  }, [years, satellites, selectedArea]);

  useEffect(() => {
    if (elements) {
      const nextElementSelected = elements.find((element) => element.name === selectedSatellite?.name);

      if (nextElementSelected) {
        setElementInMapState(nextElementSelected.images[0]);
      }
    }
  }, [elements]);

  const onPressNext = useCallback(() => {
    setYears({
      value: names && parseDate(names[years.position - 1]),
      position: years.position - 1,
    });
  }, [names, years]);

  const onPressPrev = useCallback(() => {
    setYears({
      value: names && parseDate(names[years.position + 1]),
      position: years.position + 1,
    });
  }, [names, years]);

  const parseDate = (dateValue: string) => {
    if (!dateValue) return '';
    const formattedDate = dateValue.replace(/-/g, '/');
    return new Date(formattedDate).toLocaleDateString();
  };

  function setElementInMapState(satelliteSelected: image) {
    if (selectedSatellite) {
      setElement({
        histogram: satelliteSelected.histogram,
        bbox: satelliteSelected.web_map.bbox,
        image_url: satelliteSelected.web_map.image_url,
        name: `Níveis de ${selectedSatellite.name}`,
      });
    }
  }

  return (
    <>
      <ChangeElements
        names={names}
        label="Período"
        text={names ? names[0] : '2022'}
        data={years}
        onPressNext={onPressNext}
        onPressPrev={onPressPrev}
      />
      {years && (
        <Div mt={16} ml={8}>
          <FlatListElements
            setSelectedElement={(item) => {
              console.log('FlatListElements SELECT ELEMENT', item);
              setSelectedSatellite(item as ValueSatellite);
            }}
            elements={elements as ValueSatellite[]}
          />
        </Div>
      )}
    </>
  );
};

export default Satellite;
