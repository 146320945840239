import React, { useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Search } from '../../Search';
import { useNavigation } from '@react-navigation/core';
import { format, parseISO } from 'date-fns';
import { Entypo, MaterialIcons } from '@expo/vector-icons';

import { Menu, MenuOptions, MenuOption, MenuTrigger } from 'react-native-popup-menu';

import { Badge } from 'react-native-paper';

import { styles } from '../style';

import { useNotesFromArea } from '../../../hooks/Anomalies';
import { List } from '../../List';
import { searchNote } from '../../../utils/Search';
import theme from '../../../global/styles/theme';

export interface Causes {
  id: number;
  detailId: number;
  causeId: number;
  createdAt: string;
  updatedAt: string | null;
  cause: {
    name: string;
    category: {
      name: string;
    };
  };
}

export interface DetailsResponse {
  createdAt: string;
  deletedAt: string | null;
  filename: string | null;
  hint: string;
  id: number;
  updatedAt: string | null;
  versionId: number;
  causes: Causes[];
}

export interface VersionResponse {
  id: number;
  anomalyId: number;
  audioFilename?: string | null;
  videoFilename?: string | null;
  createdAt: string;
  cropYear: string;
  deletedAt: string | null;
  description: string;
  updatedAt: string | null;
  userId: number;
  details: DetailsResponse[];
}

export interface NotesResponse {
  areaId: number;
  createdAt: string;
  id: number;
  deletedAt: string | null;
  updatedAt: string | null;
  versions: VersionResponse[];
}

export const NotasList = () => {
  const navigation = useNavigation();
  const { notes, deleteNote, setNotesByArea } = useNotesFromArea();

  const navigateRoute: string = 'NotasDetails';

  const [searchQuery, setSearchQuery] = React.useState('');

  const handleSearch = useMemo(() => searchNote(searchQuery, notes), [notes, searchQuery]);

  const handleDeleteNote = async (item: NotesResponse) => {
    await deleteNote(item.id);
    await setNotesByArea();
    alert('Nota deletada com sucesso!');
  };

  const NotasListItem = (item: NotesResponse) => {
    return (
      <View style={styles.note}>
        <View
          style={{
            alignItems: 'flex-end',
            marginBottom: 10,
          }}
        >
          <Menu>
            <MenuTrigger text="">
              <Entypo name="dots-three-vertical" size={15} color="black" />
            </MenuTrigger>
            <MenuOptions>
              <MenuOption onSelect={() => handleDeleteNote(item)}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <MaterialIcons name="delete" size={24} color="black" />
                  <Text style={{ color: 'red' }}>Excluir</Text>
                </View>
              </MenuOption>
            </MenuOptions>
          </Menu>
        </View>
        <TouchableOpacity
          onPress={() =>
            navigation.navigate(navigateRoute, {
              params: item?.versions[0],
            })
          }
        >
          <Text style={styles.content__note}>{item?.versions[0]?.description}</Text>
        </TouchableOpacity>
        <Text style={styles.content__note_time}>
          {item?.versions[0]?.createdAt ? format(parseISO(item?.versions[0]?.createdAt), 'dd/MM/yyyy HH:mm') : null}
        </Text>
        <Badge style={{ backgroundColor: theme.colors.green5dark }} size={16}>
          {item?.versions[0]?.details && item?.versions[0]?.details?.length ? 'Anomalia' : 'Visita técnica'}
        </Badge>
      </View>
    );
  };

  return (
    <View style={styles.content}>
      <View style={styles.subcontainer}>
        <TouchableOpacity>
          <Text style={styles.content__heading}>Notas</Text>
        </TouchableOpacity>
      </View>
      <Search placeholder="Pesquisar por notas" searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      <Text style={styles.favoriteStyle}>Ver todas as notas</Text>
      <Text style={styles.subTitleStyle}>
        Para ver todas as notas, anexos e conversas sobre um talhão específico, será necessário seleconar nos campos
        abaixo.
      </Text>
      <List data={handleSearch} render={NotasListItem} />
    </View>
  );
};
