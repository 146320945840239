import React from "react";
import Svg, { Path, SvgProps, Circle, Rect } from "react-native-svg";

const ButtonCloseIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 40 46"
      fill="none"
    >
      <Path
        d="M0 0L32 0C36.4183 0 40 3.58172 40 8L40 38C40 42.4183 36.4183 46 32 46L0 46L0 0Z"
        fill="#3966AE"
      />
      <Path
        d="M25 28L15 18"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <Path
        d="M15 28L25 18"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default ButtonCloseIcon;
