import React from "react";
import Svg, { Path, SvgProps, Circle, Rect } from "react-native-svg";

const FazendaIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <Rect width="24" height="24" rx="4" fill="#DEF1E0" />
      <Path
        d="M2.23661 11.8091C9.12229 11.8091 15.0663 15.8712 17.8366 21.7466"
        stroke="#5ABB62"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <Path
        d="M15.375 12.375V8.25H18.75V11.55"
        stroke="#5ABB62"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M2.23661 15.0034C7.48799 15.0034 12.0893 17.7019 14.6457 21.7467"
        stroke="#5ABB62"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <Path
        d="M2.23661 18.1975C5.61763 18.1975 8.65536 19.5684 10.7457 21.7466"
        stroke="#5ABB62"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <Path
        d="M21.7366 11.8091C18.1245 11.8091 14.7716 12.9269 12 14.8379"
        stroke="#5ABB62"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <Path
        d="M21.7366 15.0034C19.0763 15.0034 16.5828 15.696 14.4375 16.9052"
        stroke="#5ABB62"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <Path
        d="M21.7366 18.1975C19.849 18.1975 18.0684 18.6248 16.5 19.3813"
        stroke="#5ABB62"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <Path
        d="M13.1902 13.875V7.69737H12.375L12.7826 5.39474L16.8587 3.75L20.9348 5.39474L21.75 7.69737H20.9348V11.625"
        stroke="#5ABB62"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M5.625 6.75L5.625 12"
        stroke="#5ABB62"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Circle cx="5.625" cy="6.375" r="3" stroke="#5ABB62" strokeWidth="1.5" />
    </Svg>
  );
};

export default FazendaIcon;
