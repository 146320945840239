export default {
  borderRadius: '10',
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: '#112',
      contrastText: '#f3f3f3',
    },
    secondary: {
      main: '#fff',
      contrastText: '#000',
    },
  },
  colors: {
    white: '#FFFFFF',
    grey1: '#F3F3F3',
    grey2: '#E3E3E3',
    grey3: '#D3D3D3',
    grey4: '#A3A3A3',
    grey5: '#737373',
    grey6: '#333333',
    grey7: '#C4C4C4',
    red: 'red',
    black: '#000000',

    warningdefault: '#FF0000',
    warningdark: '#B20000',
    warninglight: '#FF6666',
    warninglight2: '#F7C1C4',
    confirmdefault: '#00AF51',
    confirmdark: '#007A39',
    confirmlight: '#66CF97',
    confirmlight2: '#C2ECD5',

    green1: '#D0DA58',
    green2: '#A9D054',
    green3: '#5ABB62',
    green4: '#098A45',
    green5: '#266231',
    green6: '#143C1E',
    green1dark: '#A6AE46',
    green2dark: '#87A643',
    green3dark: '#48964E',
    green4dark: '#076E37',
    green5dark: '#1E4E27',
    green6dark: '#103018',

    green1light: '#F6F8DE',
    green2light: '#EEF6DD',
    green3light: '#DEF1E0',
    green4light: '#CEE8DA',
    green5light: '#D4E0D6',
    green6light: '#D0D8D2',

    blue1: '#2D3382',
    blue2: '#3966AE',
    blue3: '#BDC8DC',
    blue1dark: '#242968',
    blue2dark: '#2E528B',
    blue3dark: '#97A0B0',

    blue1light: '#D5D6E6',
    blue2light: '#D7E0EF',
    blue3light: '#F2F4F8',

    ameixa: '#A056C3',
    ameixadark: '#703C89',
    ameixalight: '#ECDDF3',

    goiaba: '#CC6677',
    goiabadark: '#8F4854',
    goiabalight: '#F5E0E4',

    abacate: '#117733',
    abacatedark: '#0C5424',
    abacatelight: '#CFE4D6',

    pitomba: '#994F00',
    pitombadark: '#6B3700',
    pitombalight: '#EBDCCC',

    mirtilo: '#1A85FF',
    mirtilodark: '#125DB3',
    mirtilolight: '#D1E7FF',

    uva: '#882255',
    uvadark: '#5F183C',
    uvalight: '#E7D3DD',

    jaca: '#E66100',
    jacadark: '#A14400',
    jacalight: '#FADFCC',

    pitaia: '#D64380',
    pitaiadark: '#962F5A',
    pitaialight: '#F7D9E6',

    maracuja: '#F0E442',
    noname5dark: '#A8A02E',
    noname5light: '#FCFAD9',

    fertilidade: '#40B0A6',
    fertilidadedark: '#2D7C75',
    fertilidadelight: '#D9EFED',

    mapas: '#E69F00',
    mapasdark: '#A17000',
    mapaslight: '#FAECCC',

    amostragem: '#D35FB7',
    amostragemdark: '#944380',
    amostragemlight: '#F6DFF1',

    produtividade: '#E1BE6A',
    produtividadedark: '#9E854A',
    produtividadelight: '#F9F2E1',

    recomenda: '#0072B2',
    recomendadark: '#00507D',
    recomendalight: '#CCE3F0',

    satelite: '#DC3220',
    satelitedark: '#9A2316',
    satelitelight: '#F8D6D2',

    compacta: '#4B0092',
    compactadark: '#350066',
    compactalight: '#DBCCE9',

    permanentes: '#56B4E9',
    permanentesdark: '#3C7EA4',
    permanenteslight: '#DDF0FB',

    primary: '#E51C44',

    secondary100: '#0A1033',
    secondary90: '#0D133D',
    secondary80: '#0E1647',
    secondary85: '#171F52',
    secondary70: '#1B2565',
    secondary60: '#1B2565',
    secondary50: '#243189',
    secondary40: '#1D2766',
    secondary30: '#495BCC',

    overlay: 'rgba(0,0,0,0.7)',
    highlight: '#ABB1CC',
    heading: '#DDE3F0',
    line: '#991F36',
    on: '#32BD50',
  },
  fonts: {
    title700: 'Roboto_700Bold',
    title500: 'Roboto_500Medium',
    text400: 'Roboto_400Regular',
    text500: 'Roboto_500Medium',
  },
};
