import React from 'react';
import { Text as TextStyled, TextProps } from 'react-native';
import theme from '../../global/styles/theme';

interface StyledTextProps extends TextProps {
  children: string | number | undefined;
  fontSize?: number;
  color?: string;
  fontWeight?: '400' | 'bold' | 'normal' | '100' | '200' | '300' | '500' | '600' | '700' | '800' | '900';
}

const Text = ({ children, fontSize = 12, color = theme.colors.black, fontWeight = '400' }: StyledTextProps) => {
  return <TextStyled style={{ fontSize, color, fontWeight }}>{children}</TextStyled>;
};

export default Text;
