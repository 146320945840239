import React from 'react';
import Svg, { Path, SvgProps, Circle } from 'react-native-svg';

const MaisBottonTabsIcon: React.FC<SvgProps> = (props) => {
    return (

        <Svg width={props.height} height={props.height} viewBox="0 0 24 24" fill="none">
            <Circle cx="7.5" cy="12" r="1.5" fill={props.color} />
            <Circle cx="12" cy="12" r="1.5" fill={props.color} />
            <Circle cx="16.5" cy="12" r="1.5" fill={props.color} />
        </Svg>

    );
}

export default MaisBottonTabsIcon;