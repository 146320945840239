import React from 'react';
import { View, Text } from 'react-native';

export function PrecisoDeAjuda() {
  return (
    <View>
      <Text>Preciso de Ajuda</Text>
    </View>
  );
}
