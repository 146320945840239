import React from 'react';
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack';
import { EsqueciMinhaSenha } from '../screens/EsqueciMinhaSenha';
import { Login } from '../screens/Login';
import { PrecisoDeAjuda } from '../screens/PrecisoDeAjuda';
import { AlterarSenha } from '../screens/AlterarSenha';
import theme from '../global/styles/theme';
import { Permission } from '../components/Permission';

type RootStackParamList = {
  Login: undefined;
  EsqueciMinhaSenha: { email: string } | undefined;
  AlterarSenha: undefined;
  PrecisoDeAjuda: undefined;
  Permission: undefined;
};

// Importar para obter a tipagem na navegação das telas com o hook do useNavigation
export type authScreensProp = StackNavigationProp<RootStackParamList>;

const Stack = createStackNavigator<RootStackParamList>();

export function AuthRoutes() {
  return (
    <Stack.Navigator
      initialRouteName="Login"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="Login" component={Login} options={{ title: 'InCeres Conveniência' }} />
      <Stack.Screen
        name="EsqueciMinhaSenha"
        component={EsqueciMinhaSenha}
        options={{
          headerShown: true,
          title: 'Esqueci minha senha',
          headerStyle: {
            borderBottomColor: theme.colors.grey2,
          },
          headerTintColor: theme.colors.grey6,
          headerTitleStyle: {
            fontWeight: 'bold',
          },
        }}
      />
      <Stack.Screen
        name="AlterarSenha"
        component={AlterarSenha}
        options={{
          headerShown: true,
          title: 'Recuperar nova senha',
          headerStyle: {
            borderBottomColor: theme.colors.grey2,
          },
          headerTintColor: theme.colors.grey6,
          headerTitleStyle: {
            fontWeight: 'bold',
          },
        }}
      />
      <Stack.Screen name="PrecisoDeAjuda" component={PrecisoDeAjuda} options={{ headerShown: true }} />
      <Stack.Screen name="Permission" component={Permission} options={{ headerShown: false }} />
    </Stack.Navigator>
  );
}
