import { TouchableOpacityProps } from 'react-native';
import styled, { css } from 'styled-components/native';
import theme from '../../../../global/styles/theme';

interface FertilityTouchableProps extends TouchableOpacityProps {
  isSelected: boolean;
}

export const FertilityTouchable = styled.TouchableOpacity<FertilityTouchableProps>`
  width: 80px;
  height: 40px;
  border-radius: 8px;
  background: ${(props) => (props.disabled ? theme.colors.grey2 : theme.colors.blue2)};
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  margin-left: 4px;
  text-align: center;

  ${(props) =>
    props.isSelected
      ? css`
          transform: scale(1.1);
          background: ${theme.colors.fertilidade};
        `
      : css`
          transform: scale(1);
        `}
`;
