import React, { useCallback, useEffect, useState } from 'react';
import { View, Text, ActivityIndicator } from 'react-native';
import { Div } from 'react-native-magnus';
import theme from '../../../../global/styles/theme';
import { useAreas } from '../../../../hooks/Area';
import { useFertilitiesFromArea } from '../../../../hooks/FertilityFromArea';
import { useMapElements } from '../../../../hooks/Map';
import { FerlilitiesValues, UnityFertility } from '../../../../services/api/ElementsFromMap';
import { ChangeElements, Data } from '../ChangeElements';
import { FlatListElements } from '../FlatListElements';

export const Fertility = () => {
  const { fertilities, setSelectedFertility, selectedFertility } = useFertilitiesFromArea();
  const { setElement } = useMapElements();
  const { setSelectedYearSafra, selectedYearSafra } = useAreas();
  const [profundidade, setProfundidade] = useState<Data>({ value: '', position: 0 });
  const [years, setYears] = useState<Data>({ value: '', position: 0 });
  const [elements, setElements] = useState<any[]>([]);
  const [depthKey, setDepthKey] = useState<string[] | undefined>(undefined);

  const [names] = useState(
    fertilities.length
      ? fertilities?.map((item) => {
          return item.name;
        })
      : undefined,
  );

  useEffect(() => {
    setYears({ value: names && names[0], position: 0 });
    setSelectedYearSafra(names && names[0]);
  }, []);

  useEffect(() => {
    if (selectedFertility && !selectedFertility.values) {
      const findNextSelectedFertility = elements.find((element) => element.values !== null);
      setSelectedFertility(findNextSelectedFertility);
    }
  }, [selectedFertility]);

  useEffect(() => {
    if (selectedFertility && selectedFertility.values) {
      const filteredDepth = Object.keys(selectedFertility.values).filter((depthItem) => {
        if (selectedFertility.values[depthItem].stats != null) {
          return depthItem;
        }
      });
      setDepthKey(filteredDepth);
      setSelectedElement(selectedFertility && selectedFertility.values && selectedFertility.values[0]);
    }
  }, [selectedFertility]);

  useEffect(() => {
    if (depthKey) {
      setProfundidade({
        value: depthKey && depthKey[0],
        position: 0,
      });
    }
  }, [depthKey]);

  useEffect(() => {
    if (profundidade.value && selectedFertility) {
      const asArray = Object.entries(selectedFertility?.values);
      const filtered = asArray.filter((item) => item[0] === profundidade.value);
      const findElement = filtered[0][1];

      setSelectedElement(findElement);
    }
  }, [profundidade]);

  useEffect(() => {
    if (years && years.value) {
      const findElement = fertilities?.find((item) => item.name === years.value);
      setElements(findElement?.values as any[]);
    }
  }, [years, fertilities]);

  useEffect(() => {
    if (elements) {
      const nextElementSelected = elements.find((element) => element.name === selectedFertility?.name);

      if (nextElementSelected) {
        // setSelectedElement(nextElementSelected.images[0]);
        setSelectedFertility(nextElementSelected);
      }
    }
  }, [elements]);

  const setSelectedElement = (element: UnityFertility | any) => {
    if (element && selectedFertility) {
      setElement({
        bbox: element.web_map.bbox,
        histogram: element.histogram,
        image_url: element.web_map.image_url,
        name: `Níveis de ${selectedFertility?.name}`,
      });
    }
  };

  const onPressNext = useCallback(() => {
    setYears({
      value: names && names[years.position - 1],
      position: years.position - 1,
    });
    setSelectedYearSafra(names && names[years.position - 1]);
  }, [names, years]);

  const onPressPrev = useCallback(() => {
    setYears({
      value: names && names[years.position + 1],
      position: years.position + 1,
    });
    setSelectedYearSafra(names && names[years.position + 1]);
  }, [names, years]);

  const onPressNextFertility = useCallback(() => {
    setProfundidade({
      value: depthKey && depthKey[profundidade.position - 1],
      position: profundidade.position - 1,
    });
  }, [depthKey, profundidade]);

  const onPressPrevFertility = useCallback(() => {
    setProfundidade({
      value: depthKey && depthKey[profundidade.position + 1],
      position: profundidade.position + 1,
    });
  }, [depthKey, profundidade]);

  return (
    <View>
      <ChangeElements
        names={names}
        label="Período"
        text={names ? names[0] : '2021-2022'}
        data={years as any}
        onPressNext={onPressNext}
        onPressPrev={onPressPrev}
      />
      {years && (
        <Div mt={16} ml={8}>
          <FlatListElements
            setSelectedElement={(item) => {
              setSelectedFertility(item);
            }}
            elements={elements}
          />
          {selectedFertility && depthKey?.length && (
            <ChangeElements
              names={depthKey}
              label="Profundidade do solo"
              text="0~20"
              data={profundidade}
              onPressNext={onPressNextFertility}
              onPressPrev={onPressPrevFertility}
            />
          )}
        </Div>
      )}
    </View>
  );
};
