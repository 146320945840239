import React, { useCallback, useEffect, useState } from 'react';
import { Div, Icon } from 'react-native-magnus';
import { Text as NativeText, View } from 'react-native';
import { TouchableAlter, styles } from '../../style';
import theme from '../../../../global/styles/theme';
import Text from '../../../Text';

export type Data = {
  value: string | undefined;
  position: number;
};

export type ChangeElementsProps = {
  label: 'Profundidade do solo' | 'Período';
  data: Data;
  onPressNext: () => void;
  onPressPrev: () => void;
  names?: string[];
  text?: string;
};

export const ChangeElements = ({ label, data, onPressNext, onPressPrev, names, text }: ChangeElementsProps) => {
  return (
    <Div mt={5}>
      <View style={styles.outroContainer}>
        <NativeText style={styles.subtitleText}>{label}</NativeText>
      </View>
      <Div
        bg={theme.colors.grey1}
        rounded={8}
        mt={12}
        ml="auto"
        mr="auto"
        h={46}
        w={328}
        alignItems="center"
        justifyContent="space-between"
        flexDir="row"
      >
        <TouchableAlter onPress={onPressNext} orientation="left" disabled={data.position === 0}>
          <Icon fontSize={18} name="left" fontFamily="AntDesign" color={theme.colors.white} />
        </TouchableAlter>
        <Text color={theme.colors.blue2dark} fontSize={16} fontWeight="700">
          {data.value}
        </Text>
        <TouchableAlter onPress={onPressPrev} orientation="right" disabled={data.position === (names?.length || 1) - 1}>
          <Icon fontSize={18} name="right" fontFamily="AntDesign" color={theme.colors.white} />
        </TouchableAlter>
      </Div>
    </Div>
  );
};
