import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Text as NativeText, TouchableOpacity, View } from 'react-native';
import { useAreas } from '../../hooks/Area';
import { ButtonVerTodos } from '../ButtonVerTodos';
import { Routes } from '../../screens/Tabs/HomeTab';
import VoltarIcon from '../../../assets/Svg/VoltarIcon';
import { styles } from './style';
import { useFertilitiesFromArea } from '../../hooks/FertilityFromArea';
import theme from '../../global/styles/theme';
import { TopFlatList } from '../FlatListTop';
import { useSatelliteFromArea } from '../../hooks/SatelliteFromArea';
import { Fertility } from './components/Fertility';
import { Div } from 'react-native-magnus';
import Satellite from './components/Satellite';
import { useMapElements } from '../../hooks/Map';
import { DEFAULT_MAP_STATE } from '../../contexts/reducers/MapReducer';

type ModalTalhaoSelected = {
  selectedId: string;
  navigate: (route: Routes) => void;
};

export const ModalTalhaoSelected = ({ navigate, selectedId }: ModalTalhaoSelected) => {
  const { selectedArea } = useAreas();
  const { setFertilitiesByArea } = useFertilitiesFromArea();
  const { setSatelliteByArea } = useSatelliteFromArea();
  const { resetMapState, setElement } = useMapElements();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    switchTypeElement();
  }, [selectedId]);

  const switchTypeElement = async () => {
    // console.log('### switchTypeElement ###', selectedId)

    setLoading(true);
    resetMapState();
    switch (selectedId) {
      case TopFlatList.Fertility:
        await setFertilitiesByArea();
        break;
      case TopFlatList.SatelliteMap:
        await setSatelliteByArea();
        break;
      default:
        setLoading(false);
        break;
    }
    setLoading(false);
  };

  const setRenderType = () => {
    switch (selectedId) {
      case TopFlatList.Fertility:
        return <Fertility />;
        break;
      case TopFlatList.SatelliteMap:
        return <Satellite />;
        break;
      default:
        break;
    }
  };

  return (
    <View style={styles.content}>
      <View style={styles.subContainer}>
        <Div flex={1} flexDir="row" justifyContent="flex-start" alignItems="center">
          <TouchableOpacity
            // style={{ marginTop: 4 }}
            onPress={() => {
              setElement(DEFAULT_MAP_STATE);
              navigate(Routes.AREA);
            }}
          >
            <VoltarIcon color="#737373" height={24} width={24} />
          </TouchableOpacity>
          <NativeText style={styles.subtitle}>{selectedArea?.field}</NativeText>
        </Div>
      </View>

      {/* <View style={styles.outroContainer}>
                <NativeText style={styles.subtitleText}>{selectedId}</NativeText>
            </View> */}

      {loading ? (
        <Div flex={1} alignItems="center" justifyContent="center">
          <ActivityIndicator color={theme.colors.green1} />
        </Div>
      ) : (
        setRenderType()
      )}
    </View>
  );
};
