import { AxiosResponse } from 'axios';
import api from '..';

export type Farm = {
  id: number;
  name: string;
  country: string;
  state: string;
  city: string;
  manager_id: number;
  client_id: number;
  year?: string;
  v2_id?: number;
  v2_sync: string;
  sm_id: number;
};

export type FarmResponse = {
  farms: Farm[];
};

export type FarmParams = {
  userId: number;
  clientId: number;
  lastSyncDate: string;
};

export const GetFarms = (params: FarmParams): Promise<AxiosResponse<FarmResponse>> => {
  return api.get('sync', {
    params: {
      target: 'farms',
      ...params,
    },
  });
};
