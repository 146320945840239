import { useState, useContext, useMemo, useCallback } from 'react';

import { AppContext } from '../contexts';
import { getNotesFromAreaSelected, deleteNoteById } from '../services/api/Notes';
// import { GetFertilitiesStorage, SetFertilitiesStorage } from '../services/database/fertilityStorage';

export function useNotesFromArea() {
  const { areaState, notesDispatch, notesState } = useContext(AppContext);

  const [loading, setLoading] = useState<boolean>(false);

  const deleteNote = async (id: number) => {
    setLoading(true);

    try {
      const response = await deleteNoteById(id);
      console.log('RESPONSE', response.data);
    } catch (err) {
      console.error(err);
      console.log('ERR:', err);
    } finally {
      setLoading(false);
    }
  };

  const setNotesByArea = useCallback(
    async (selectedAreaId?: number) => {
      setLoading(true);

      try {
        const idArea = areaState.selectedArea?.id || selectedAreaId;
        if (!idArea) return;

        const response = await getNotesFromAreaSelected(idArea as number);

        const filteredNotesWithData = response.data.count > 0 ? response.data.rows : [];

        notesDispatch({
          type: 'SetNotesFromArea',
          payload: filteredNotesWithData,
        });
      } catch (err) {
        console.error(err);
        console.log('ERR:', err);
      } finally {
        setLoading(false);
      }
    },
    [notesState],
  );

  const notes = useMemo(() => notesState.notes, [notesState]);

  return {
    notes,
    loading,
    setNotesByArea,
    deleteNote,
    useNotesFromArea,
  };
}
