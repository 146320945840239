import * as React from 'react';
import { Searchbar } from 'react-native-paper';

interface SearchProps {
  placeholder: string;
  searchQuery: string;
  setSearchQuery: any;
}
export function Search({ placeholder, setSearchQuery, searchQuery }: SearchProps) {
  const onChangeSearch = (query: string) => {
    setSearchQuery(query);
  };

  return (
    <Searchbar
      style={{
        elevation: 0,
        borderWidth: 1,
        borderColor: '#D3D3D3',
        marginLeft: 16,
        marginRight: 32,
        marginVertical: 10,
      }}
      placeholder={placeholder}
      onChangeText={onChangeSearch}
      value={searchQuery}
    />
  );
}
