import { useContext, useEffect } from 'react';
import MapContext from '../MapContext';
import OLVectorLayer from 'ol/layer/Vector';
import Select from 'ol/interaction/Select';

const VectorLayer = ({
  source,
  style,
  zIndex = 0,
  styleSelected = null,
  isPointInsideLayer,
  point,
  areaCoordinates,
}: any) => {
  const { map } = useContext(MapContext);

  /* const selectSingleClick = new Select({ style: styleSelected }); */

  useEffect(() => {
    if (!map) return;

    let vectorLayer = new OLVectorLayer({
      source,
      style,
    });

    map.addLayer(vectorLayer);
    vectorLayer.setZIndex(zIndex);
    /* map.addInteraction(selectSingleClick); */

    isPointInsideLayer();

    return () => {
      if (map) {
        map.removeLayer(vectorLayer);
      }
    };
  }, [map, point, areaCoordinates]);

  return null;
};

export default VectorLayer;
