import { useEffect, useState, useContext, useMemo, useCallback } from 'react';

import { AppContext } from '../contexts';
import {
  getFertilitiesFromAreaSelected,
  FerlilitiesValues,
  UnityFertility,
  ValueSatellite,
} from '../services/api/ElementsFromMap';
import { GetFertilitiesStorage, SetFertilitiesStorage } from '../services/database/fertilityStorage';

export function useFertilitiesFromArea() {
  const { areaState, fertilityDispatch, fertilityState, areaDispatch } = useContext(AppContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (fertilityState.fertilities.length && update && areaState.selectedArea) {
      SetFertilitiesStorage(areaState.selectedArea.id, fertilityState.fertilities);
      setUpdate(false);
    }
  }, [update]);

  const fertilitiesDispatch = async (areas: any) => {
    const promises = areas.map((areaItem) => getFertilitiesFromAreaSelected(areaItem.id));

    const results = await Promise.all(promises);

    const filteredFertilitiesWithData = results.reduce((acc, cur) => {
      const items = cur.data.response.filter((dataItem) => dataItem.values != null);
      return [...acc, ...items];
    }, []);

    const sortedFertilitiesWithData = filteredFertilitiesWithData.sort((a, b) => (a.name > b.name ? 1 : -1));

    if (sortedFertilitiesWithData.length <= 0) {
      fertilityDispatch({
        type: 'SetFertilitiesFromArea',
        payload: [],
      });
    } else {
      fertilityDispatch({
        type: 'SetFertilitiesFromArea',
        payload: sortedFertilitiesWithData,
      });
    }
    setUpdate(true);
  };

  const setFertilitiesByArea = useCallback(
    async (areaId?: number) => {
      setLoading(true);
      const id = areaId || areaState.selectedArea?.id;
      try {
        if (!id) {
          return;
        }
        fertilityDispatch({
          type: 'resetFertilitiesFromArea',
        });

        const stored = await GetFertilitiesStorage(id);
        if (stored && stored.length) {
          fertilityDispatch({
            type: 'SetFertilitiesFromArea',
            payload: stored,
          });
        }

        console.log('area aqui', areaState.selectedArea);

        await fertilitiesDispatch(areaState.selectedArea.areas);
      } catch (err) {
        console.error(err);
        console.log('ERR:', err);
      } finally {
        setLoading(false);
      }
    },
    [fertilityState],
  );

  const resetArea = () => {
    areaDispatch({
      type: 'resetAreaState',
    });
  };

  const setSelectedFertility = useCallback(
    (fertility: FerlilitiesValues | undefined) => {
      fertilityDispatch({
        type: 'SetSelectedFertility',
        payload: fertility,
      });
    },
    [fertilityState],
  );

  const fertilities = useMemo(() => fertilityState.fertilities, [fertilityState]);

  const selectedFertility = useMemo(() => fertilityState.selectedFertility, [fertilityState]) as any;

  return {
    fertilities,
    loading,
    selectedFertility,
    setSelectedFertility,
    resetArea,
    fertilitiesDispatch,
    setFertilitiesByArea,
  };
}
