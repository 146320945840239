import React, { useState } from 'react';
import { View, Text, TextInput, TouchableOpacity } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { styles } from './styles';
import theme from '../../global/styles/theme';
import { Button } from '../../components/Button';
import { ButtonDesabled } from '../../components/ButtonDesabled';

import IconShowPassword from '../../../assets/Svg/IconShowPassword';
import ButtonCloseIcon from '../../../assets/Svg/ButtonCloseIcon';

export function AlterarSenha() {
  const navigation = useNavigation();
  const [userName, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<string>('');
  const [hidePass, setHidePass] = useState(true);
  const [validateInput, setValidateInput] = useState({
    case: false,
    number: false,
    length: false,
  });

  const anotherFunc = () => {
    setUserName('');
  };

  const validar = () => {
    setTimeout(function () {
      let error = false;
      setErrorEmail('');

      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!re.test(String(userName).toLowerCase())) {
        setErrorEmail('Preencha seu e-mail corretamente');
        error = true;
      }
      return !error;
    }, 500);
  };

  const secureText = (password: string) => {
    const regexUppercase = new RegExp(/^(?=.*[A-Z]).+$/);
    const regexLowercase = new RegExp(/^(?=.*[a-z]).+$/);
    const regexNumber = new RegExp(/^(?=.*[0-9]).+$/);
    const length = password.length >= 6;

    setValidateInput({
      case: regexUppercase.test(password) && regexLowercase.test(password),
      number: regexNumber.test(password),
      length,
    });
  };

  return (
    <View style={styles.container}>
      <View style={styles.senhaAntigaContainer}>
        <Text style={styles.emailText}>Recuperar minha senha</Text>
        <Text style={styles.TextRecuperar}>
          Se você esqueceu sua senha, não se preocupe. Informe seu {'\n'}
          e-mail utilizado em seu cadastro que enviaremos um link e {'\n'}
          um código de acesso para você cadastrar uma nova senha.
        </Text>
      </View>
      <View style={styles.senhaAntigaContainer}>
        <Text style={styles.antigaText}>Email</Text>
      </View>
      <View style={styles.inputContainer}>
        <View style={{ flexDirection: 'row' }}>
          <TextInput
            style={[
              styles.textInput,
              errorEmail
                ? {
                    borderColor: theme.colors.warninglight,
                    color: theme.colors.warninglight,
                  }
                : { borderColor: theme.colors.grey2 },
            ]}
            placeholder="Digite seu email"
            onChangeText={(text) => {
              setUserName(text);
              validar();
            }}
            value={userName}
            autoCorrect={false}
            keyboardType="email-address"
          />
          {!!userName && (
            <View style={{ position: 'absolute', right: 13, marginTop: 8 }}>
              <TouchableOpacity
                onPress={() => {
                  anotherFunc();
                }}
              >
                <ButtonCloseIcon width={40} height={46} />
              </TouchableOpacity>
            </View>
          )}
        </View>

        <View style={{ alignSelf: 'flex-end', marginRight: 16 }}>
          {!!errorEmail && (
            <Text
              style={{
                color: 'red',
                fontSize: 12,
                fontFamily: 'bold',
                marginTop: 2,
              }}
            >
              Email inválido
            </Text>
          )}
        </View>

        <View style={styles.marginText}>
          <Text style={styles.antigaText}>Nova Senha</Text>
        </View>
      </View>
      <View style={styles.inputContainer}>
        <View style={{ flexDirection: 'row' }}>
          <TextInput
            style={styles.textInput}
            placeholder="Digite a nova senha"
            onChangeText={setPassword}
            value={password}
            secureTextEntry={hidePass}
            autoCorrect={false}
          />
          {!!password && (
            <View style={{ position: 'absolute', right: 13, marginTop: 8 }}>
              <TouchableOpacity onPress={() => setHidePass(!hidePass)}>
                <IconShowPassword width={40} height={46} />
              </TouchableOpacity>
            </View>
          )}
        </View>
        <View style={{ alignSelf: 'flex-end', marginRight: 16 }}>
          {/*<Text style={{color: 'red', fontSize: 12, fontFamily: 'bold', marginTop: 2}}>Senha inválida</Text> */}
        </View>

        <View>
          {!!!password && (
            <View style={styles.buttonContainer}>
              <ButtonDesabled title="Alterar senha" />
            </View>
          )}

          {!!password && (
            <View style={styles.buttonContainer}>
              <Button title="Alterar senha" onPress={() => validar()} />
            </View>
          )}
        </View>
      </View>
    </View>
  );
}
