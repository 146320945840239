import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthState } from '../../contexts/reducers/AuthReducer';

export const STORAGE_AUTH_ALIAS = '@Auth';

export const GetAuthStorage = async (): Promise<AuthState> => {
  const auth = await AsyncStorage.getItem(STORAGE_AUTH_ALIAS);
  return !!auth ? JSON.parse(auth) : null;
};

export const SetAuthStorage = async (authState: AuthState) => {
  AsyncStorage.setItem(STORAGE_AUTH_ALIAS, JSON.stringify(authState));
};

export const DeleteAuthStorage = async () => {
  await AsyncStorage.clear();
};
