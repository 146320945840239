import React, { useState, useRef } from 'react';
import { TouchableOpacity, StyleSheet, TextInput, ScrollView } from 'react-native';
import { Div, Text, Icon, Radio, Checkbox, Image } from 'react-native-magnus';
import { Camera } from 'expo-camera';
import * as ImagePicker from 'expo-image-picker';
import theme from '../../global/styles/theme';
import { useAreas } from '../../hooks/Area';
import { ProblemReport, useProblemsReport } from '../../hooks/ProblemsReport';
import { BodyProblemReport } from '../../services/api/ProblemsReport';
import { ButtonActions } from './ButtonActions';
import { ModalReportHeader } from './ModalReportHeader';
import { SelectProblems } from './SelectProblems';
import { Day } from './Day';
import { Hour } from './Hour';
import { ChangeCamera, RemoveImage, TakePic, CancelTakePicture, TimeRecorder } from './styles';
import { AlertMessage } from '../../utils/AlertMessage';
import { format, addSeconds } from 'date-fns';
import getBlobDuration from 'get-blob-duration';

const MAX_FILES_LENGTH = 4;
const MESSAGE_MAX_FILES_LENGTH = `A quantidade máxima permitida de anexos é igual à ${MAX_FILES_LENGTH}.`;

interface ModalReportProps {
  closeModalReport: () => void;
  modalizeRef: any;
  location: {
    coords: {
      latitude: number;
      longitude: number;
    };
  };
}

interface PhotoLibrary {
  cancelled: boolean;
  height: number;
  width: number;
  uri: string;
}

export interface DetailsReport {
  causeId: number;
  hint: string;
  causeName: string;
  image?: string;
}

interface Base64File {
  type: string;
  uri: string;
  duration?: string;
}

interface PhotoLibrary {
  cancelled: boolean;
  height: number;
  width: number;
  uri: string;
}

export enum ViewStatus {
  Reporter = 'reporter',
  ListProblem = 'listProblem',
  Camera = 'camera',
  Video = 'video',
}

const ModalReport = ({ closeModalReport, modalizeRef, location }: ModalReportProps) => {
  const { problems, sendProblemReport, loading } = useProblemsReport();
  const { selectedArea } = useAreas();
  const cameraRef = useRef<Camera>(null);
  const mediaRecorderRef = useRef<MediaRecorder>();
  const videoRecorderRef = useRef<MediaRecorder>();
  const timerIntervalRef = useRef();
  const videoTimerIntervalRef = useRef();

  const [isAnomalia, setIsAnomalia] = useState<boolean>(false);
  const [isVisitaTecnica, setIsVisitaTecnica] = useState<boolean>(true);
  const [checkedValues, setCheckedValues] = useState<Array<number>>([]);
  const [base64Files, setBase64Files] = useState<Array<Base64File>>([]);
  const [details, setDetails] = useState<DetailsReport[]>([]);
  const [update, setUpdate] = useState<boolean>(false);
  const [text, setText] = useState<string>('');
  const [view, setView] = useState(ViewStatus.Reporter);
  const [recording, setRecording] = React.useState<boolean>();
  const [hasPermission, setHasPermission] = useState<boolean | undefined>();
  const [type, setType] = useState(Camera.Constants.Type.back);
  const [recordingTime, setRecordingTime] = useState(0);
  const [recordingVideoTime, setRecordingVideoTime] = useState(0);
  const [audio, setAudio] = useState<string | ArrayBuffer | null>('');
  const [iconVideo, setIconVideo] = useState<'record-circle' | 'record'>('record-circle');
  const [isRecording, setIsRecording] = useState(false);
  const [video, setVideo] = useState<string | ArrayBuffer | null>();

  const pickImage = async () => {
    if (base64Files.length >= MAX_FILES_LENGTH) {
      AlertMessage(MESSAGE_MAX_FILES_LENGTH);
      return;
    }
    const result = (await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    })) as PhotoLibrary;

    setBase64Files((prev) => [
      ...prev,
      {
        type: 'image',
        uri: result?.uri,
      },
    ]);
  };

  const stopAudioRecording = async () => {
    if (audio) {
      return;
    }
    mediaRecorderRef?.current?.stop();
    clearInterval(timerIntervalRef.current);
    setRecording(false);
    setRecordingTime(0);
  };

  const startAudioRecording = async () => {
    if (audio) {
      return;
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      mediaRecorderRef.current = new MediaRecorder(stream);
      let audioChunks: any = [];

      mediaRecorderRef.current.start();

      mediaRecorderRef.current.onstart = (e) => {
        setRecording(true);
        setRecordingTime(0);
        timerIntervalRef.current = setInterval(() => {
          setRecordingTime((prev) => prev + 1);
        }, 1000);
      };

      mediaRecorderRef.current.ondataavailable = (e) => {
        audioChunks.push(e.data);
      };

      mediaRecorderRef.current.onstop = async () => {
        clearInterval(timerIntervalRef.current);
        const blob = new Blob(audioChunks, { type: 'audio/ogg; codecs=opus' });
        audioChunks = [];
        const videoURL = window.URL.createObjectURL(blob);

        const durationSeconds = await getBlobDuration(videoURL);
        const durationFormatted = format(addSeconds(new Date(0), durationSeconds), 'mm:ss');
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          var base64data = reader.result?.toString().replace('data:audio/ogg; codecs=opus;base64,', '');
          setAudio(base64data as string);
        };

        setBase64Files((prev) => [
          ...prev,
          {
            type: 'audio',
            uri: videoURL,
            duration: durationFormatted,
          },
        ]);
      };
    } catch (err) {
      console.error('Failed to start recording', err);
    }
  };

  const getCameraPermission = async () => {
    if (base64Files.length >= MAX_FILES_LENGTH) {
      AlertMessage(MESSAGE_MAX_FILES_LENGTH);
      return;
    }
    const { status } = await Camera.requestCameraPermissionsAsync();

    setHasPermission(status === 'granted');

    modalizeRef.current?.slideUp('100%');
    setView(ViewStatus.Camera);
  };

  const getVideoPermission = async () => {
    if (video) {
      return;
    }
    if (base64Files.length >= MAX_FILES_LENGTH) {
      AlertMessage(MESSAGE_MAX_FILES_LENGTH);
      return;
    }
    const { status } = await Camera.requestCameraPermissionsAsync();

    setHasPermission(status === 'granted');

    modalizeRef.current?.slideUp('100%');
    setView(ViewStatus.Video);
  };

  const date = new Date();
  const hour = date.getHours();
  const minutes = date.getMinutes();
  const formattedHour = `${hour}:${minutes.toString().length === 1 ? '0' + minutes : minutes}`;

  const setAnomaliaChecked = (): void => {
    setIsVisitaTecnica(false);
    setIsAnomalia(true);
  };

  const setVisitaTecnicaChecked = (): void => {
    setIsAnomalia(false);
    setIsVisitaTecnica(true);
  };

  const checkboxValues = (id: number, causeName: string): void => {
    const copyCheckedValues: Array<number> = checkedValues;
    const copyDetails: DetailsReport[] = details;
    const indexValue = copyCheckedValues.indexOf(id);
    if (copyCheckedValues.includes(id) && indexValue !== -1) {
      const copyDetailsIndexValue = copyDetails.findIndex((item) => item.causeId === id);
      copyCheckedValues.splice(indexValue, 1);
      copyDetails.splice(copyDetailsIndexValue, 1);
    } else {
      copyCheckedValues.push(id);
      copyDetails.push({ causeId: id, hint: '', causeName: causeName });
    }
    setCheckedValues(copyCheckedValues);
    setDetails(copyDetails);
  };

  const descriptionProblemReport = (id: number, text: string, causeName: string): void => {
    let copyDetails: DetailsReport[] = details;
    const findIndex = copyDetails.findIndex((item) => item.causeId === id);
    if (findIndex !== -1) {
      copyDetails[findIndex] = { hint: text || '', causeId: id, causeName: causeName };
    }
    setDetails(copyDetails);
  };

  const getValueText = (id: number): string => {
    const findElement = details.find((item) => item.causeId === id);
    if (findElement) {
      return findElement.hint;
    }
    return '';
  };

  const handleSubmitReport = () => {
    if (!text.length) {
      AlertMessage('Escreva ao menos um comentário para salvar a nota!');
      return;
    }
    const formattedDetails = details.map((item) => {
      return {
        hint: item.hint,
        causeId: item.causeId,
      };
    });
    const formattedImages = base64Files.map((item) => {
      const formattedImage = item.uri.split(',');
      return {
        image: formattedImage[1],
      };
    });
    const formattedDetailsWithImages = formattedDetails.concat(formattedImages as any);
    let formattedBody = {};

    formattedBody = {
      areaId: selectedArea?.id as number,
      description: text,
      cropYear: selectedArea?.year as string,
      details: formattedDetailsWithImages,
      latitude: location.coords.latitude.toString(),
      longitude: location.coords.longitude.toString(),
    };

    if (audio) {
      formattedBody = { ...formattedBody, audio };
    }
    if (video) {
      formattedBody = { ...formattedBody, video };
    }

    sendProblemReport(formattedBody as BodyProblemReport, closeModalReport);
  };

  const takePicture = async () => {
    try {
      const options = { quality: 0.5, base64: true };
      const response = await cameraRef.current?.takePictureAsync(options);
      setBase64Files((prev) => [
        ...prev,
        {
          type: 'image',
          uri: response?.uri,
        },
      ]);
      setView(ViewStatus.Reporter);
    } catch (err) {
      console.log('err:', err);
    }
  };

  const startVideoRecording = async () => {
    if (video) {
      return;
    }
    try {
      setIsRecording(true);
      setIconVideo('record');
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      videoRecorderRef.current = new MediaRecorder(stream);
      let videoChunk: any = [];

      videoRecorderRef.current.start();

      videoRecorderRef.current.onstart = (e) => {
        setIsRecording(true);
        videoTimerIntervalRef.current = setInterval(() => {
          setRecordingVideoTime((prev) => prev + 1);
        }, 1000);
      };

      videoRecorderRef.current.ondataavailable = (e) => {
        videoChunk.push(e.data);
      };

      videoRecorderRef.current.onstop = async () => {
        clearInterval(videoTimerIntervalRef.current);
        const blob = new Blob(videoChunk, { type: 'video/ogg; codecs=opus' });
        videoChunk = [];
        const videoURL = window.URL.createObjectURL(blob);

        const durationSeconds = await getBlobDuration(videoURL);
        const durationFormatted = format(addSeconds(new Date(0), durationSeconds), 'mm:ss');
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          var base64data = reader.result?.toString().replace('data:video/ogg; codecs=opus;base64,', '');
          setVideo(base64data as string);
        };

        setBase64Files((prev) => [
          ...prev,
          {
            type: 'video',
            uri: videoURL,
            duration: durationFormatted,
          },
        ]);
      };
    } catch (err) {
      console.log('error media', err);
    }
  };

  const stopVideoRecording = () => {
    setIsRecording(false);
    setIconVideo('record-circle');
    videoRecorderRef?.current?.stop();
    setView(ViewStatus.Reporter);
  };

  const removeImageFromBase64Files = (item: any) => {
    if (item.type === 'audio') {
      setAudio(null);
    }
    if (item.type === 'video') {
      setVideo(null);
    }
    const removedItem = base64Files.filter((file: any) => file.uri !== item.uri);
    setBase64Files(removedItem);
  };

  const ImageItem = ({ item, index }: { item: Base64File; index: number }) => {
    return (
      <Div ml={index == 0 ? 0 : 8} key={`attach-item-${index}`} w={160} h={160}>
        <Image source={{ uri: item.uri }} w={160} h={160} />

        <RemoveImage onPress={() => removeImageFromBase64Files(item)}>
          <Icon fontSize={12} color={theme.colors.white} name="close" fontFamily="AntDesign" />
        </RemoveImage>
      </Div>
    );
  };

  const AudioItem = ({ item, index }: { item: Base64File; index: number }) => {
    const [playing, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState('');
    const audioRef = useRef<any>();

    const handlePlay = () => {
      setPlaying(true);
      audioRef.current.play();
    };

    const handlePause = () => {
      setPlaying(false);
      audioRef.current.pause();
    };

    const handleOnEnded = () => {
      setPlaying(false);
    };

    const handleOnTimeUpdate = () => {
      const currentTime = Math.floor(audioRef.current.currentTime);
      const currentTimeFormatted = format(addSeconds(new Date(0), currentTime), 'mm:ss');

      setCurrentTime(currentTimeFormatted);
    };

    return (
      <Div borderWidth={0.5} ml={index == 0 ? 0 : 8} key={`attach-item-${index}`} w={160} h={160}>
        <audio ref={audioRef} onEnded={handleOnEnded} onTimeUpdate={handleOnTimeUpdate}>
          <source src={item.uri} type="audio/ogg" />
        </audio>

        <TouchableOpacity
          onPress={playing ? handlePause : handlePlay}
          style={{
            alignContent: 'center',
            justifyContent: 'center',
            flex: 1,
          }}
        >
          <Icon fontSize={60} name={playing ? 'pausecircleo' : 'play'} fontFamily="AntDesign" />
          <Text textAlign="center">{playing ? currentTime : item.duration}</Text>
        </TouchableOpacity>

        <RemoveImage onPress={() => removeImageFromBase64Files(item)}>
          <Icon fontSize={12} color={theme.colors.white} name="close" fontFamily="AntDesign" />
        </RemoveImage>
      </Div>
    );
  };

  const VideoItem = ({ item, index }: { item: Base64File; index: number }) => {
    const [playing, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState('');
    const videoRef = useRef<any>();

    const handlePlay = () => {
      setPlaying(true);
      videoRef.current.play();
    };

    const handlePause = () => {
      setPlaying(false);
      videoRef.current.pause();
    };

    const handleOnEnded = () => {
      setPlaying(false);
    };

    const handleOnTimeUpdate = () => {
      const currentTime = Math.floor(videoRef.current.currentTime);
      const currentTimeFormatted = format(addSeconds(new Date(0), currentTime), 'mm:ss');

      setCurrentTime(currentTimeFormatted);
    };

    return (
      <Div borderWidth={0.5} ml={index == 0 ? 0 : 8} key={`attach-item-${index}`} w={160} h={160}>
        <video ref={videoRef} onEnded={handleOnEnded} style={{ height: 160 }} onTimeUpdate={handleOnTimeUpdate}>
          <source src={item.uri} type="audio/ogg" />
        </video>

        <TouchableOpacity
          onPress={playing ? handlePause : handlePlay}
          style={{
            alignContent: 'center',
            justifyContent: 'center',
            flex: 1,
            position: 'absolute',
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
            visibility: playing ? 'hidden' : 'visible',
          }}
        >
          <Icon fontSize={60} name={playing ? 'pausecircleo' : 'play'} fontFamily="AntDesign" />
          <Text fontWeight="bold" textAlign="center">
            {playing ? currentTime : item.duration}
          </Text>
        </TouchableOpacity>

        <RemoveImage onPress={() => removeImageFromBase64Files(item)}>
          <Icon fontSize={12} color={theme.colors.white} name="close" fontFamily="AntDesign" />
        </RemoveImage>
      </Div>
    );
  };

  if (hasPermission && view === ViewStatus.Camera) {
    return (
      <Div flex={1} h="600px" w="100%">
        <Camera style={{ height: 600 }} ref={cameraRef} type={type}>
          <Div h="100%" w="100%">
            <ChangeCamera
              onPress={() =>
                setType(type === Camera.Constants.Type.back ? Camera.Constants.Type.front : Camera.Constants.Type.back)
              }
            >
              <Icon fontSize={34} fontFamily="MaterialIcons" name="flip-camera-ios" color={theme.colors.black} />
            </ChangeCamera>
            <TakePic
              onPress={() => {
                modalizeRef.current?.slideDown();
                takePicture();
              }}
            >
              <Icon fontSize={34} fontFamily="AntDesign" name="camera" color={theme.colors.black} />
            </TakePic>
            <CancelTakePicture
              onPress={() => {
                modalizeRef.current?.slideDown();
                setView(ViewStatus.Reporter);
              }}
            >
              <Icon fontSize={34} fontFamily="AntDesign" name="close" color={theme.colors.black} />
            </CancelTakePicture>
          </Div>
        </Camera>
      </Div>
    );
  }

  if (hasPermission && view === ViewStatus.Video) {
    return (
      <Div flex={1} h="600px" w="100%">
        <Camera style={{ height: 600 }} ref={cameraRef} type={type}>
          <Div h="100%" w="100%">
            <ChangeCamera
              onPress={() =>
                setType(type === Camera.Constants.Type.back ? Camera.Constants.Type.front : Camera.Constants.Type.back)
              }
            >
              <Icon fontSize={34} fontFamily="MaterialIcons" name="flip-camera-ios" color={theme.colors.black} />
            </ChangeCamera>
            <TakePic onPress={!isRecording ? startVideoRecording : stopVideoRecording}>
              <Icon fontSize={34} fontFamily="MaterialCommunityIcons" name={iconVideo} color={theme.colors.red} />
            </TakePic>
            <CancelTakePicture
              onPress={() => {
                modalizeRef.current?.slideDown();
                setView(ViewStatus.Reporter);
              }}
            >
              <Icon fontSize={34} fontFamily="AntDesign" name="close" color={theme.colors.black} />
            </CancelTakePicture>
            {recordingVideoTime > 0 ? (
              <TimeRecorder>
                <Text color={theme.colors.white} fontWeight="bold">
                  {new Date(recordingVideoTime * 1000).toISOString().slice(14, 19)}
                </Text>
              </TimeRecorder>
            ) : null}
          </Div>
        </Camera>
      </Div>
    );
  }

  if (view === ViewStatus.Reporter) {
    return (
      <ScrollView style={{ width: '100%' }}>
        <Div flex={1} flexDir="column" w="100%">
          <ModalReportHeader
            text={text}
            loading={loading}
            closeModalReport={closeModalReport}
            handleSubmitReport={handleSubmitReport}
          />
          <Div
            flexDir="row"
            w="100%"
            borderBottomWidth={1}
            borderBottomColor="#E3E3E3"
            justifyContent="space-between"
            pt={32}
            pb={32}
            alignItems="center"
          >
            <Div w={80} ml={16}>
              <Text fontWeight="700" color={theme.colors.grey5}>
                Atividade
              </Text>
            </Div>
            <Div flexDir="row" mr={36}>
              <Radio
                onPress={() => setVisitaTecnicaChecked()}
                checked={isVisitaTecnica}
                activeColor={theme.colors.blue2}
                fontSize="sm"
                value={1}
              >
                <Text ml={4} color={theme.colors.grey5}>
                  Visita técnica
                </Text>
              </Radio>
              <Radio
                onPress={() => setAnomaliaChecked()}
                checked={isAnomalia}
                activeColor={theme.colors.blue2}
                ml={16}
                fontSize="sm"
                value={2}
              >
                <Text ml={4} color={theme.colors.grey5}>
                  Reportar anomalia
                </Text>
              </Radio>
            </Div>
          </Div>
          {recording ? (
            <Div
              px={24}
              py={18}
              flexDir="row"
              w="100%"
              borderBottomWidth={1}
              borderBottomColor="#E3E3E3"
              alignItems="center"
              justifyContent="center"
            >
              <Text ml={4} color={theme.colors.grey5} fontSize={25}>
                Gravando {format(addSeconds(new Date(0), recordingTime), 'mm:ss')}
              </Text>
            </Div>
          ) : null}
          <Div
            px={24}
            py={18}
            flexDir="row"
            w="100%"
            borderBottomWidth={1}
            borderBottomColor="#E3E3E3"
            justifyContent="space-between"
          >
            <ButtonActions onPress={getCameraPermission} title="Foto" iconName="camera" iconFontFamily="AntDesign" />

            <ButtonActions
              onPress={getVideoPermission}
              title="Vídeo"
              iconName="videocamera"
              iconFontFamily="AntDesign"
            />

            <ButtonActions
              onPress={recording ? stopAudioRecording : startAudioRecording}
              title="Áudio"
              iconName="microphone"
              iconFontFamily="FontAwesome"
            />
            <ButtonActions onPress={pickImage} title="Anexo" iconName="paperclip" iconFontFamily="AntDesign" />
          </Div>
          <TextInput
            value={text}
            onChangeText={setText}
            style={styles.textInput}
            placeholder="Digite seu comentário..."
          ></TextInput>
          <Div
            mt={base64Files.length ? 12 : 0}
            ml={base64Files.length ? 12 : 0}
            mr={base64Files.length ? 12 : 0}
            flexDir="row"
          >
            {base64Files.map((item, index) => {
              if (item.type == 'image') return <ImageItem item={item} index={index} />;
              if (item.type == 'audio') return <AudioItem item={item} index={index} />;
              if (item.type == 'video') return <VideoItem item={item} index={index} />;
            })}
          </Div>
          {isAnomalia && (
            <>
              {details.length ? (
                <Div flex={1} w="100%" borderColor={theme.colors.grey7} borderTopWidth={1} borderBottomWidth={1}>
                  <Text ml={12} fontWeight="700" color={theme.colors.grey5} mt={12}>
                    Problemas
                  </Text>
                </Div>
              ) : null}
              {details.length
                ? details.map((item) => (
                    <Div
                      key={item.causeId}
                      flex={1}
                      flexDir="row"
                      w="100%"
                      borderColor={theme.colors.grey7}
                      justifyContent="space-between"
                      pr={12}
                      borderTopWidth={1}
                      borderBottomWidth={1}
                    >
                      <Text ml={12} mt={12}>
                        {item.hint
                          ? item.hint + ' ' + `(${item.causeName})`
                          : 'Sem descrição do problema' + ' ' + `(${item.causeName})`}
                      </Text>
                      <TouchableOpacity onPress={() => setView(ViewStatus.ListProblem)} style={styles.touchableIcon}>
                        <Icon
                          fontSize={26}
                          color={theme.colors.grey5}
                          name="keyboard-arrow-right"
                          fontFamily="MaterialIcons"
                        />
                      </TouchableOpacity>
                    </Div>
                  ))
                : null}
              <TouchableOpacity onPress={() => setView(ViewStatus.ListProblem)} style={styles.reportProblem}>
                <Icon color="red" fontSize={20} name="add" fontFamily="MaterialIcons" />
                <Text fontSize={20} ml={4} color="red">
                  Adicionar um problema
                </Text>
              </TouchableOpacity>
            </>
          )}
          <Day />
          <Hour formattedHour={formattedHour} />
        </Div>
      </ScrollView>
    );
  }

  return (
    <ScrollView style={{ width: '100%' }}>
      <Div flex={1} flexDir="column" p={16} w="100%">
        <SelectProblems details={details} checkedValues={checkedValues} setView={setView} />
        <Div mt={12} flexDir="column">
          {problems.length &&
            problems.map((item: ProblemReport) => (
              <Div key={item.id} flexDir="column">
                <Checkbox
                  checked={checkedValues.includes(item.id)}
                  onChange={() => {
                    setUpdate(!update);
                    checkboxValues(item.id, item.name);
                  }}
                  mt={12}
                  activeColor={theme.colors.grey5}
                  inactiveColor={theme.colors.grey3}
                  value={item.id}
                  prefix={<Text flex={1}>{item.name}</Text>}
                />
                {checkedValues.includes(item.id) && (
                  <TextInput
                    value={getValueText(item.id)}
                    onChangeText={(text: string) => {
                      setUpdate(!update);
                      descriptionProblemReport(item.id, text, item.name);
                    }}
                    style={styles.textInput}
                    placeholder={`Digite seu comentário sobre ${item.name}`}
                  ></TextInput>
                )}
              </Div>
            ))}
        </Div>
      </Div>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  textInput: {
    height: 75,
    paddingLeft: 16,
    backgroundColor: theme.colors.grey1,
    textAlignVertical: 'top',
    // outline: 'none',
  },
  reportProblem: {
    backgroundColor: theme.colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
    height: 60,
    border: `1px solid ${theme.colors.grey7}`,
  },
  touchableIcon: {
    marginRight: 16,
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },

  button: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    position: 'absolute',
    padding: 8,
  },
  text: {
    fontSize: 18,
    color: 'white',
  },
});

export default ModalReport;
