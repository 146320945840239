import React, { useEffect, useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { List } from '../List';
import { Search } from '../Search';
import { Agricultores } from '../Agricultores';
import { ButtonVerTodos } from '../ButtonVerTodos';
import { Client } from '../../services/api/Clients';

import { useClient } from '../../hooks/Client';

import { styles } from './style';
import { search } from '../../utils/Search';
import { Routes } from '../../screens/Tabs/HomeTab';

export const ModalAgricultores = ({ navigate }: { navigate: (route: Routes) => void }) => {
  const { setClients, clients, setSelectedClient } = useClient();

  const [searchQuery, setSearchQuery] = React.useState('');

  useEffect(() => {
    setClients();
  }, []);

  const handleSearch = useMemo(() => search(searchQuery, clients), [clients, searchQuery]);

  function handleAgricultor(data: Client) {
    setSelectedClient(data);
    navigate(Routes.FARM);
  }

  return (
    <View style={styles.content}>
      <View style={styles.subcontainer}>
        <TouchableOpacity>
          <Text style={styles.content__heading}>Agricultores</Text>
        </TouchableOpacity>
      </View>
      <Search placeholder="Buscar Agricultores" searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      <Text style={styles.favoriteStyle}>Todos os agricultores</Text>
      <List
        data={handleSearch}
        render={(item) => (
          <Agricultores data={item as Client} handleSelectId={() => handleAgricultor(item as Client)} />
        )}
      />
    </View>
  );
};
