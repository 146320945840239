import React from 'react';
import { Div, Icon, Button, Text } from 'react-native-magnus';
import { TouchableOpacity } from 'react-native';
import theme from '../../global/styles/theme';
import { DetailsReport, ViewStatus } from './index';
import { AlertMessage } from '../../utils/AlertMessage';

type SelectProblemsProps = {
  setView: (string: ViewStatus) => void;
  checkedValues: Array<number>;
  details: DetailsReport[];
};

export const SelectProblems = ({ setView, checkedValues, details }: SelectProblemsProps) => {
  const addProblems = () => {
    for (let i = 0; i < details.length; i++) {
      if (!details[i].hint) {
        AlertMessage('A descrição do problema é obrigatória!');
        return;
      }
    }
    setView(ViewStatus.Reporter);
  };

  return (
    <Div flexDir="row" h={40} justifyContent="space-between" alignItems="center">
      <Div flexDir="row" w={200}>
        <TouchableOpacity onPress={() => setView(ViewStatus.Reporter)}>
          <Icon name="arrow-left" fontSize={18} fontFamily="Feather" />
        </TouchableOpacity>
        <Text ml={16} fontWeight="700">
          Selecionar problemas
        </Text>
      </Div>
      <Button disabled={!checkedValues.length} onPress={addProblems} mr={16} bg="transparent">
        <Text fontWeight="bold" color={!checkedValues.length ? theme.colors.grey3 : theme.colors.blue2}>
          Adicionar
        </Text>
      </Button>
    </Div>
  );
};
