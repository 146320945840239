import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    // flex: 1,
    height: 350,
    //alignItems: 'center',
  },
  divider: {
    width: '100%',
  },
});
