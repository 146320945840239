import api from '..';
import { AxiosResponse } from 'axios';

export type Crs = {
  type: string;
  properties: {
    name: string;
  };
};

export type TheGeom = {
  crs: Crs;
  type: string;
  coordinates: [[[number, number]]];
};

export type Area = {
  id: number;
  name: string;
  the_geom: TheGeom;
  block: string;
  field: string;
  /**
   * @template '2021-2022'
   */
  year: string;
  deleted: false;
  farm_id: number;
  has_cycle: true;
  total_area: number;
  errors: {};
  manager_id: number;
  v2_id: null;
  v2_sync: string;
  sm_id: number;
};

export type AreasParams = {
  userId: Number;
  farmId: Number;
  lastSyncDate: string;
  safra?: string;
};

export type AreaResponse = {
  areas: Area[];
};

export const getAreas = (params: AreasParams): Promise<AxiosResponse<AreaResponse>> => {
  return api.get('sync', {
    params: {
      target: 'areas',
      ...params,
    },
  });
};
