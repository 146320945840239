import styled from 'styled-components/native';
import { RectButton } from 'react-native-gesture-handler';

interface ButtonProps {
  color: string;
}

interface ButtonTextProps {
  light: boolean;
}

export const Container = styled(RectButton)<ButtonProps>`
  width: 100%;
  height: 56px;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
  margin-bottom: 8px;
  border-radius: 8px;
`;

export const Title = styled.Text<ButtonTextProps>`
  font-family: ${({ theme }) => theme.fonts.title700};
  font-size: 15px;
  color: ${({ theme, light }) => (light ? theme.colors.white : theme.colors.white)};
`;
