import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../contexts';
import { AreaState } from '../contexts/reducers/AreaReducer';
import { Area, getAreas } from '../services/api/Areas';
import { GetAreaStorage, SetAreaStorage } from '../services/database/areaStorage';
import { DEFAULT_SYNC_DATE } from '../utils/constantes';

export function useAreas() {
  const { areaState, areaDispatch, farmState, authState, farmDispatch } = useContext(AppContext);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (areaState.areas.length && update && farmState.selectedFarm) {
      SetAreaStorage(farmState.selectedFarm.id, areaState);
      setUpdate(false);
    }
  }, [update]);

  const setArea = useCallback(async () => {
    if (!farmState.selectedFarm) return;

    areaDispatch({
      type: 'resetAreaState',
    });

    const farmId = farmState.selectedFarm.id;
    const safra = new Date().getFullYear() + '-' + (new Date().getFullYear() + 1);

    const stored = await GetAreaStorage(farmId);
    if (stored && stored.areas.length) {
      areaDispatch({
        type: 'setAreaState',
        payload: stored,
      });
    }

    const { data } = await getAreas({
      userId: authState.userId,
      farmId,
      lastSyncDate: stored ? stored.lastSyncDate : DEFAULT_SYNC_DATE,
    });

    if (data && data.areas.length) {
      areaDispatch({
        type: 'updateAreaState',
        payload: {
          farmId: farmId,
          ...data,
        },
      });
      setUpdate(true);
      return;
    }
  }, [areaState]);

  const setSelectedArea = useCallback(
    (area: Area | undefined) => {
      areaDispatch({
        type: 'setSelectedArea',
        payload: area,
      });
    },
    [areaState],
  );

  const setSelectedYearSafra = useCallback(
    (year: string | undefined) => {
      areaDispatch({
        type: 'setSelectedYearSafra',
        payload: year,
      });
    },
    [areaState],
  );

  const setAreasGroup = useCallback(
    (areas: any) => {
      areaDispatch({
        type: 'setAreasGroup',
        payload: areas,
      });
    },
    [areaState],
  );

  const resetFarm = () => {
    farmDispatch({
      type: 'resetSelectedFarm',
    });
  };

  const areas = useMemo(() => areaState.areas, [areaState]);

  const selectedArea = useMemo(() => areaState.selectedArea, [areaState]);

  const selectedYearSafra = useMemo(() => areaState.yearSafra, [areaState]);

  const selectedAreasGroup = useMemo(() => areaState.areasGroup, [areaState]);

  return {
    areas,
    setArea,
    selectedArea,
    setSelectedArea,
    resetFarm,
    setSelectedYearSafra,
    selectedYearSafra,
    selectedAreasGroup,
    setAreasGroup,
  };
}
