import { Roboto_400Regular } from '@expo-google-fonts/roboto';
import { StyleSheet, Dimensions } from 'react-native';
import theme from '../../global/styles/theme';

const Screen = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
};

export const styles = StyleSheet.create({
  content: {
    paddingTop: 24,
    zIndex: 2,
    height: '100%',
  },
  content__modal: {
    zIndex: 2,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 6 },
    shadowOpacity: 0.45,
    shadowRadius: 16,
  },
  button: {
    marginTop: 10,
    paddingTop: 15,
    paddingBottom: 15,
    marginLeft: 30,
    marginRight: 30,
    backgroundColor: '#66CF97',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
    alignItems: 'center',
    padding: 10,
    justifyContent: 'center',
  },
  buttonCreateNote: {
    marginTop: 15,
    paddingTop: 15,
    paddingBottom: 15,
    marginLeft: 30,
    marginRight: 30,
    backgroundColor: 'white',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'red',
    alignItems: 'center',
    padding: 10,
    justifyContent: 'center',
  },
  button2: {
    marginTop: 10,
    paddingTop: 15,
    paddingBottom: 15,
    marginLeft: 30,
    marginRight: 30,
    backgroundColor: '#FAECCC',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
    alignItems: 'center',
    padding: 10,
    justifyContent: 'center',
  },
  fillBackground: {
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  content__subheading: {
    marginBottom: 2,

    fontSize: 14,
    fontWeight: 'bold',
    color: theme.colors.grey5,
    fontFamily: theme.fonts.text400,
  },

  content__heading: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#333',
    fontFamily: theme.fonts.text400,
    marginBottom: 5,
  },
  content__heading2: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#333',
    fontFamily: theme.fonts.text400,
    marginBottom: 5,
    marginTop: 10,
  },
  content__button: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#FFFFFF',
    fontFamily: theme.fonts.text400,
    justifyContent: 'flex-end',
    flex: 1,
  },
  contentButtonCreateNote: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'red',
    fontFamily: theme.fonts.text400,
    justifyContent: 'flex-end',
    flex: 1,
  },
  content__button2: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#E69F00',
    fontFamily: theme.fonts.text400,
    marginBottom: 5,
    justifyContent: 'flex-end',
    flex: 1,
  },

  content__note: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#333333',
    fontFamily: theme.fonts.text400,
    marginBottom: 5,
    textAlign: 'right',
  },

  content__note_time: {
    fontSize: 10,
    fontWeight: 'bold',
    color: '#333333',
    fontFamily: theme.fonts.text400,
    margin: 5,
    textAlign: 'right',
  },

  note: {
    flex: 1,
    marginTop: 10,
    paddingTop: 15,
    paddingBottom: 15,
    marginLeft: 'auto',
    maxWidth: '50%',
    marginRight: 30,
    backgroundColor: '#fff',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
    padding: 10,
  },

  content__description: {
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 15,
    fontWeight: '200',
    lineHeight: 22,
    color: '#666',
  },
  cart_layout: {
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: 56,
  },
  cart_detail: {
    paddingLeft: 20,
    paddingRight: 20,
    height: 52,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon_claw: {
    position: 'absolute',
    top: 2,
    bottom: 0,
    left: Screen.width / 2 - 16,
    right: Screen.width / 2 - 16,
    width: 32,
    height: 6,
  },
  icon_checkout: {
    width: 16,
    height: 16,
  },
  icon_cart: {
    width: 22,
    height: 23,
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.colors.grey5,
    marginTop: 16,
    fontFamily: theme.fonts.text500,
    marginBottom: 19,
    marginLeft: 16,
  },
  favoriteStyle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.colors.grey5,
    marginTop: 16,
    fontFamily: theme.fonts.text500,
    marginBottom: 19,
    marginLeft: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  listTextStyle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.colors.grey5,
    fontFamily: theme.fonts.text500,
    marginLeft: 25,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '90%',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 16,
  },
  containerList: {
    width: '90%',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 16,
    marginBottom: 5,
  },
  subTitleStyle: {
    fontSize: 10,
    fontWeight: 'bold',
    color: theme.colors.grey5,
    marginTop: 16,
    fontFamily: theme.fonts.text500,
    marginBottom: 19,
    marginLeft: 16,
  },

  item: {
    width: 150,
    height: 50,
    backgroundColor: '#3966ae',
    padding: 20,
    marginLeft: 16,
    marginHorizontal: 16,
    borderRadius: 8,
  },
  moreContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
  },
  subcontainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 16,
    marginRight: 16,
  },
  listaagricultores: {
    flexDirection: 'row',
    marginBottom: 16,
    marginLeft: 16,
    marginRight: 16,
  },
  containerFavorito: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
