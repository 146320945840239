import React from 'react';
import AmostragensIcon from '../../../assets/Svg/AmostragensIcon';
import CompactacoesIcon from '../../../assets/Svg/CompactacoesIcon';
import MapasFlatListIcon from '../../../assets/Svg/MapasFlatListIcon';
import PermanentesIcon from '../../../assets/Svg/PermanentesIcon';
import ProdutividadeIcon from '../../../assets/Svg/ProdutividadeIcon';
import RecomendacoesIcon from '../../../assets/Svg/RecomendacoesIcon';
import SateliteIcon from '../../../assets/Svg/SateliteIcon';
import TalhaoIcon from '../../../assets/Svg/TalhaoIcon';

const Talhao = <TalhaoIcon height={24} width={24} />;
const Mapas = <MapasFlatListIcon height={24} width={24} />;
const Amostragens = <AmostragensIcon height={24} width={24} />;
const Produtividade = <ProdutividadeIcon height={24} width={24} />;
const Recomendacoes = <RecomendacoesIcon height={24} width={24} />;
const Satelite = <SateliteIcon height={24} width={24} />;
const Compactacoes = <CompactacoesIcon height={24} width={24} />;
const Permanentes = <PermanentesIcon height={24} width={24} />;

export let DATA = [
  {
    id: '1',
    title: 'Fertilidade',
    icon: Talhao,
    active: false,
  },

  {
    id: '2',
    title: 'Imagens de satélite',
    icon: Satelite,
    active: false,
  },
  // {
  //   id: '3',
  //   title: 'Compactaçoes',
  //   icon: Compactacoes,
  //   active: false
  // },
  // {
  //   id: '8',
  //   title: 'Mapas',
  //   icon: Mapas,
  //   active: false
  // }
];
