import { FerlilitiesValues, UnityFertility } from '../../services/api/ElementsFromMap';

export type FertilityState = {
  fertilities: FerlilitiesValues[];
  selectedFertility?: FerlilitiesValues;
};

export interface ResetFertilitiesFromAreaState {
  type: 'resetFertilitiesFromArea';
}

export interface SetFertilitiesFromAreaState {
  type: 'SetFertilitiesFromArea';
  payload: FerlilitiesValues[];
}

export interface SetSelectedFertilityState {
  type: 'SetSelectedFertility';
  payload: FerlilitiesValues;
}

export type FertilitiesActions =
  | SetFertilitiesFromAreaState
  | ResetFertilitiesFromAreaState
  | SetSelectedFertilityState;

export const DEFAULT_FERTILITIES_STATE: FertilityState = {
  fertilities: [],
  selectedFertility: undefined,
};

const FertilityFromAreaReducer = (state: FertilityState, action: FertilitiesActions): FertilityState => {
  switch (action.type) {
    case 'resetFertilitiesFromArea': {
      return DEFAULT_FERTILITIES_STATE;
    }

    case 'SetFertilitiesFromArea': {
      return {
        ...state,
        fertilities: action.payload,
      };
    }

    case 'SetSelectedFertility': {
      return {
        ...state,
        selectedFertility: action.payload,
      };
    }

    default:
      return state;
  }
};

export default FertilityFromAreaReducer;
