import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxTwoo: {
    zIndex: 2,
    position: 'absolute',
    left: 5,
    bottom: 5,
    backgroundColor: 'white',
    borderRadius: 5,
  },
  subContainerRight: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: 16,
    top: 90,
    zIndex: 2,
    height: 290,
    justifyContent: 'space-between',
  },
  boxNiveisMap: {
    width: 42,
    height: 42,
    zIndex: 2,
    backgroundColor: '#fff',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxReport: {
    width: 42,
    height: 42,
    zIndex: 2,
    backgroundColor: '#FF6666',
    borderRadius: 8,
    marginTop: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxCentralizarMap: {
    width: 42,
    height: 42,
    zIndex: 2,
    backgroundColor: '#fff',
    borderRadius: 8,
    marginTop: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  Point2: {
    width: 100,
    height: 100,
  },
});
