import React from 'react';
import { TouchableOpacity, TouchableOpacityProps, StyleSheet } from 'react-native';
import { Icon, Text } from 'react-native-magnus';
import theme from '../../global/styles/theme';

interface ButtonActionsProps extends TouchableOpacityProps {
  title: 'Foto' | 'Áudio' | 'Anexo' | 'Vídeo';
  iconName: string;
  iconFontFamily: 'AntDesign' | 'FontAwesome';
  onPress?: () => void;
  onPressIn?: () => void;
  onPressOut?: () => void;
}

export const ButtonActions = ({
  title,
  iconName,
  iconFontFamily,
  onPress,
  onPressIn,
  onPressOut,
}: ButtonActionsProps) => {
  return (
    <TouchableOpacity onPress={onPress} onPressIn={onPressIn} onPressOut={onPressOut} style={styles.button}>
      <Icon name={iconName} fontFamily={iconFontFamily} fontSize={12} color={theme.colors.blue2} />
      <Text fontWeight="700" ml={4} color={theme.colors.blue2} fontSize={10}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: 'transparent',
    borderColor: theme.colors.blue2,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 4,
    paddingRight: 4,
    width: 70,
    height: 24,
    borderRadius: 100,
    flexDirection: 'row',
  },
});
