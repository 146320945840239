import { AxiosResponse } from 'axios';
import api from '../';

export type AuthRequest = {
  email: string;
  password: string;
};

export type Resource = {
  name: string;
  attributes: string;
};

export type Grant = {
  role: string;
  resources: Resource[];
};

export enum userRoles {
  ADMIN = 'admin',
  TECNICAL_ASSISTENT = 'tecnicalAssistent',
  FARMER = 'farmer',
  SAMPLER = 'sampler',
}

export type AuthResponse = {
  token: string;
  userId: number;
  grants: Grant[];
  nome: string;
  email: string;
  clientId: number;
};

export type BodyRecoverPass = {
  email: string;
};

export const authenticate = (login: AuthRequest): Promise<AxiosResponse<AuthResponse>> => {
  return api.post('users/sign_in', login);
};

export const recoverPassword = (body: BodyRecoverPass): Promise<AxiosResponse<string>> => {
  return api.post('users/forgot-password', body);
};
