import React, { useEffect, useMemo, useRef, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Div } from 'react-native-magnus';

import { ButtonVerTodos } from '../ButtonVerTodos';
import { Search } from '../Search';
import { styles } from './styles';
import VoltarIcon from '../../../assets/Svg/VoltarIcon';

import { List } from '../List';
import { useAreas } from '../../hooks/Area';
import { search } from '../../utils/Search';
import { Talhoes } from '../Talhoes';
import { Area } from '../../services/api/Areas';
import { Routes } from '../../screens/Tabs/HomeTab';
import { useFarms } from '../../hooks/Farm';

export const ModalTalhoes = ({ navigate }: { navigate: (route: Routes) => void }) => {
  const { setArea, areas, setSelectedArea, resetFarm, setAreasGroup: setGroupedAreas } = useAreas();
  const { selectedFarm, setSelectedFarm } = useFarms();

  const [talhaoNomeSelecionado, setTalhaoNomeSelecionado] = useState('');
  const [searchQuery, setSearchQuery] = React.useState('');
  const [areasGroup, setAreasGroup] = React.useState([]);

  useEffect(() => {
    setArea();
  }, []);

  useEffect(() => {
    const _areasGroup = areas.reduce((acc, cur) => {
      const curItem = acc.find((item) => item.name == cur.name);

      if (curItem) {
        acc = [
          ...acc.filter((item) => item.name != cur.name),
          {
            ...curItem,
            areas: [
              ...curItem.areas,
              {
                id: cur.id,
                year: cur.year,
              },
            ],
          },
        ];
      } else {
        acc.push({
          ...cur,
          areas: [
            {
              id: cur.id,
              year: cur.year,
            },
          ],
        });
      }
      return acc;
    }, []);

    setAreasGroup(_areasGroup);
    setGroupedAreas(_areasGroup);
  }, [areas]);

  // const handleSearch = useMemo(() => search(searchQuery, areas), [areas, searchQuery]);

  function handleSelectId(data: any) {
    console.log('handleSelectId TALHAO DATA', data);

    setSelectedArea(data);
    navigate(Routes.AREA_SELECTED);
  }

  const handleGoBack = () => {
    resetFarm();
    setSelectedFarm(undefined);
    navigate(Routes.FARM);
  };

  return (
    <View style={styles.content}>
      <View style={styles.subcontainer}>
        <Div flex={1} flexDir="row" justifyContent="flex-start" alignItems="center">
          <TouchableOpacity style={{ marginTop: 4 }} onPress={handleGoBack}>
            <VoltarIcon color="#737373" height={24} width={24} />
          </TouchableOpacity>
          <Text style={styles.subtitle}>{selectedFarm?.name}</Text>
        </Div>

        <Text style={styles.content__heading}>{talhaoNomeSelecionado}</Text>
      </View>

      <View>
        <Search placeholder="Pesquisar Talhões" searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <Text style={{ marginTop: 16, marginLeft: 16 }}>Todos os talhões</Text>
        <List
          // data={handleSearch}
          // data={searchQuery}
          data={areasGroup}
          render={(item) => (
            <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', paddingHorizontal: 10 }}>
              <Talhoes data={item as Area} handleSelectId={() => handleSelectId(item as Area)} />
              {/* <Text>Total Areas: {item.areas.length}</Text> */}
            </View>
          )}
        />
      </View>
    </View>
  );
};
