import React, { useMemo, JSXElementConstructor } from 'react';
import { FlatList, TouchableOpacity, View } from 'react-native';

import { ListDivider } from '../ListDivider';

import { styles } from './styles';
import { Client } from '../../services/api/Clients';
import { Agricultores } from '../Agricultores';
import { Farm } from '../../services/api/Farms';
import { Area } from '../../services/api/Areas';
import { Data } from '../ListFazendas/data';

export type ListProps = {
  data?: ReadonlyArray<Farm | Client | Area | any>;
  render: (data: Farm | Client | Area | any) => JSX.Element;
};

export function List({ data, render }: ListProps) {
  // const removeDuplicateFromArray = data?.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i) || data;
  const removeDuplicateFromArray = data;
  return (
    <View style={styles.container}>
      <FlatList
        data={removeDuplicateFromArray}
        // keyExtractor={(item: any) => item.id.toString()}
        keyExtractor={(item: any) => `${item.name}${item.farm_id}`}
        renderItem={({ item }) => render(item)}
        ListHeaderComponent={() => <ListDivider isCentered />}
        ItemSeparatorComponent={() => <ListDivider isCentered />}
        contentContainerStyle={{ paddingBottom: 50, paddingTop: 1 }}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        style={styles.divider}
      />
    </View>
  );
}
