import React, { useCallback, useState } from 'react';
import { View, Text, TextInput, TouchableOpacity } from 'react-native';

import { RouteProp, useNavigation } from '@react-navigation/native';

import { styles } from './styles';
import theme from '../../global/styles/theme';

import ButtonCloseIcon from '../../../assets/Svg/ButtonCloseIcon';
import { ButtonComponent } from '../../components/Form/ButtonComponent';
import { useAuth } from '../../hooks/Auth';
import { AlertMessage } from '../../utils/AlertMessage';

export function EsqueciMinhaSenha({ route }: { route: RouteProp<{ params: { email: string } }, 'params'> }) {
  const navigation = useNavigation();
  const { forgotPassword } = useAuth();

  const [userName, setUserName] = useState<string>(route.params.email ? route.params.email : '');
  const [errorEmail, setErrorEmail] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const anotherFunc = () => {
    setUserName('');
  };

  const validar = () => {
    setTimeout(function () {
      let error = false;
      setErrorEmail(null);

      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!re.test(String(userName).toLowerCase())) {
        setErrorEmail('Preencha seu e-mail corretamente');
        error = true;
      }
      return !error;
    }, 2000);
  };

  const handleForgotPass = useCallback(async () => {
    setLoading(true);
    const response = await forgotPassword(userName);
    setLoading(false);
    AlertMessage(response);
    navigation.goBack();
  }, [userName]);

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <Text style={styles.textContainer}>Recuperar minha senha</Text>
      </View>
      <View style={styles.terceiroContainer}>
        <Text style={styles.segundoTexto}>
          Se você esqueceu sua senha, não se preocupe. Informe seu {'\n'}
          e-mail utilizado em seu cadastro que enviaremos um link e {'\n'}
          um código de acesso para você cadastrar uma nova senha.
        </Text>
      </View>
      <View style={styles.containerInput}>
        <Text style={styles.emailText}>Email</Text>
        <View style={styles.emailContainer}>
          <View style={{ flexDirection: 'row' }}>
            <TextInput
              style={[
                styles.textInput,
                errorEmail
                  ? {
                      borderColor: theme.colors.warninglight,
                      color: theme.colors.warninglight,
                    }
                  : { borderColor: theme.colors.grey2 },
              ]}
              placeholder="Digite seu email"
              onChangeText={(text) => {
                setUserName(text);
                validar();
              }}
              value={userName}
            />

            {!!userName && (
              <View style={{ position: 'absolute', right: 13, marginTop: 8 }}>
                <TouchableOpacity
                  onPress={() => {
                    anotherFunc();
                  }}
                >
                  <ButtonCloseIcon width={40} height={46} />
                </TouchableOpacity>
              </View>
            )}
          </View>
          {!!errorEmail && (
            <Text
              style={{
                color: 'red',
                fontSize: 12,
                fontFamily: 'bold',
                marginTop: 2,
                alignSelf: 'flex-end',
                marginRight: 16,
              }}
            >
              Email inválido
            </Text>
          )}

          <View>
            <View style={styles.buttonContainer}>
              <ButtonComponent
                title="Enviar"
                loading={loading}
                activeOpacity={0.7}
                enabled={!!userName}
                onPress={handleForgotPass}
              />
            </View>
          </View>
        </View>
        {/* <TouchableOpacity onPress={moveToAlterarSenha}>
                    <Text style={{ marginTop: 20 }}>Senha nova</Text>
                </TouchableOpacity> */}
      </View>
    </View>
  );
}
