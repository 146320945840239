import React, { useState, useCallback, useEffect, useRef } from 'react';
import { View, Text, TouchableOpacity, ImageBackground, TextInput, Image } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import ButtonCloseIcon from '../../../assets/Svg/ButtonCloseIcon';

import { styles } from './styles';
import theme from '../../global/styles/theme';
import { ButtonComponent } from '../../components/Form/ButtonComponent';

import { InputPassword } from '../../components/Form/InputPassword';
import { useAuth } from '../../hooks/Auth';
import { authScreensProp } from '../../routes/auth.routes';
import logoInceres from '../../../assets/logo-inceres-branco.png';
import { Permission } from '../../components/Permission';

export const Login: React.FC = () => {
  const { signIn } = useAuth();
  const navigation = useNavigation<authScreensProp>();

  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [errorPassword, setErrorPassword] = useState<boolean>(false);
  const [email, setUserName] = useState<string>(__DEV__ ? 'sandro.sark+consultor@gmail.com' : '');
  const [password, setPassword] = useState<string>(__DEV__ ? '123456' : '');
  const [loading, setLoading] = useState(false);
  const hidePass: boolean = true;

  const isMounted = useRef(true);

  const validateEmail = (validatedEmail: string) => {
    setErrorEmail(false);
    const re =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;

    if (!re.test(String(validatedEmail).toLowerCase())) {
      setErrorEmail(true);
    }
  };

  const validatePassword = (textPassword: string) => {
    setErrorPassword(false);
    if (!textPassword.length) {
      setErrorPassword(true);
    }
  };

  const handleSignIn = useCallback(async () => {
    setLoading(true);
    await signIn({ email, password });
    if (isMounted.current) {
      setLoading(false);
    }
  }, [email, password]);

  const moveToEsqueciMinhaSenha = useCallback(() => {
    navigation.navigate('EsqueciMinhaSenha', { email });
  }, [email]);

  const moveToPrecisoDeAjuda = useCallback(() => {
    const url = 'https://inceres.octadesk.com/kb';
    window.open(url, '_blank');
  }, []);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );

  return (
    <ImageBackground style={styles.container} source={require('../../../assets/Bg.png')}>
      <View style={styles.logoContainer}>
        <Image source={logoInceres} style={styles.logoImage} />
      </View>

      <View style={styles.inputsContainer}>
        <Text style={styles.textEmail}>E-mail</Text>
        <View style={{ flexDirection: 'row' }}>
          <TextInput
            style={[
              styles.textInput,
              errorEmail
                ? {
                    borderColor: theme.colors.warninglight,
                    color: theme.colors.warninglight,
                  }
                : { borderColor: theme.colors.grey2 },
            ]}
            placeholder="Digite seu email"
            onChangeText={(text) => {
              setUserName(text);
              validateEmail(text);
            }}
            value={email}
          />

          {!!email && (
            <View style={{ position: 'absolute', right: 16, marginTop: 8 }}>
              <TouchableOpacity onPress={() => setUserName('')}>
                <ButtonCloseIcon width={40} height={46} />
              </TouchableOpacity>
            </View>
          )}
        </View>

        {!!errorEmail && <Text style={styles.textInvalida}>Email inválido</Text>}

        <Text style={styles.textSenha}>Senha</Text>

        <InputPassword
          icon="eye"
          placeholder="Digite sua senha"
          onChangeText={(text) => {
            setPassword(text);
            validatePassword(text);
          }}
          value={password}
          secureTextEntry={hidePass}
          autoCorrect={false}
        />

        {!!errorPassword && <Text style={styles.textInvalida}>Senha inválida</Text>}
      </View>

      <View style={styles.buttonContainer}>
        <ButtonComponent
          onPress={handleSignIn}
          title="Iniciar agora"
          color={theme.colors.confirmlight}
          enabled={!!password || !!email}
          loading={loading}
        />
      </View>

      <View style={styles.esqueceuContainer}>
        <TouchableOpacity onPress={moveToPrecisoDeAjuda}>
          <Text style={styles.textAjuda}>Eu preciso de ajuda</Text>
        </TouchableOpacity>

        <TouchableOpacity onPress={moveToEsqueciMinhaSenha}>
          <Text style={styles.textEsqueci}>Esqueci minha senha</Text>
        </TouchableOpacity>
      </View>
    </ImageBackground>
  );
};
