import api from '..';
import { AxiosResponse } from 'axios';

interface Details {
  hint?: string;
  causeId?: number;
  image: string;
}

export interface BodyProblemReport {
  areaId: number;
  description: string;
  cropYear: string;
  details: Details[];
  audio?: string;
  video?: string;
  latitude?: number;
  longitude?: number;
}

export const getProblemsToReport = async (): Promise<AxiosResponse> => {
  return api.get('/anomalyCauses');
};

export const sendProblemsToReport = async (body: BodyProblemReport): Promise<AxiosResponse> => {
  return api.post('/anomalies', body);
};
