import AsyncStorage from '@react-native-async-storage/async-storage';
import { FarmState } from '../../contexts/reducers/FarmReducer';

export const STORAGE_AUTH_ALIAS = '@Farm';

export const GetFarmStorage = async (id: number): Promise<FarmState> => {
  const farm = await AsyncStorage.getItem(`${STORAGE_AUTH_ALIAS}_${id}`);
  return !!farm ? JSON.parse(farm) : null;
};

export const SetFarmStorage = async (id: number, farmState: FarmState) => {
  await AsyncStorage.setItem(`${STORAGE_AUTH_ALIAS}_${id}`, JSON.stringify(farmState));
};

export const DeleteFarmStorage = async (id: number) => {
  await AsyncStorage.removeItem(`${STORAGE_AUTH_ALIAS}_${id}`);
};
