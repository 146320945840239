import React from "react";
import Svg, { Path, SvgProps, Circle } from "react-native-svg";

const BuscarBottonTabsIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <Circle cx="6.5" cy="6.5" r="4.5" stroke={props.color} />
      <Path
        d="M13.75 14.25L9.5 10"
        stroke={props.color}
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default BuscarBottonTabsIcon;
