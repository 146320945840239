import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { Alert, Text, Pressable, View } from 'react-native';
import Semconexao from '../../../assets/Svg/Semconexao';
import { authScreensProp } from '../../routes/auth.routes';
import { styles } from './styles';

export const Permission = () => {
  const navigation = useNavigation<authScreensProp>();
  return (
    <View style={styles.centeredView}>
      <Semconexao width={200} height={200} />
      <View style={{ marginTop: 20 }}>
        <Text style={styles.TextGps}>Acesso ao local (GPS)</Text>
        <View style={{ marginTop: 20 }}>
          <Text>Sua localização atual será mostrada no mapa{`\n`}e usada para obter dados de geolocalização.</Text>
        </View>
      </View>
      <View style={{ flexDirection: 'row', marginTop: 16, justifyContent: 'space-between', width: '80%' }}>
        <Pressable style={styles.button} onPress={() => navigation.navigate('Login')}>
          <Text style={styles.textStyleNegar}>Negar</Text>
        </Pressable>

        <Pressable style={[styles.button, styles.buttonOpen]} onPress={() => navigation.navigate('Login')}>
          <Text style={styles.textStyle}>Permitir</Text>
        </Pressable>
      </View>
    </View>
  );
};
