import 'react-native-gesture-handler';
import React from 'react';
import { StatusBar } from 'expo-status-bar';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { useFonts } from 'expo-font';
import { Roboto_400Regular, Roboto_500Medium, Roboto_700Bold } from '@expo-google-fonts/roboto';

import { Routes } from './src/routes';
import { SplashScreen } from './src/screens/SplashScreen';
import { AppProvider } from './src/contexts';
import { ThemeProvider } from 'styled-components/native';
import theme from './src/global/styles/theme';

import { MenuProvider } from 'react-native-popup-menu';

export default function App() {
    const [fontsLoaded] = useFonts({
        Roboto_400Regular,
        Roboto_500Medium,
        Roboto_700Bold,
    });

    if (!fontsLoaded) {
        setTimeout(() => {
            return <SplashScreen />;
        }, 2000);
    }

    if (!__DEV__) {
        const newUrl = location.href.includes('http://') ? location.href.replace('http://', 'https://') : '';

        if (newUrl.length > 0) location.replace(newUrl);

        Sentry.init({
            dsn: 'https://5b3eaa2d89534292b58ae969471715cf@o1002981.ingest.sentry.io/5986637',
            integrations: [new Integrations.BrowserTracing()],
            tracesSampleRate: 1.0,
        });
    }

    return (
        <ThemeProvider theme={theme}>
            <MenuProvider>
                <AppProvider>
                    <StatusBar style="auto" backgroundColor="transparent" translucent />
                    <Routes />
                </AppProvider>
            </MenuProvider>
        </ThemeProvider>
    );
}
