import React from 'react';
import Svg, { Path, SvgProps, Circle } from 'react-native-svg';

const NovidadesBottonTabsIcon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
        <Path d="M11.5555 6.17005C11.5555 6.24986 11.5555 6.95337 11.5555 7.75606C11.5555 8.49244 12.1316 9.02244 12.6965 9.39436C13.8304 10.1408 13.4501 12 11.5555 12H4.44443C2.54994 12 2.1696 10.1408 3.30342 9.39437C3.86835 9.02244 4.44443 8.49244 4.44443 7.75606C4.44442 6.95337 4.44442 6.24986 4.44442 6.17005C4.44442 4.23516 6.03631 2.66663 7.99999 2.66663C9.96367 2.66663 11.5555 4.23516 11.5555 6.17005Z" stroke={props.color}/>
        <Path d="M8 14C9.10457 14 10 13.1046 10 12H6C6 13.1046 6.89543 14 8 14Z" stroke={props.color}/>
        <Path d="M7.33332 2.66663C7.33332 2.29844 7.6318 1.99996 7.99999 1.99996C8.36818 1.99996 8.66666 2.29844 8.66666 2.66663" stroke={props.color}/>
        </Svg>


    );
}

export default NovidadesBottonTabsIcon;