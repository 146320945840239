import { useCallback, useContext, useEffect, useMemo } from 'react';
import { AppContext } from '../contexts';
import { Client, getClients } from '../services/api/Clients';
import { SetClientStorage, GetClientStorage } from '../services/database/clientStorage';
import { DEFAULT_SYNC_DATE } from '../utils/constantes';

export function useClient() {
  const { clientState, clientDispatch, authState } = useContext(AppContext);

  useEffect(() => {
    if (clientState.clients.length) {
      SetClientStorage({
        clients: clientState.clients,
        lastSyncDate: clientState.lastSyncDate,
      });
    }
  }, [clientState]);

  const setClients = useCallback(async () => {
    const stored = await GetClientStorage();

    if (!!stored && stored.clients.length) {
      clientDispatch({
        type: 'setClientState',
        payload: stored,
      });
    }

    const { data } = await getClients({
      lastSyncDate: !!stored ? stored.lastSyncDate : DEFAULT_SYNC_DATE,
      userId: authState.userId,
    });
    if (!!data) {
      clientDispatch({
        type: 'updateClientState',
        payload: {
          ...data,
        },
      });
    }
  }, [clientState]);

  const setSelectedClient = (client: Client) => {
    clientDispatch({
      type: 'setSelectedClient',
      payload: client,
    });
  };

  const selectedClient = useMemo(() => clientState.selectedClient, [clientState]);

  const clients = useMemo(() => clientState.clients, [clientState]);

  const resetClients = useCallback((): void => {
    clientDispatch({
      type: 'resetClientState',
    });
  }, [authState]);

  return { clients, setClients, selectedClient, setSelectedClient, resetClients };
}
