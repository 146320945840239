import React, { createContext, useReducer, Dispatch } from 'react';
import AreaReducer, { AreaActions, AreaState, DEFAULT_AREA_STATE } from './reducers/AreaReducer';
import AuthReducer, { AuthActions, AuthState, DEFAULT_AUTH_STATE } from './reducers/AuthReducer';
import ClientReducer, { ClientActions, ClientState, DEFAULT_CLIENT_STATE } from './reducers/ClientReducer';
import FarmReducer, { DEFAULT_FARM_STATE, FarmActions, FarmState } from './reducers/FarmReducer';
import FertilityFromAreaReducer, {
  DEFAULT_FERTILITIES_STATE,
  FertilitiesActions,
  FertilityState,
} from './reducers/FertilityFromAreaReducer';
import MapReducer, { MapState, MapActions, DEFAULT_MAP_STATE } from './reducers/MapReducer';
import NotesFromAreaReducer, { DEFAULT_NOTES_STATE, NotesActions, NotesState } from './reducers/NotesFromAreaReducer';
import SatelliteFromAreaReducer, {
  DEFAULT_SATELLITE_STATE,
  SatellitesActions,
  SatelliteState,
} from './reducers/SatelliteFromAreaReducer';

export type ContextApp = {
  authState: AuthState;
  authDispatch: Dispatch<AuthActions>;

  clientState: ClientState;
  clientDispatch: Dispatch<ClientActions>;

  farmState: FarmState;
  farmDispatch: Dispatch<FarmActions>;

  areaState: AreaState;
  areaDispatch: Dispatch<AreaActions>;

  fertilityState: FertilityState;
  fertilityDispatch: Dispatch<FertilitiesActions>;

  notesState: NotesState;
  notesDispatch: Dispatch<NotesActions>;

  satelliteState: SatelliteState;
  satelliteDispatch: Dispatch<SatellitesActions>;

  mapState: MapState;
  mapDispatch: Dispatch<MapActions>;
};

const AppContext = createContext<ContextApp>({
  authState: DEFAULT_AUTH_STATE,
  authDispatch: () => null,

  clientState: DEFAULT_CLIENT_STATE,
  clientDispatch: () => null,

  farmState: DEFAULT_FARM_STATE,
  farmDispatch: () => null,

  areaState: DEFAULT_AREA_STATE,
  areaDispatch: () => null,

  fertilityState: DEFAULT_FERTILITIES_STATE,
  fertilityDispatch: () => null,

  notesState: DEFAULT_NOTES_STATE,
  notesDispatch: () => null,

  satelliteState: DEFAULT_SATELLITE_STATE,
  satelliteDispatch: () => null,

  mapState: DEFAULT_MAP_STATE,
  mapDispatch: () => null,
});

const AppProvider: React.FC = ({ children }) => {
  const [authState, authDispatch] = useReducer(AuthReducer, DEFAULT_AUTH_STATE);
  const [clientState, clientDispatch] = useReducer(ClientReducer, DEFAULT_CLIENT_STATE);
  const [farmState, farmDispatch] = useReducer(FarmReducer, DEFAULT_FARM_STATE);
  const [areaState, areaDispatch] = useReducer(AreaReducer, DEFAULT_AREA_STATE);
  const [fertilityState, fertilityDispatch] = useReducer(FertilityFromAreaReducer, DEFAULT_FERTILITIES_STATE);
  const [notesState, notesDispatch] = useReducer(NotesFromAreaReducer, DEFAULT_NOTES_STATE);
  const [satelliteState, satelliteDispatch] = useReducer(SatelliteFromAreaReducer, DEFAULT_SATELLITE_STATE);
  const [mapState, mapDispatch] = useReducer(MapReducer, DEFAULT_MAP_STATE);

  return (
    <AppContext.Provider
      value={{
        authState,
        authDispatch,
        clientState,
        clientDispatch,
        farmState,
        farmDispatch,
        areaState,
        areaDispatch,
        fertilityState,
        fertilityDispatch,
        notesState,
        notesDispatch,
        satelliteState,
        satelliteDispatch,
        mapState,
        mapDispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
