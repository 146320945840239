import React from 'react';
import { TouchableOpacity, TouchableOpacityProps, Text, View } from 'react-native';

import TalhaoListIcon from '../../../assets/Svg/TalhaoListIcon';
import { Area } from '../../services/api/Areas';

import { styles } from './styles';

type Props = TouchableOpacityProps & {
  data: Area;
  handleSelectId: () => void;
};

export function Talhoes({ data, handleSelectId, ...rest }: Props) {
  return (
    <TouchableOpacity style={styles.container} onPress={handleSelectId}>
      <TalhaoListIcon width={24} height={24} />
      <View style={styles.content}>
        <View>
          <Text style={styles.title}>{data.field}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}
