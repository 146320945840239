import React from "react";
import Svg, { Path, SvgProps, Circle, Rect } from "react-native-svg";

const Logo: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 148 64"
      fill="none"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.1773 48.7493C30.1838 48.7591 30.1936 48.7689 30.2001 48.7786C30.2848 48.7591 30.3728 48.7395 30.4575 48.7135C31.0928 48.5473 31.6825 48.2704 32.2169 47.9055C32.067 47.7523 31.9269 47.5894 31.7901 47.4233C31.7835 47.4102 31.7738 47.4005 31.764 47.3907C31.2851 46.8401 30.0372 45.5401 28.5972 45.0938L28.0596 47.117C28.7959 47.3777 29.643 48.1433 30.1773 48.7493Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.7879 48.8539C35.7944 48.8441 35.8074 48.8311 35.8172 48.8213C36.1528 48.4173 37.2182 47.3259 38.1337 47.0783L37.5929 45.0615C35.9638 45.5014 34.5303 47.1141 34.2436 47.4464C34.2338 47.4595 34.2208 47.4725 34.211 47.4855C32.9143 49.0559 31.0051 49.9584 28.972 49.9584C28.4182 49.9584 27.8773 49.8867 27.356 49.7629L26.8152 51.7829C27.5092 51.9589 28.2292 52.0501 28.972 52.0501C31.6111 52.0468 34.0937 50.8837 35.7879 48.8539Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.844 49.7305C38.2738 49.8836 37.6808 49.9618 37.0846 49.9618C36.8044 49.9618 36.5275 49.9422 36.2505 49.9097C35.6478 50.5613 34.9734 51.1217 34.2371 51.5843C35.1428 51.8873 36.1007 52.047 37.0879 52.047C37.8665 52.047 38.642 51.9492 39.3881 51.744L38.844 49.7305Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4054 37.2711C15.4576 37.0887 15.5162 36.9127 15.5814 36.7335C15.5846 36.7205 15.5911 36.7107 15.5944 36.6944C15.728 36.336 16.36 34.4724 16.0603 32.876L14.0338 33.4168C14.1413 34.2965 13.8122 35.4857 13.6428 35.9549C14.138 36.5022 14.7375 36.9486 15.4054 37.2711Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1388 43.9114C16.5132 43.2826 16.0245 42.5495 15.6922 41.7643C14.8255 41.5656 14.0012 41.2561 13.2356 40.8521C13.5712 42.5397 14.3987 44.1264 15.6596 45.3905C15.7182 45.4524 15.7834 45.5078 15.842 45.5665L17.3212 44.0873C17.2626 44.0319 17.1974 43.9733 17.1388 43.9114Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3828 40.8355C16.3925 40.8355 16.4023 40.8387 16.4121 40.842C16.891 40.9234 18.4093 41.3046 19.0804 41.9791C19.1358 42.0312 19.1912 42.0964 19.2466 42.1648L20.729 40.6824C20.6736 40.6172 20.6182 40.5585 20.5596 40.4999C19.3476 39.2912 17.194 38.8578 16.7998 38.7862C16.7868 38.7829 16.7737 38.7829 16.7607 38.7796C14.239 38.3594 12.1668 36.5479 11.4044 34.1206L9.3877 34.6647C10.3553 37.8674 13.0726 40.2718 16.3828 40.8355Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.1238 8.97155C37.3614 8.76629 36.4426 7.94526 35.8757 7.2969C35.8692 7.29038 35.8627 7.27735 35.8529 7.26758C35.765 7.28713 35.6802 7.30668 35.5955 7.32948C34.9602 7.49564 34.3705 7.77258 33.8362 8.14074C33.9861 8.29387 34.1294 8.45351 34.263 8.62293C34.2695 8.63271 34.2793 8.63922 34.2858 8.65226C34.781 9.21916 36.094 10.5843 37.5862 10.9883C37.6351 11.0013 37.684 11.0144 37.7328 11.0241L38.2737 9.00413C38.2248 8.99435 38.1727 8.98458 38.1238 8.97155Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.2687 7.19632C30.259 7.20935 30.2459 7.21913 30.2362 7.23216C29.9266 7.60358 28.8417 8.72436 27.9197 8.97197L28.4605 10.992C30.0928 10.5521 31.5264 8.93939 31.8098 8.60707C31.8196 8.59403 31.8326 8.57774 31.8424 8.56471C33.1424 6.99432 35.0516 6.0951 37.0846 6.0951C37.7427 6.0951 38.3781 6.18632 38.9841 6.36552L39.5217 4.34551C38.743 4.12397 37.9252 4.00342 37.0879 4.00342C34.4456 4.00342 31.9629 5.16655 30.2687 7.19632Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2093 6.31974C27.7795 6.16661 28.3724 6.08842 28.9719 6.09168C29.2521 6.09168 29.529 6.10797 29.806 6.14381C30.4087 5.49219 31.0831 4.93181 31.8195 4.46916C30.9137 4.1629 29.9558 4 28.9719 4C28.1932 4 27.4178 4.10426 26.6685 4.303L27.2093 6.31974Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4676 15.2011C16.2428 15.9016 16.1581 16.6444 16.2134 17.384C16.3926 17.3384 16.5783 17.2993 16.7641 17.2667C16.7771 17.2667 16.7934 17.2635 16.8064 17.2602C16.9107 17.2406 17.1876 17.1885 17.5558 17.0875C18.4322 16.8529 19.6246 16.4164 20.4489 15.654L18.9698 14.1748C18.5658 14.5202 17.8653 14.8427 17.0149 15.0708C16.7543 15.1392 16.5523 15.1815 16.4676 15.2011Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3593 19.2346C13.431 19.4985 13.5157 19.7624 13.6102 20.0166C13.6134 20.0296 13.6167 20.0361 13.6199 20.0459C13.6199 20.0492 13.7014 20.2772 13.7959 20.6193C14.0467 21.5576 14.1119 22.4015 13.9718 22.926C13.949 23.0042 13.9229 23.0824 13.8904 23.1606L15.9136 23.7047C15.9397 23.6232 15.9657 23.5451 15.9853 23.4701C16.2883 22.3396 16.0602 21.0005 15.8126 20.0785C15.7181 19.7266 15.6302 19.4692 15.5943 19.3682C15.5878 19.3552 15.5813 19.3389 15.5748 19.3226C15.4998 19.1206 15.4314 18.9088 15.376 18.6971C14.7635 16.4099 15.3956 13.9533 17.0311 12.2493L15.5552 10.7734C13.4016 12.9987 12.5545 16.2274 13.3593 19.2346Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2643 22.4572C11.4793 21.5156 11.8931 20.6392 12.4633 19.88C12.4242 19.7562 12.3851 19.6292 12.3525 19.5054C12.1603 18.7755 12.0528 18.036 12.0267 17.2964C10.655 18.5051 9.67437 20.1146 9.24756 21.9163L11.2643 22.4572Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.9839 6.36538C39.7789 6.5967 40.5184 6.97138 41.1733 7.46334C41.2971 7.42425 41.4242 7.38841 41.548 7.35583C42.2778 7.16034 43.0174 7.05283 43.757 7.02676C42.6362 5.75612 41.1668 4.82105 39.5215 4.34863L38.9839 6.36538Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.9727 14.0741C46.5915 13.6929 46.2299 12.9403 45.979 12.0117C45.9106 11.7543 45.8682 11.5523 45.8519 11.4644C45.1482 11.2428 44.4086 11.1516 43.6658 11.2102C43.7114 11.3927 43.7505 11.5751 43.7798 11.7576C43.7831 11.7739 43.7863 11.7902 43.7896 11.8032C43.8059 11.9107 43.8613 12.1844 43.9623 12.5526C44.2066 13.4648 44.6693 14.7257 45.4935 15.55C45.5554 15.6119 45.6174 15.6673 45.6825 15.7259L47.1649 14.2435C47.0965 14.1881 47.0314 14.1327 46.9727 14.0741Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.8185 8.35918C41.5579 8.43085 41.294 8.51231 41.0366 8.61005C41.0268 8.61005 41.017 8.61331 41.0073 8.61656C41.004 8.61982 40.7792 8.70127 40.4338 8.7925C39.5737 9.02382 38.8016 9.0955 38.2737 9.00427L37.7329 11.0243C38.8309 11.2719 40.095 11.0471 40.9747 10.8125C41.3266 10.718 41.5839 10.63 41.6882 10.5942C41.7012 10.591 41.7208 10.5844 41.7338 10.5779C41.9391 10.503 42.1476 10.4346 42.3594 10.3759C44.6954 9.75037 47.2041 10.4248 48.9146 12.1353C48.9765 12.1939 49.0319 12.2558 49.0905 12.321L50.5664 10.8386C50.5078 10.7799 50.4491 10.718 50.3905 10.6561C48.1587 8.42434 44.8713 7.54466 41.8185 8.35918Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0377 12.2428C17.0703 12.2069 17.1061 12.1711 17.1387 12.1353C17.7642 11.5097 18.5038 11.021 19.289 10.6887C19.4845 9.82529 19.794 9.001 20.198 8.23535C18.5103 8.57419 16.9236 9.39522 15.6628 10.6594C15.6269 10.6952 15.5911 10.7278 15.5618 10.7669L17.0377 12.2428Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0949 8.63623C24.5508 9.13471 24.1044 9.73094 23.7852 10.4021C23.9643 10.451 24.1435 10.5129 24.3195 10.578C24.3293 10.5813 24.3455 10.5878 24.3586 10.5943C24.7463 10.7344 26.8119 11.4317 28.4605 10.9918C28.4605 10.9918 28.4637 10.9918 28.4637 10.9886L27.9262 8.97181C27.9262 8.97181 27.9229 8.97181 27.9229 8.97507C27.0302 9.21291 25.6194 8.8252 25.0949 8.63623Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2176 11.3796C20.2176 11.3894 20.2143 11.4024 20.2143 11.4089C20.1329 11.8911 19.7484 13.4061 19.0772 14.0773C19.0479 14.1098 19.0088 14.1424 18.9697 14.175L20.4489 15.6542C20.4847 15.6216 20.5206 15.589 20.5531 15.5564C21.7619 14.3444 22.1952 12.1876 22.2669 11.7966C22.2701 11.7836 22.2701 11.7673 22.2734 11.7543C22.7067 9.14129 24.642 7.004 27.2061 6.31655H27.2094L26.6685 4.2998H26.6653C23.3258 5.19903 20.7975 7.97164 20.2176 11.3796Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.323 33.8437C11.1699 33.2736 11.0917 32.6806 11.0949 32.0844C11.0917 31.8009 11.108 31.524 11.1405 31.2503C10.4922 30.6508 9.92855 29.9731 9.4659 29.2368C9.1629 30.1393 9 31.0972 9 32.0844C9 32.863 9.10426 33.6417 9.303 34.3846C9.32906 34.4823 9.35513 34.5735 9.38771 34.668L11.4045 34.1239C11.3751 34.0294 11.3458 33.9382 11.323 33.8437Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2643 22.4569L9.24427 21.916C9.08788 22.5774 9.00317 23.2649 9.00317 23.9653C9.00317 26.6109 10.1663 29.0903 12.1993 30.7877C12.2091 30.7975 12.2221 30.8073 12.2352 30.8138C12.6359 31.1494 13.7306 32.218 13.9782 33.1368C14.001 33.2248 14.0206 33.3225 14.0336 33.4202L16.0601 32.8794C16.0406 32.7849 16.021 32.6904 15.995 32.596C15.5584 30.9669 13.9424 29.5269 13.6101 29.2434C13.597 29.2304 13.5808 29.2173 13.5677 29.2076C11.9973 27.9141 11.0949 26.0049 11.0949 23.9686C11.0949 23.4506 11.1535 22.9456 11.2643 22.4569Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3003 25.1745C12.2938 25.1842 12.2808 25.1907 12.271 25.2005C12.2905 25.2852 12.3101 25.3699 12.3329 25.4579C12.5056 26.0932 12.7793 26.6862 13.1474 27.2173C13.3005 27.0707 13.4602 26.924 13.6296 26.7872C13.6394 26.7807 13.6492 26.7742 13.6622 26.7644C14.1998 26.2952 15.4509 25.0963 15.92 23.7018L13.8968 23.1577C13.6035 23.868 12.8835 24.6662 12.3003 25.1745Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.9584 47.4137C41.4992 46.9153 41.9488 46.3158 42.2681 45.6479C42.0889 45.5925 41.9097 45.5371 41.7338 45.4719C41.724 45.4654 41.7077 45.4622 41.6947 45.4589C41.3167 45.3188 39.2446 44.6151 37.596 45.0614H37.5928L38.1336 47.0782C38.1336 47.0782 38.1336 47.0782 38.1369 47.0749C39.0263 46.8371 40.4338 47.2248 40.9584 47.4137Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.8102 44.0156C48.2075 44.5956 47.507 45.0484 46.7609 45.3645C46.5654 46.2279 46.2591 47.0489 45.8486 47.8178C47.4907 47.4887 49.0383 46.7003 50.2861 45.4915L48.8102 44.0156Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.8356 44.6706C45.8356 44.6608 45.8356 44.651 45.8389 44.6412C45.9236 44.1525 46.269 42.7939 46.8717 42.0804L45.3925 40.6045C44.262 41.8263 43.8547 43.8691 43.7831 44.2503C43.7798 44.2633 43.7765 44.2763 43.7765 44.2959C43.3432 46.9121 41.4079 49.0461 38.8406 49.7336H38.8438L39.3847 51.7503C42.7307 50.8544 45.2557 48.0785 45.8356 44.6706Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0774 44.0384C20.1458 44.2958 20.1849 44.4978 20.2012 44.5858C20.9017 44.8073 21.6478 44.8953 22.3874 44.8367C22.345 44.6575 22.3059 44.475 22.2733 44.2926C22.2701 44.2763 22.2668 44.2632 22.2636 44.2469C22.2473 44.1427 22.1919 43.8657 22.0909 43.4943C21.8628 42.6505 21.449 41.5036 20.729 40.6826L19.2466 42.165C19.5659 42.5821 19.8624 43.2467 20.0774 44.0384Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2381 47.6874C24.4987 47.6157 24.7659 47.5343 25.0233 47.4398C25.033 47.4365 25.0396 47.4333 25.0493 47.4333C25.0526 47.4333 25.2774 47.3486 25.6195 47.2573C26.5611 47.0032 27.4017 46.9413 27.9295 47.0814C27.9718 47.0944 28.0174 47.1107 28.063 47.1238L28.6006 45.1038C28.555 45.094 28.5127 45.0777 28.467 45.0647C27.3365 44.7617 25.9974 44.993 25.0754 45.2406C24.7203 45.3351 24.4596 45.423 24.3619 45.4589C24.3489 45.4621 24.3326 45.4687 24.3163 45.4752C24.1143 45.5501 23.9025 45.6185 23.6907 45.6772C21.4394 46.2799 19.0252 45.6739 17.3212 44.0938L15.842 45.5664C18.0738 47.6711 21.2635 48.4856 24.2381 47.6874Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3527 49.7627C26.4502 49.5412 25.6096 49.1405 24.8798 48.5898C24.7527 48.6289 24.6257 48.6648 24.5051 48.7006C23.7753 48.8928 23.0357 49.0036 22.2961 49.0297C23.4788 50.372 25.0525 51.3429 26.8118 51.7827L27.3527 49.7627Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.0145 19.7594L86.5877 19.4662C84.9163 18.3324 82.9419 17.7329 80.873 17.7329C75.1975 17.7329 70.5808 22.3496 70.5808 28.0251C70.5808 33.7007 75.1975 38.3174 80.873 38.3174C83.0657 38.3174 85.1216 37.6658 86.819 36.4342C87.1937 36.1638 87.2849 35.6327 87.0178 35.258L85.9361 33.6942C85.8058 33.5052 85.607 33.3814 85.3822 33.3423C85.1607 33.3032 84.9326 33.3521 84.7436 33.4824C83.5935 34.2904 82.2903 34.7009 80.873 34.7009C77.1751 34.7009 74.1679 31.7035 74.1679 28.0219C74.1679 24.324 77.1751 21.3168 80.873 21.3168C81.8798 21.3168 82.8865 21.5448 83.7955 21.9847V22.128C83.7955 22.5972 84.18 22.9817 84.6491 22.9817H86.5258C86.9982 22.9817 87.3794 22.5972 87.3794 22.128V20.4632C87.3827 20.1862 87.2458 19.9256 87.0145 19.7594Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.365 34.2674V28.5006C67.365 26.562 66.4755 25.3631 65.7327 24.6984C64.7976 23.8676 63.5303 23.3887 62.2531 23.3887C60.686 23.3887 59.1579 23.9816 57.9296 25.0438V24.7212C57.9296 24.2488 57.5452 23.8676 57.0728 23.8676H54.4793C54.0069 23.8676 53.6257 24.2521 53.6257 24.7212V26.5979C53.6257 27.0116 53.9222 27.357 54.3132 27.4352V36.9878C54.3132 37.457 54.6976 37.8415 55.1668 37.8415H57.0728C57.5452 37.8415 57.9264 37.457 57.9264 36.9878V31.3286C57.9264 28.6048 59.8812 26.9758 61.7742 26.9758C62.706 26.9758 63.7779 27.1485 63.7779 28.5006V36.9878C63.7779 37.457 64.1623 37.8415 64.6315 37.8415H67.2216C67.6941 37.8415 68.0753 37.457 68.0753 36.9878V35.1079C68.0753 34.6876 67.7657 34.3358 67.365 34.2674Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137.329 30.3152C136.325 29.1325 134.677 28.4711 132.425 28.3505C131.559 28.3049 131.073 28.1094 130.897 27.9335C130.865 27.8488 130.813 27.6077 130.901 27.4253C131.041 27.1385 131.562 26.9724 132.331 26.9724C132.911 26.9724 133.413 27.0701 133.826 27.2656V27.3862C133.826 27.8586 134.211 28.243 134.68 28.243H136.586C137.055 28.243 137.44 27.8586 137.44 27.3862V25.907C137.44 25.6529 137.326 25.415 137.13 25.2489L136.84 25.0078C135.693 23.9456 134.133 23.3853 132.331 23.3853C130.357 23.3853 128.584 24.304 127.704 25.7799C126.893 27.1418 127.03 28.9077 128.05 30.1718C128.929 31.2633 130.337 31.866 132.233 31.967C134.774 32.1038 134.774 32.8695 134.774 33.4331C134.774 34.2411 133.882 34.7038 132.331 34.7038C131.301 34.7038 130.461 34.5083 129.881 34.1336V34.0261C129.881 33.5537 129.496 33.1725 129.027 33.1725H127.151C126.678 33.1725 126.294 33.5569 126.294 34.0261V35.2935C126.294 35.502 126.369 35.704 126.512 35.8669L126.737 36.1145C127.434 36.9388 129.086 38.317 132.331 38.317C134.015 38.317 136.286 37.9293 137.56 36.0754C138.358 34.909 138.401 33.671 138.401 33.4331C138.397 32.586 138.212 31.3577 137.329 30.3152Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.006 26.9758C120.586 26.9758 121.152 27.1061 121.674 27.357C120.664 28.1226 119.748 28.569 119.159 28.569H116.868C117.601 27.5851 118.768 26.9758 120.006 26.9758ZM124.029 33.4268C123.87 33.2769 123.664 33.1955 123.446 33.1955C123.212 33.1955 122.984 33.2965 122.824 33.4659C122.068 34.2641 121.068 34.7039 120.006 34.7039C118.377 34.7039 116.927 33.6711 116.37 32.1855H119.159C121.638 32.1855 123.736 30.3218 125.251 29.0121C126.769 27.7024 125.899 26.1841 125.326 25.5976C123.941 24.1804 122.035 23.3887 120.006 23.3887C115.891 23.3887 112.545 26.7347 112.545 30.8529C112.545 34.9711 115.891 38.3171 120.006 38.3171C122.084 38.3171 124.023 37.47 125.466 35.9322C125.623 35.7661 125.704 35.5478 125.697 35.3132C125.688 35.0851 125.59 34.8734 125.424 34.7202L124.029 33.4268Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.0196 26.9758C95.5995 26.9758 96.1664 27.1061 96.6877 27.357C95.6777 28.1226 94.7622 28.569 94.1757 28.569H91.8886C92.6151 27.5851 93.7815 26.9758 95.0196 26.9758ZM99.04 33.4268C98.8836 33.2769 98.6751 33.1955 98.4568 33.1955C98.2222 33.1955 97.9942 33.2965 97.8345 33.4659C97.0787 34.2641 96.0784 34.7039 95.0163 34.7039C93.3873 34.7039 91.9374 33.6711 91.3803 32.1855H94.1725C96.6486 32.1855 98.75 30.3218 100.265 29.0121C101.78 27.7024 100.91 26.1841 100.34 25.5976C98.9521 24.1804 97.0493 23.3887 95.0196 23.3887C90.9046 23.3887 87.5586 26.7347 87.5586 30.8529C87.5586 34.9711 90.9079 38.3171 95.0196 38.3171C97.0982 38.3171 99.0368 37.47 100.48 35.9322C100.636 35.7661 100.718 35.5478 100.711 35.3132C100.702 35.0851 100.604 34.8734 100.438 34.7202L99.04 33.4268Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.2507 18.2246H48.9503C48.4779 18.2246 48.0967 18.6091 48.0967 19.0782V20.9484C48.0967 21.3719 48.4062 21.7238 48.8102 21.7922V34.2934C48.4062 34.3618 48.0967 34.7137 48.0967 35.1372V37.0074C48.0967 37.4798 48.4811 37.861 48.9503 37.861H52.2507C52.7199 37.861 53.1043 37.4765 53.1043 37.0074V35.1372C53.1043 34.7202 52.8046 34.3716 52.4104 34.2966V21.7889C52.8046 21.714 53.1011 21.3654 53.1011 20.9484V19.0782C53.1043 18.6091 52.7231 18.2246 52.2507 18.2246Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.714 24.3239C112.714 23.7798 112.336 23.3921 111.515 23.3921C109.182 23.3921 107.625 23.9883 106.198 24.653C106.198 24.653 106.198 24.3956 106.09 24.2424C105.934 24.0209 105.677 23.871 105.387 23.871H102.793C102.321 23.871 101.936 24.2555 101.936 24.7246V26.6013C101.936 27.0151 102.233 27.3637 102.624 27.4386V36.9913C102.624 37.4604 103.008 37.8449 103.481 37.8449H105.383C105.856 37.8449 106.237 37.4604 106.237 36.9913V34.45V31.1137L106.234 30.6283C106.902 28.605 108.211 27.8263 109.075 27.5624C109.13 27.9892 109.492 28.3606 109.935 28.3639H111.857C112.47 28.3639 112.714 27.986 112.714 27.3083V24.3239Z"
        fill="white"
      />
      <Path
        d="M69.688 49.8606L69.7825 48.9776C70.0985 49.2285 70.6491 49.4859 71.3528 49.4859C71.9393 49.4859 72.4508 49.3067 72.4508 48.7919C72.4508 48.2478 71.8774 48.0458 71.1965 47.8243C70.408 47.5669 69.613 47.316 69.613 46.4494C69.613 45.8108 70.0496 44.98 71.418 44.98C72.0859 44.98 72.731 45.1722 73.0014 45.3384L72.9232 46.1333C72.4215 45.876 71.9686 45.6805 71.4767 45.6805C71.0042 45.6805 70.4243 45.8597 70.4243 46.3484C70.4243 46.7915 70.8967 46.9707 71.4864 47.1499C72.3335 47.4072 73.2914 47.6809 73.2914 48.7789C73.2914 49.323 73.0633 50.1994 71.3431 50.1994C70.5905 50.1994 69.991 50.0332 69.688 49.8606Z"
        fill="white"
      />
      <Path
        d="M73.8975 45.8531V45.0776H74.6273V45.8531H73.8975ZM73.8975 50.0983V46.5633H74.6273V50.0983H73.8975Z"
        fill="white"
      />
      <Path
        d="M75.2952 49.8768L75.3734 49.2089C75.546 49.3523 75.9826 49.5673 76.4713 49.5673C76.9144 49.5673 77.1164 49.3881 77.1164 49.1438C77.1164 48.7984 76.745 48.6713 76.429 48.5834C75.9403 48.4465 75.3538 48.2739 75.3538 47.5734C75.3538 47.0488 75.6894 46.4917 76.6733 46.4917C77.1392 46.4917 77.4748 46.622 77.6833 46.7361L77.6051 47.3811C77.4031 47.2085 77.0969 47.0456 76.7027 47.0456C76.3312 47.0456 76.051 47.1824 76.051 47.4984C76.051 47.8275 76.3508 47.9057 76.8688 48.0588C77.263 48.1729 77.8658 48.3879 77.8658 49.1275C77.8658 49.5803 77.6279 50.1733 76.5039 50.1733C75.8947 50.1701 75.4744 49.9974 75.2952 49.8768Z"
        fill="white"
      />
      <Path
        d="M80.2899 50.1701H79.6871C79.0192 50.1701 78.7553 49.7336 78.7553 49.297V47.1467H78.1819L78.247 46.5667H78.7553V45.7196L79.4851 45.6479V46.5667H80.3583V47.1467H79.4851V48.9158C79.4851 49.5381 79.5275 49.5837 80.153 49.5837H80.3452L80.2899 50.1701Z"
        fill="white"
      />
      <Path
        d="M81.479 48.3977C81.5735 49.2643 82.1111 49.538 82.7106 49.538C83.0396 49.538 83.4404 49.4533 83.7499 49.3002L83.8216 49.8964C83.5479 50.0267 83.1471 50.1701 82.6389 50.1701C81.3845 50.1701 80.7231 49.323 80.7231 48.313C80.7231 47.3225 81.3552 46.4917 82.4434 46.4917C83.3329 46.4917 83.978 47.0586 83.978 48.0034C83.978 48.1175 83.9714 48.2543 83.9421 48.3977H81.479ZM81.5214 47.8959H83.2351C83.2351 47.4593 82.9973 47.1205 82.4467 47.1205C81.9156 47.1238 81.6387 47.4235 81.5214 47.8959Z"
        fill="white"
      />
      <Path
        d="M89.3374 50.0984V48.0849C89.3374 47.4268 89.1158 47.1238 88.562 47.1238C87.8224 47.1238 87.7084 47.619 87.7084 47.9969V50.0984H86.9753V48.1631C86.9753 47.3323 86.6821 47.1238 86.1933 47.1238C85.8284 47.1238 85.5254 47.2541 85.356 47.5245V50.0984H84.6262V46.5634H85.2062L85.2713 46.899C85.6069 46.6057 85.9034 46.4917 86.2748 46.4917C86.6918 46.4917 87.1349 46.6285 87.4347 47.1433C87.6269 46.7784 88.0374 46.4917 88.6825 46.4917C89.6013 46.4917 90.0574 47.0521 90.0574 48.0751V50.0984H89.3374Z"
        fill="white"
      />
      <Path
        d="M93.788 50.1701H93.6447C93.094 50.1701 92.9148 49.9909 92.7715 49.6911C92.5206 49.9843 92.1329 50.1701 91.6898 50.1701C91.0675 50.1701 90.6277 49.753 90.6277 49.1307C90.6277 48.1338 91.8755 47.8829 92.6933 47.8699C92.6868 47.2899 92.4555 47.1368 91.97 47.1368C91.6833 47.1368 91.4031 47.202 91.0252 47.43L90.9405 46.8436C91.2695 46.6285 91.7159 46.4917 92.1753 46.4917C93.0354 46.4917 93.4296 46.9087 93.4296 48.0979V48.8994C93.4296 49.4077 93.472 49.5803 93.801 49.5803H93.8858L93.788 50.1701ZM92.6966 48.3618C91.8495 48.3977 91.377 48.6062 91.377 49.0916C91.377 49.4435 91.5693 49.5934 91.9016 49.5934C92.1818 49.5934 92.4978 49.4435 92.6966 49.1926V48.3618Z"
        fill="white"
      />
      <Path
        d="M94.1821 49.8768L94.2603 49.2089C94.433 49.3523 94.8696 49.5673 95.3583 49.5673C95.8014 49.5673 96.0034 49.3881 96.0034 49.1438C96.0034 48.7984 95.632 48.6713 95.3159 48.5834C94.8272 48.4465 94.2408 48.2739 94.2408 47.5734C94.2408 47.0488 94.5763 46.4917 95.5603 46.4917C96.0262 46.4917 96.3618 46.622 96.5703 46.7361L96.4921 47.3811C96.2901 47.2085 95.9838 47.0456 95.5896 47.0456C95.2182 47.0456 94.938 47.1824 94.938 47.4984C94.938 47.8275 95.2377 47.9057 95.7558 48.0588C96.15 48.1729 96.7527 48.3879 96.7527 49.1275C96.7527 49.5803 96.5149 50.1733 95.3909 50.1733C94.7816 50.1701 94.3613 49.9974 94.1821 49.8768Z"
        fill="white"
      />
      <Path
        d="M99.7143 49.7465V51.8317H98.991V46.5634H99.5872L99.6589 46.9576C99.9391 46.6644 100.226 46.4917 100.734 46.4917C101.773 46.4917 102.363 47.3583 102.363 48.3325C102.363 49.349 101.812 50.1668 100.728 50.1668C100.395 50.1701 99.9716 50.0267 99.7143 49.7465ZM99.6784 48.3358C99.6784 49.1372 100.122 49.5412 100.659 49.5412C101.262 49.5412 101.614 49.0167 101.614 48.3358C101.614 47.632 101.262 47.1238 100.659 47.1238C100.122 47.1238 99.6784 47.5473 99.6784 48.3358Z"
        fill="white"
      />
      <Path
        d="M105.914 50.1701H105.771C105.22 50.1701 105.038 49.9909 104.898 49.6911C104.647 49.9843 104.259 50.1701 103.816 50.1701C103.191 50.1701 102.754 49.753 102.754 49.1307C102.754 48.1338 104.002 47.8829 104.82 47.8699C104.813 47.2899 104.582 47.1368 104.096 47.1368C103.81 47.1368 103.53 47.202 103.152 47.43L103.067 46.8436C103.396 46.6285 103.842 46.4917 104.298 46.4917C105.159 46.4917 105.553 46.9087 105.553 48.0979V48.8994C105.553 49.4077 105.595 49.5803 105.924 49.5803H106.009L105.914 50.1701ZM104.826 48.3618C103.979 48.3977 103.507 48.6062 103.507 49.0916C103.507 49.4435 103.699 49.5934 104.031 49.5934C104.312 49.5934 104.628 49.4435 104.826 49.1926V48.3618Z"
        fill="white"
      />
      <Path
        d="M108.273 47.2087H108.016C107.723 47.2087 107.377 47.4172 107.263 47.6322V50.0986H106.504V46.5636H107.143L107.201 47.0718C107.547 46.6906 107.889 46.4561 108.312 46.4561H108.426L108.273 47.2087Z"
        fill="white"
      />
      <Path
        d="M111.714 50.1701H111.57C111.02 50.1701 110.837 49.9909 110.697 49.6911C110.446 49.9843 110.059 50.1701 109.616 50.1701C108.99 50.1701 108.553 49.753 108.553 49.1307C108.553 48.1338 109.801 47.8829 110.619 47.8699C110.613 47.2899 110.381 47.1368 109.896 47.1368C109.609 47.1368 109.329 47.202 108.951 47.43L108.866 46.8436C109.195 46.6285 109.642 46.4917 110.098 46.4917C110.958 46.4917 111.352 46.9087 111.352 48.0979V48.8994C111.352 49.4077 111.395 49.5803 111.724 49.5803H111.808L111.714 50.1701ZM110.622 48.3618C109.775 48.3977 109.303 48.6062 109.303 49.0916C109.303 49.4435 109.495 49.5934 109.827 49.5934C110.108 49.5934 110.424 49.4435 110.622 49.1926V48.3618Z"
        fill="white"
      />
      <Path
        d="M72.9232 58.6995L72.4638 57.3963H70.6295L70.1636 58.6995H69.3687L71.1606 53.6821H71.9262L73.7247 58.6995H72.9232ZM71.545 54.8062L70.8771 56.6828H72.2097L71.545 54.8062Z"
        fill="white"
      />
      <Path
        d="M73.8975 59.4294C73.8975 59.1851 74.018 58.8332 74.5067 58.6345C74.2428 58.4976 74.1614 58.3184 74.1614 58.1685C74.1614 58.0122 74.2558 57.8036 74.5556 57.6603C74.1027 57.3312 73.9691 56.9077 73.9691 56.5069C73.9691 55.7413 74.4774 55.0962 75.4092 55.0962C75.7383 55.0962 76.0185 55.1744 76.2759 55.3405C76.4681 55.2395 76.7548 55.1548 77.1001 55.1548H77.2142L77.1425 55.6273H76.9275C76.8786 55.6273 76.7124 55.6403 76.5821 55.6631C76.8037 55.9433 76.8688 56.2235 76.8688 56.4874C76.8688 57.2172 76.3671 57.8916 75.4418 57.8916C75.2854 57.8916 75.1192 57.8688 74.9759 57.833C74.8977 57.8753 74.839 57.9405 74.839 58.0122C74.839 58.1914 75.1127 58.2402 75.8653 58.3575C76.4029 58.4422 77.2989 58.6214 77.2989 59.374C77.2989 59.9833 76.6961 60.4915 75.5493 60.4915C74.3471 60.4915 73.8975 59.9181 73.8975 59.4294ZM75.0932 58.8136C74.7934 58.9146 74.5556 59.1362 74.5556 59.3936C74.5556 59.7096 74.914 59.9442 75.5591 59.9442C76.2172 59.9442 76.5984 59.6933 76.5984 59.4066C76.5984 59.1264 76.2335 58.9831 75.6601 58.8918C75.6014 58.8918 75.2007 58.8365 75.0932 58.8136ZM74.6501 56.4906C74.6501 57.0152 74.9498 57.3377 75.4255 57.3377C75.9272 57.3377 76.1911 56.9728 76.1911 56.4776C76.1911 55.9628 75.8914 55.6598 75.4157 55.6598C74.914 55.6598 74.6501 56.0019 74.6501 56.4906Z"
        fill="white"
      />
      <Path
        d="M79.4884 55.8097H79.231C78.9378 55.8097 78.5924 56.0183 78.4784 56.2333V58.6996H77.7192V55.1646H78.3578L78.4165 55.6729C78.7618 55.2917 79.1039 55.0571 79.5275 55.0571H79.6415L79.4884 55.8097Z"
        fill="white"
      />
      <Path
        d="M80.0681 54.4546V53.6792H80.7979V54.4546H80.0681ZM80.0681 58.6999V55.1649H80.7979V58.6999H80.0681Z"
        fill="white"
      />
      <Path
        d="M81.4497 56.9271C81.4497 55.9105 82.0296 55.0928 83.1928 55.0928C83.7369 55.0928 84.0594 55.2003 84.2744 55.3208L84.18 55.9659C83.8933 55.8095 83.6 55.7216 83.2612 55.7216C82.5509 55.7216 82.1925 56.181 82.1925 56.9271C82.1925 57.6569 82.5509 58.1456 83.2612 58.1456C83.5968 58.1456 83.8998 58.0609 84.18 57.9012L84.2647 58.504C84.0496 58.6408 83.7629 58.7679 83.196 58.7679C82.0394 58.7711 81.4497 57.9175 81.4497 56.9271Z"
        fill="white"
      />
      <Path
        d="M87.2 58.6996L87.1511 58.3705C86.9361 58.6279 86.6363 58.7712 86.2421 58.7712C85.3168 58.7712 84.8672 58.2043 84.8672 57.165V55.1646H85.597V57.051C85.597 57.7677 85.8185 58.1392 86.3789 58.1392C86.6884 58.1392 86.9654 58.0186 87.1381 57.7743V55.1646H87.8711V58.6996H87.2Z"
        fill="white"
      />
      <Path
        d="M89.8716 58.7712H89.6273C88.9594 58.7712 88.7183 58.3477 88.7183 57.8981V53.3237H89.4416V57.5234C89.4416 58.1098 89.5197 58.1815 89.9661 58.1815L89.8716 58.7712Z"
        fill="white"
      />
      <Path
        d="M92.2469 58.7712H91.6441C90.9762 58.7712 90.7123 58.3346 90.7123 57.8981V55.7477H90.1389L90.2041 55.1678H90.7123V54.3207L91.4421 54.249V55.1678H92.3153V55.7477H91.4421V57.5201C91.4421 58.1424 91.4845 58.188 92.11 58.188H92.3023L92.2469 58.7712Z"
        fill="white"
      />
      <Path
        d="M95.1921 58.6996L95.1433 58.3705C94.9282 58.6279 94.6285 58.7712 94.2343 58.7712C93.309 58.7712 92.8594 58.2043 92.8594 57.165V55.1646H93.5924V57.051C93.5924 57.7677 93.814 58.1392 94.3744 58.1392C94.6839 58.1392 94.9608 58.0186 95.1335 57.7743V55.1646H95.8633V58.6996H95.1921Z"
        fill="white"
      />
      <Path
        d="M98.4894 55.8097H98.232C97.9387 55.8097 97.5934 56.0183 97.4793 56.2333V58.6996H96.7202V55.1646H97.3588L97.4174 55.6729C97.7628 55.2917 98.1049 55.0571 98.5284 55.0571H98.6425L98.4894 55.8097Z"
        fill="white"
      />
      <Path
        d="M101.93 58.7713H101.787C101.236 58.7713 101.053 58.5921 100.913 58.2924C100.662 58.5856 100.275 58.7713 99.8317 58.7713C99.2061 58.7713 98.7695 58.3543 98.7695 57.732C98.7695 56.735 100.017 56.4841 100.835 56.4711C100.829 55.8912 100.597 55.7413 100.112 55.7413C99.8251 55.7413 99.545 55.8065 99.167 56.0345L99.0823 55.4481C99.4114 55.233 99.8577 55.0962 100.314 55.0962C101.174 55.0962 101.568 55.5132 101.568 56.7024V57.5039C101.568 58.0122 101.611 58.1848 101.94 58.1848H102.024L101.93 58.7713ZM100.838 56.9631C99.9913 56.9989 99.5189 57.2074 99.5189 57.6929C99.5189 58.0447 99.7111 58.1946 100.043 58.1946C100.324 58.1946 100.64 58.0447 100.838 57.7939V56.9631Z"
        fill="white"
      />
      <Path
        d="M106.631 58.6999L106.572 58.3415C106.315 58.6217 105.914 58.7715 105.569 58.7715C104.588 58.7715 103.92 57.9538 103.92 56.9373C103.92 55.9631 104.53 55.0932 105.569 55.0932C105.905 55.0932 106.286 55.1779 106.566 55.4646V53.3208H107.289V58.6966H106.631V58.6999ZM104.667 56.9373C104.667 57.6182 105.025 58.1427 105.621 58.1427C106.159 58.1427 106.625 57.755 106.625 56.9535C106.625 56.1651 106.159 55.7285 105.621 55.7285C105.025 55.7253 104.667 56.2335 104.667 56.9373Z"
        fill="white"
      />
      <Path
        d="M108.694 56.9987C108.788 57.8654 109.326 58.1391 109.925 58.1391C110.254 58.1391 110.658 58.0544 110.964 57.9012L111.036 58.4975C110.762 58.6278 110.362 58.7711 109.853 58.7711C108.599 58.7711 107.938 57.924 107.938 56.914C107.938 55.9236 108.57 55.0928 109.658 55.0928C110.547 55.0928 111.193 55.6597 111.193 56.6045C111.193 56.7185 111.186 56.8554 111.157 56.9987H108.694ZM108.739 56.497H110.453C110.453 56.0604 110.215 55.7216 109.665 55.7216C109.133 55.7248 108.853 56.0246 108.739 56.497Z"
        fill="white"
      />
      <Path
        d="M114.379 56.9793V58.6995H113.59V53.6821H115.138C116.226 53.6821 117.024 53.8972 117.024 55.2525C117.024 56.6079 116.086 56.9793 115.405 56.9793H114.379ZM114.379 54.3468V56.2821H115.275C115.962 56.2821 116.236 55.953 116.236 55.2721C116.236 54.6042 115.978 54.3468 115.138 54.3468H114.379Z"
        fill="white"
      />
      <Path
        d="M119.331 55.8097H119.074C118.781 55.8097 118.435 56.0183 118.321 56.2333V58.6996H117.562V55.1646H118.201L118.259 55.6729C118.605 55.2917 118.947 55.0571 119.37 55.0571H119.484L119.331 55.8097Z"
        fill="white"
      />
      <Path
        d="M120.377 56.9987C120.471 57.8654 121.009 58.1391 121.609 58.1391C121.938 58.1391 122.342 58.0544 122.648 57.9012L122.72 58.4975C122.446 58.6278 122.045 58.7711 121.537 58.7711C120.282 58.7711 119.621 57.924 119.621 56.914C119.621 55.9236 120.253 55.0928 121.341 55.0928C122.231 55.0928 122.876 55.6597 122.876 56.6045C122.876 56.7185 122.869 56.8554 122.84 56.9987H120.377ZM120.423 56.497H122.136C122.136 56.0604 121.898 55.7216 121.348 55.7216C120.817 55.7248 120.537 56.0246 120.423 56.497Z"
        fill="white"
      />
      <Path
        d="M123.316 56.9271C123.316 55.9105 123.896 55.0928 125.059 55.0928C125.603 55.0928 125.925 55.2003 126.14 55.3208L126.046 55.9659C125.759 55.8095 125.466 55.7216 125.127 55.7216C124.417 55.7216 124.059 56.181 124.059 56.9271C124.059 57.6569 124.417 58.1456 125.127 58.1456C125.463 58.1456 125.766 58.0609 126.046 57.9012L126.131 58.504C125.916 58.6408 125.629 58.7679 125.062 58.7679C123.905 58.7711 123.316 57.9175 123.316 56.9271Z"
        fill="white"
      />
      <Path
        d="M126.766 54.4546V53.6792H127.496V54.4546H126.766ZM126.766 58.6999V55.1649H127.496V58.6999H126.766Z"
        fill="white"
      />
      <Path
        d="M128.164 58.4779L128.242 57.81C128.415 57.9534 128.851 58.1684 129.34 58.1684C129.783 58.1684 129.985 57.9892 129.985 57.7448C129.985 57.3995 129.614 57.2724 129.298 57.1845C128.809 57.0476 128.222 56.8749 128.222 56.1744C128.222 55.6499 128.558 55.0928 129.542 55.0928C130.008 55.0928 130.343 55.2231 130.552 55.3371L130.474 55.9822C130.272 55.8095 129.966 55.6466 129.571 55.6466C129.2 55.6466 128.92 55.7835 128.92 56.0995C128.92 56.4286 129.219 56.5068 129.737 56.6599C130.132 56.7739 130.734 56.989 130.734 57.7285C130.734 58.1814 130.497 58.7744 129.373 58.7744C128.763 58.7711 128.343 58.5985 128.164 58.4779Z"
        fill="white"
      />
      <Path
        d="M134.263 58.7715H134.12C133.569 58.7715 133.39 58.5923 133.246 58.2925C132.995 58.5857 132.608 58.7715 132.165 58.7715C131.542 58.7715 131.103 58.3544 131.103 57.7321C131.103 56.7352 132.35 56.4843 133.168 56.4713C133.162 55.8913 132.93 55.7414 132.445 55.7414C132.158 55.7414 131.878 55.8066 131.5 56.0347L131.415 55.4482C131.744 55.2332 132.191 55.0964 132.647 55.0964C133.507 55.0964 133.901 55.5134 133.901 56.7026V57.5041C133.901 58.0123 133.944 58.185 134.273 58.185H134.357L134.263 58.7715ZM132.207 54.0603C131.956 54.0603 131.813 54.2167 131.754 54.4056L131.373 54.3209C131.467 53.842 131.868 53.64 132.168 53.64C132.513 53.64 132.855 53.9756 133.113 53.9756C133.341 53.9756 133.494 53.8192 133.579 53.6172L133.931 53.7117C133.836 54.1059 133.536 54.4056 133.136 54.4056C132.829 54.4056 132.448 54.0603 132.207 54.0603ZM133.171 56.9632C132.324 56.9991 131.852 57.2076 131.852 57.693C131.852 58.0449 132.044 58.1948 132.376 58.1948C132.657 58.1948 132.973 58.0449 133.171 57.794V56.9632Z"
        fill="white"
      />
      <Path
        d="M134.641 56.9368C134.641 55.9203 135.286 55.0928 136.403 55.0928C137.521 55.0928 138.173 55.9171 138.173 56.9368C138.173 57.9468 137.521 58.7711 136.403 58.7711C135.286 58.7711 134.641 57.9468 134.641 56.9368ZM135.387 56.9368C135.387 57.6829 135.752 58.1423 136.403 58.1423C137.062 58.1423 137.43 57.6829 137.43 56.9368C137.43 56.1842 137.065 55.7248 136.403 55.7248C135.752 55.7248 135.387 56.1842 135.387 56.9368Z"
        fill="white"
      />
      <Path
        d="M65.7947 41.1387V58.6671"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </Svg>
  );
};

export default Logo;
