import React from "react";
import Svg, { Path, SvgProps, Circle, Rect } from "react-native-svg";

const ProdutividadeIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <Rect width="24" height="24" rx="2" fill="#FADFCC" />
      <Path d="M8 3L8 13" stroke="#E66100" strokeLinecap="round" />
      <Path d="M4 7L4 13" stroke="#E66100" strokeLinecap="round" />
      <Path d="M12 7L12 13" stroke="#E66100" strokeLinecap="round" />
      <Path d="M14 13H12" stroke="#E66100" strokeLinecap="round" />
      <Path d="M4 13H2" stroke="#E66100" strokeLinecap="round" />
      <Path d="M8 2L6 4" stroke="#E66100" strokeLinecap="round" />
      <Path d="M4 7L2 9" stroke="#E66100" strokeLinecap="round" />
      <Path d="M12 7L10 9" stroke="#E66100" strokeLinecap="round" />
      <Path d="M8 2L10 4" stroke="#E66100" strokeLinecap="round" />
      <Path d="M4 7L6 9" stroke="#E66100" strokeLinecap="round" />
      <Path d="M12 7L14 9" stroke="#E66100" strokeLinecap="round" />
    </Svg>
  );
};

export default ProdutividadeIcon;
