import { useRoute } from '@react-navigation/core';
import { Div, Image, Text, Icon } from 'react-native-magnus';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/native';
import { View, ScrollView } from 'react-native';
import { VersionResponse } from '../NotasList';
import { styles } from '../style';

const ButtonPlayAudio = styled.TouchableOpacity`
  width: 80px;
  height: 40px;
  background: grey;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const CDNBUCKET = 'https://d2b6xd5a1ty0fm.cloudfront.net';

type RouteParams = {
  params: VersionResponse;
};

enum ICON_PLAY_SOUND {
  PLAYING = 'playcircleo',
  PAUSE = 'pausecircleo',
}

export const NotasDetails = () => {
  const route = useRoute();
  const [sound, setSound] = useState<HTMLAudioElement>();
  const [iconPlay, setIconPlay] = useState(ICON_PLAY_SOUND.PLAYING);
  const [isPlaying, setIsPlaying] = useState(false);
  const { params } = route.params as RouteParams;

  const { details } = params;

  useEffect(() => {
    if (params.audioFilename) {
      const audio = new Audio(`${CDNBUCKET}/${params.audioFilename}`);
      setSound(audio);
    }
  }, [params.audioFilename]);

  useEffect(() => {
    sound?.addEventListener('ended', () => {
      setIsPlaying(false);
      setIconPlay(ICON_PLAY_SOUND.PLAYING);
    });
  }, [sound]);

  const streamAudio = () => {
    if (!isPlaying) {
      sound?.play();
      setIsPlaying(true);
      setIconPlay(ICON_PLAY_SOUND.PAUSE);
    } else {
      sound?.pause();
      setIsPlaying(false);
      setIconPlay(ICON_PLAY_SOUND.PLAYING);
    }
  };

  return (
    <>
      <ScrollView style={{ height: '100%', paddingVertical: 16 }}>
        <View style={styles.subcontainer}>
          <Text style={styles.content__heading2}>{`Detalhes da nota ${params.description}`}</Text>
        </View>
        <Div mt={6} flex={1} ml={18}>
          {details.map((item) => (
            <Div mt={24}>
              <Div flexDir="row">
                {item.causes.map((cause) => (
                  <Text>{`${cause.cause.category.name + ' ' + `(${cause.cause.name})`}`}</Text>
                ))}
              </Div>
              {item.filename && (
                <Div>
                  <Image
                    w={180}
                    h={180}
                    borderRadius={4}
                    source={{
                      uri: `${CDNBUCKET}/${item.filename}`,
                    }}
                  />
                </Div>
              )}
            </Div>
          ))}
          {sound ? (
            <Div mt={6}>
              <ButtonPlayAudio onPress={streamAudio}>
                <Icon name={iconPlay} fontFamily="AntDesign" fontSize={26} color="white" />
              </ButtonPlayAudio>
            </Div>
          ) : null}
          {params?.videoFilename ? (
            <Div mt={6} w="100%">
              <video style={{ height: 160, width: 320 }} controls={true} autoPlay={true}>
                <source src={`${CDNBUCKET}/${params.videoFilename}`} type="video/mp4" />
              </video>
            </Div>
          ) : null}
        </Div>
      </ScrollView>
    </>
  );
};
