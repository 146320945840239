import React from "react";
import Svg, { Path, SvgProps, Circle, Rect } from "react-native-svg";

const PermanentesIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <Rect width="24" height="24" rx="4" fill="#DDF0FB" />
      <Path
        d="M9 14V11"
        stroke="#56B4E9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path d="M6 14H8" stroke="#56B4E9" strokeLinejoin="round" />
      <Path d="M10 14H14" stroke="#56B4E9" strokeLinejoin="round" />
      <Path d="M16 14H18" stroke="#56B4E9" strokeLinejoin="round" />
      <Path
        d="M10.5487 10.3333C10.1947 10.8667 9.36873 11 9 11C9 10.4444 9.21248 9.67987 9.66372 9C10.1062 8.33333 10.7699 8 10.9911 8C10.9911 8.55556 11.1007 9.50153 10.5487 10.3333Z"
        stroke="#56B4E9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.677 10.3333C7.20797 10.8667 8.4469 11 9 11C9 10.4444 8.68129 9.67987 8.00443 9C7.34071 8.33333 6.34514 8 6.01328 8C6.01328 8.55556 5.84888 9.50153 6.677 10.3333Z"
        stroke="#56B4E9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M15 14V11"
        stroke="#56B4E9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13.4513 10.3333C13.8053 10.8667 14.6313 11 15 11C15 10.4444 14.7875 9.67987 14.3363 9C13.8938 8.33333 13.2301 8 13.0089 8C13.0089 8.55556 12.8993 9.50153 13.4513 10.3333Z"
        stroke="#56B4E9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M17.323 10.3333C16.792 10.8667 15.5531 11 15 11C15 10.4444 15.3187 9.67987 15.9956 9C16.6593 8.33333 17.6549 8 17.9867 8C17.9867 8.55556 18.1511 9.50153 17.323 10.3333Z"
        stroke="#56B4E9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Circle cx="14.5" cy="15.5" r="0.5" fill="#56B4E9" />
      <Circle cx="11.5" cy="15.5" r="0.5" fill="#56B4E9" />
      <Circle cx="9.5" cy="16.5" r="0.5" fill="#56B4E9" />
      <Circle cx="7.5" cy="15.5" r="0.5" fill="#56B4E9" />
      <Circle cx="16.5" cy="16.5" r="0.5" fill="#56B4E9" />
    </Svg>
  );
};

export default PermanentesIcon;
