import { Area, AreaResponse } from '../../services/api/Areas';
import { DEFAULT_SYNC_DATE } from '../../utils/constantes';

export type AreaState = AreaResponse & {
  farmId?: number;
  lastSyncDate: string;
  selectedArea?: Area;
  yearSafra?: string;
  areasGroup?: any;
};

export type AreaPayload = Omit<AreaState, 'lastSyncDate'>;

export interface ResetAreaState {
  type: 'resetAreaState';
}

export interface SetAreaState {
  type: 'setAreaState';
  payload: AreaState;
}

export interface SetAreasGroup {
  type: 'setAreasGroup';
  payload: any;
}

export interface UpdateAreaState {
  type: 'updateAreaState';
  payload: AreaPayload;
}

export interface SetSelectedArea {
  type: 'setSelectedArea';
  payload: Area | undefined;
}

export interface SetSelectedYearSafra {
  type: 'setSelectedYearSafra';
  payload: string | undefined;
}

export type AreaActions =
  | SetAreaState
  | ResetAreaState
  | UpdateAreaState
  | SetSelectedArea
  | SetSelectedYearSafra
  | SetAreasGroup;

export const DEFAULT_AREA_STATE: AreaState = {
  areas: [],
  lastSyncDate: DEFAULT_SYNC_DATE,
  farmId: undefined,
  selectedArea: undefined,
};

const AreaReducer = (state: AreaState, action: AreaActions): AreaState => {
  switch (action.type) {
    case 'resetAreaState': {
      return DEFAULT_AREA_STATE;
    }

    case 'setAreaState': {
      return {
        ...state,
        farmId: action.payload.farmId,
        areas: action.payload.areas,
      };
    }

    case 'updateAreaState': {
      return {
        ...state,
        farmId: action.payload.farmId,
        areas: state.areas.concat(...action.payload.areas),
        lastSyncDate: new Date().toISOString(),
      };
    }

    case 'setSelectedArea': {
      return {
        ...state,
        selectedArea: action.payload,
      };
    }

    case 'setSelectedYearSafra': {
      return {
        ...state,
        yearSafra: action.payload,
      };
    }

    case 'setAreasGroup': {
      return {
        ...state,
        areasGroup: action.payload,
      };
    }

    default:
      return state;
  }
};

export default AreaReducer;
