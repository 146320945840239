import React from "react";
import Svg, { Path, SvgProps, Circle, Rect } from "react-native-svg";

const RecomendacoesIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <Rect width="24" height="24" rx="4" fill="#CCE3F0" />
      <Path
        d="M12 6C11.1667 6 9 6 8 6.5C7.5 6 6.99998 6 6 6C5.99999 7 6 7.5 7 8C6.5 10 6.49999 14.5 7 16C6 16.5 6 17 6 18C7.5 18 7.5 18 8 17.5C9 18 10.5 18 12 18"
        stroke="#0072B2"
        strokeLinecap="round"
      />
      <Path
        d="M12 6C12.8333 6 15 6 16 6.5C16.5 6 17 6 18 6C18 7 18 7.5 17 8C17.5 10 17.5 14.5 17 16C18 16.5 18 17 18 18C16.5 18 16.5 18 16 17.5C15 18 13.5 18 12 18"
        stroke="#0072B2"
        strokeLinecap="round"
      />
      <Path
        d="M12 14V12"
        stroke="#0072B2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Circle cx="13.5" cy="14.5" r="0.5" fill="#0072B2" />
      <Circle cx="10.5" cy="13.5" r="0.5" fill="#0072B2" />
      <Circle cx="11.5" cy="15.5" r="0.5" fill="#0072B2" />
      <Path
        d="M14.323 11.3333C13.792 11.8667 12.5531 12 12 12C12 11.4444 12.3187 10.6799 12.9956 10C13.6593 9.33333 14.6549 9 14.9867 9C14.9867 9.55556 15.1511 10.5015 14.323 11.3333Z"
        stroke="#0072B2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M9.677 11.3333C10.208 11.8667 11.4469 12 12 12C12 11.4444 11.6813 10.6799 11.0044 10C10.3407 9.33333 9.34514 9 9.01328 9C9.01328 9.55556 8.84888 10.5015 9.677 11.3333Z"
        stroke="#0072B2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default RecomendacoesIcon;
