import { StyleSheet } from 'react-native';
import theme from '../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    width: '90%',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 16,
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: 8,
  },
  title: {
    fontFamily: theme.fonts.text400,
    color: theme.colors.grey6,
    fontSize: 12,
    marginBottom: 4,
  },
  type: {
    fontFamily: theme.fonts.text400,
    color: theme.colors.grey6,
    fontSize: 8,
  },
  item: {
    backgroundColor: '#D3D3D3',
  },
});
