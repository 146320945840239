import React, { useState } from 'react';
import { View, Text, TouchableOpacity, ActivityIndicator } from 'react-native';
import { Search } from '../Search';
import UserIcon from '../../../assets/Svg/UserIcon';
import FazendaIcon from '../../../assets/Svg/FazendaIcon';
import TalhaoIcon from '../../../assets/Svg/TalhaoIcon';
import { Entypo } from '@expo/vector-icons';
import { ListDivider } from '../ListDivider';

import { useClient } from '../../hooks/Client';

import { styles } from './style';
import { useNavigation } from '@react-navigation/native';
import { useFarms } from '../../hooks/Farm';
import { useAreas } from '../../hooks/Area';
import { useNotesFromArea } from '../../hooks/Anomalies';
import { AlertMessage } from '../../utils/AlertMessage';

export const NotasScreen = () => {
  const isNotasScreenDisabled = true;
  const { selectedClient } = useClient();
  const { selectedFarm } = useFarms();
  const { selectedArea } = useAreas();
  const { setNotesByArea, loading } = useNotesFromArea();
  const navigation = useNavigation<any>();

  const [searchQuery, setSearchQuery] = useState('');

  const handleSelect = async () => {
    if (!selectedClient || !selectedFarm || !selectedArea) {
      AlertMessage('Para visualizar as notas é necessário selecionar os campos Agricultor, Fazenda e Talhão!');
      return;
    }
    await setNotesByArea();
    return navigation.navigate('NotasList');
  };

  const handleGoToCreateNote = () => {
    if (!selectedClient || !selectedFarm || !selectedArea) {
      AlertMessage('Para criar uma nota é necessário selecionar os campos Agricultor, Fazenda e Talhão!');
      return;
    }
    return navigation.navigate('Map', {
      openModalReport: true,
    });
  };

  if (isNotasScreenDisabled)
    return (
      <View>
        <Text style={{ padding: 10 }}>Em Breve!</Text>
      </View>
    );

  return (
    <View style={styles.fillBackground}>
      <View style={styles.content}>
        <View style={styles.subcontainer}>
          <TouchableOpacity>
            <Text style={styles.content__heading}>Notas</Text>
          </TouchableOpacity>
        </View>
        <Search placeholder="Pesquisar por notas" searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <Text style={styles.favoriteStyle}>Ver todas as notas</Text>
        <Text style={styles.subTitleStyle}>
          Para ver todas as notas, anexos e conversas sobre um talhão específico, será necessário seleconar nos campos
          abaixo.
        </Text>
        <ListDivider />

        <TouchableOpacity style={styles.containerList} onPress={() => navigation.navigate('NotasFarmer')}>
          <UserIcon height={24} width={24} />
          <Text style={styles.listTextStyle}>{selectedClient ? selectedClient.name : 'Selecionar Agricultor'}</Text>
          <Entypo name="chevron-right" size={24} color="grey" style={styles.moreContainer} />
        </TouchableOpacity>
        <ListDivider />

        <TouchableOpacity style={styles.containerList} onPress={() => navigation.navigate('NotasFarm')}>
          <FazendaIcon width={24} height={24} />
          <Text style={styles.listTextStyle}>{selectedFarm ? selectedFarm.name : 'Selecionar Fazenda'}</Text>
          <Entypo name="chevron-right" size={24} color="grey" style={styles.moreContainer} />
        </TouchableOpacity>
        <ListDivider />
        <TouchableOpacity style={styles.containerList} onPress={() => navigation.navigate('NotasArea')}>
          <TalhaoIcon height={24} width={24} />
          <Text style={styles.listTextStyle}>{selectedArea ? selectedArea.name : 'Selecionar Talhão'}</Text>
          <Entypo name="chevron-right" size={24} color="grey" style={styles.moreContainer} />
        </TouchableOpacity>
        <ListDivider />
        <TouchableOpacity disabled={loading} style={styles.button} onPress={handleSelect}>
          {loading ? (
            <ActivityIndicator color="white" size={18} />
          ) : (
            <Text style={styles.content__button}>Ver notas</Text>
          )}
        </TouchableOpacity>
        <TouchableOpacity style={styles.buttonCreateNote} onPress={handleGoToCreateNote}>
          <Text style={styles.contentButtonCreateNote}>Criar nota</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};
