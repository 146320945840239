import React from 'react';
import { ActivityIndicator } from 'react-native';
import { RectButtonProps } from 'react-native-gesture-handler';
import { Container, Title } from './styles';
import theme from '../../../global/styles/theme';

interface Props extends RectButtonProps {
  title: string;
  color?: string;
  loading?: boolean;
  light?: boolean;
}

export function ButtonComponent({ title, color, onPress, enabled = true, loading = false, light = false }: Props) {
  const isEnabledOrLoading = !enabled || loading;

  return (
    <Container
      color={color ? color : theme.colors.green1light}
      onPress={onPress}
      enabled={enabled}
      style={[{ opacity: isEnabledOrLoading ? 0.9 : 1 }, { backgroundColor: !enabled ? '#E3E3E3' : '#66CF97' }]}
    >
      {loading ? <ActivityIndicator color="#fff" /> : <Title light={light}>{title}</Title>}
    </Container>
  );
}
