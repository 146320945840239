import AsyncStorage from '@react-native-async-storage/async-storage';
import { ClientState } from '../../contexts/reducers/ClientReducer';

export const STORAGE_AUTH_ALIAS = '@Client';

export const GetClientStorage = async (): Promise<ClientState> => {
  const client = await AsyncStorage.getItem(STORAGE_AUTH_ALIAS);
  return !!client ? JSON.parse(client) : null;
};

export const SetClientStorage = async (clientState: ClientState) => {
  AsyncStorage.setItem(STORAGE_AUTH_ALIAS, JSON.stringify(clientState));
};

export const DeleteClientStorage = async () => {
  await AsyncStorage.removeItem(STORAGE_AUTH_ALIAS);
};
