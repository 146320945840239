import styled from 'styled-components/native';
import { TextInput } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import theme from '../../../global/styles/theme';

interface ContainerProps {
  error?: string;
  disabled?: boolean;
  color?: 'white' | 'gray';
}

export interface PropsIcon {
  hasError?: boolean;
}

export const ContainerWrapper = styled.View<ContainerProps>`
  height: 46px;
  margin-right: 16px;
  background-color: ${theme.colors.white};
  margin-top: 8px;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;
  left: 2px;
`;

export const Container = styled(TextInput)<ContainerProps>`
  flex: 1;
  height: 46px;
  font-family: ${theme.fonts.text400};
  font-size: 14px;
  padding-left: 8px;
  background-color: ${theme.colors.white};
  color: ${({ color }) => (color === 'white' ? theme.colors.black : theme.colors.black)};
  width: 100%;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  /* outline-style: none; */
`;

export const Error = styled.Text`
  color: ${theme.colors.warningdark};
  font-size: 14px;
  font-family: ${theme.fonts.text400};
  margin-top: 4px;
`;

export const VisibilityWrapper = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;

export const IconContainer = styled.View`
  margin-left: 16px;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.blue2};
  height: 46px;
  width: 40px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
`;

export const IconContent = styled(FontAwesome5).attrs((props: PropsIcon) => {
  return {
    color: props.hasError ? theme.colors.warninglight : theme.colors.white,
    size: 15,
  };
})<PropsIcon>``;
