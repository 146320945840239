import { useContext, useEffect } from 'react';
import MapContext from '../MapContext';
//import OLVectorLayer from "ol/layer/Vector";
//import Static from "ol/source/ImageStatic";
import ImageLayer from 'ol/layer/Image';
//import { Projection } from "ol/proj";
//import { source } from "openlayers";
//import { ImageStatic } from "ol/source";

const PngLayer = ({ source, style, zIndex = 0 }: any) => {
  const { map } = useContext(MapContext);
  let achou = false;
  let urlPngLayer = source.url;
  if (!map) return null;
  map.getLayers().forEach(function (layer) {
    if (layer instanceof ImageLayer) {
      let urlImagemMapa = layer.values_.source.url_;
      if (urlImagemMapa != urlPngLayer) {
        map.removeLayer(layer);
      }
      if (urlImagemMapa == urlPngLayer) {
        achou = true;
      }
    }
  });

  if (achou == false) {
    let vectorLayer = new ImageLayer({ source, style });
    map.addLayer(vectorLayer);
    vectorLayer.setZIndex(zIndex + 1);
  }

  useEffect(() => {
    if (!map) return;

    let vectorLayer = new ImageLayer({
      source,
      style,
    });

    map.addLayer(vectorLayer);
    vectorLayer.setZIndex(zIndex);

    return () => {
      if (map) {
        map.removeLayer(vectorLayer);
      }
    };
  }, [map]);

  return null;
};

export default PngLayer;
