import React, { useEffect, useMemo, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Div } from 'react-native-magnus';

import { Search } from '../Search';
import { styles } from './styles';
import VoltarIcon from '../../../assets/Svg/VoltarIcon';

import { useFarms } from '../../hooks/Farm';
import { List } from '../List';
import { Fazendas } from '../Fazendas';
import { Farm } from '../../services/api/Farms';
import { search } from '../../utils/Search';
import { Routes } from '../../screens/Tabs/HomeTab';
import { useClient } from '../../hooks/Client';

export const ModalFazendas = ({ navigate }: { navigate: (route: Routes) => void }) => {
  const { setFarm, farms, setSelectedFarm } = useFarms();
  const { selectedClient } = useClient();
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setFarm();
  }, []);

  const handleSearch = useMemo(() => search(searchQuery, farms), [farms, searchQuery]);

  function handleSelectId(data: Farm) {
    setSelectedFarm(data);
    navigate(Routes.AREA);
  }

  return (
    <View style={styles.content}>
      <View style={styles.subContainer}>
        <Div flex={1} flexDir="row" justifyContent="flex-start" alignItems="center">
          <TouchableOpacity style={{ marginTop: 4 }} onPress={() => navigate(Routes.FARMER)}>
            <VoltarIcon color="#737373" height={24} width={24} />
          </TouchableOpacity>
          <Text style={styles.subtitle}>{selectedClient?.name}</Text>
        </Div>
      </View>
      <View>
        <Search placeholder="Buscar Fazendas" searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <Text style={{ marginTop: 16, marginLeft: 16 }}>Todas as fazendas</Text>
        <List
          data={handleSearch}
          render={(item) => <Fazendas data={item as Farm} handleSelectId={() => handleSelectId(item as Farm)} />}
        />
      </View>
    </View>
  );
};
