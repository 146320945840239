import React from "react";
import Svg, { Path, SvgProps, Circle, Rect, Ellipse } from "react-native-svg";

const CompactacoesIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <Rect width="24" height="24" rx="4" fill="#DBCCE9" />
      <Ellipse
        cx="16.7273"
        cy="11.9091"
        rx="2.36364"
        ry="5.90909"
        stroke="#4B0092"
      />
      <Path
        d="M16.7273 6H7.27272C5.96732 6 4.90909 8.64559 4.90909 11.9091C4.90909 15.1726 5.96732 17.8182 7.27272 17.8182H16.7273"
        stroke="#4B0092"
      />
      <Path
        d="M7 19L17 19"
        stroke="#4B0092"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke-dasharray="1 1.5"
      />
      <Path
        d="M7.21745 7.2922C7.25133 7.22444 7.32059 7.18164 7.39634 7.18164H8.13094C8.27962 7.18164 8.37632 7.3381 8.30983 7.47108L7.91892 8.2529C7.88504 8.32066 7.81579 8.36346 7.74004 8.36346H7.00543C6.85675 8.36346 6.76005 8.207 6.82655 8.07402L7.21745 7.2922Z"
        fill="#4B0092"
      />
      <Path
        d="M13.2371 15.5651C13.2032 15.4974 13.134 15.4546 13.0582 15.4546H12.3236C12.1749 15.4546 12.0782 15.6111 12.1447 15.744L12.5356 16.5259C12.5695 16.5936 12.6388 16.6364 12.7145 16.6364H13.4491C13.5978 16.6364 13.6945 16.4799 13.628 16.347L13.2371 15.5651Z"
        fill="#4B0092"
      />
      <Path
        d="M9.87654 7.2922C9.91042 7.22444 9.97967 7.18164 10.0554 7.18164H10.79C10.9387 7.18164 11.0354 7.3381 10.9689 7.47108L10.578 8.2529C10.5441 8.32066 10.4749 8.36346 10.3991 8.36346H9.66452C9.51584 8.36346 9.41914 8.207 9.48563 8.07402L9.87654 7.2922Z"
        fill="#4B0092"
      />
      <Path
        d="M10.578 15.5651C10.5441 15.4974 10.4749 15.4546 10.3991 15.4546H9.66453C9.51585 15.4546 9.41915 15.6111 9.48564 15.744L9.87655 16.5259C9.91043 16.5936 9.97968 16.6364 10.0554 16.6364H10.79C10.9387 16.6364 11.0354 16.4799 10.9689 16.347L10.578 15.5651Z"
        fill="#4B0092"
      />
      <Path
        d="M12.5356 7.2922C12.5695 7.22444 12.6388 7.18164 12.7145 7.18164H13.4491C13.5978 7.18164 13.6945 7.3381 13.628 7.47108L13.2371 8.2529C13.2032 8.32066 13.134 8.36346 13.0582 8.36346H12.3236C12.1749 8.36346 12.0782 8.207 12.1447 8.07402L12.5356 7.2922Z"
        fill="#4B0092"
      />
      <Path
        d="M7.9189 15.5651C7.88502 15.4974 7.81577 15.4546 7.74001 15.4546H7.00541C6.85673 15.4546 6.76003 15.6111 6.82652 15.744L7.21743 16.5259C7.25131 16.5936 7.32056 16.6364 7.39632 16.6364H8.13092C8.2796 16.6364 8.3763 16.4799 8.30981 16.347L7.9189 15.5651Z"
        fill="#4B0092"
      />
      <Path
        d="M7.42306 13.4879C7.40436 13.3944 7.32228 13.3271 7.22694 13.3271H6.45304C6.32683 13.3271 6.23218 13.4426 6.25693 13.5664L6.41328 14.3482C6.43198 14.4417 6.51406 14.509 6.6094 14.509H7.3833C7.50951 14.509 7.60417 14.3935 7.57942 14.2697L7.42306 13.4879Z"
        fill="#4B0092"
      />
      <Path
        d="M11.6134 9.35145C11.632 9.25797 11.7141 9.19067 11.8095 9.19067H12.5834C12.7096 9.19067 12.8042 9.30614 12.7795 9.4299L12.6231 10.2117C12.6044 10.3052 12.5223 10.3725 12.427 10.3725H11.6531C11.5269 10.3725 11.4322 10.257 11.457 10.1333L11.6134 9.35145Z"
        fill="#4B0092"
      />
      <Path
        d="M12.6231 13.4879C12.6044 13.3944 12.5223 13.3271 12.427 13.3271H11.6531C11.5268 13.3271 11.4322 13.4426 11.4569 13.5664L11.6133 14.3482C11.632 14.4417 11.7141 14.509 11.8094 14.509H12.5833C12.7095 14.509 12.8042 14.3935 12.7794 14.2697L12.6231 13.4879Z"
        fill="#4B0092"
      />
      <Path
        d="M6.41334 9.35145C6.43204 9.25797 6.51412 9.19067 6.60946 9.19067H7.38335C7.50956 9.19067 7.60422 9.30614 7.57947 9.4299L7.42311 10.2117C7.40441 10.3052 7.32233 10.3725 7.22699 10.3725H6.4531C6.32689 10.3725 6.23223 10.257 6.25698 10.1333L6.41334 9.35145Z"
        fill="#4B0092"
      />
      <Path
        d="M10.0231 13.4879C10.0044 13.3944 9.92228 13.3271 9.82695 13.3271H9.05305C8.92684 13.3271 8.83218 13.4426 8.85693 13.5664L9.01329 14.3482C9.03199 14.4417 9.11407 14.509 9.20941 14.509H9.9833C10.1095 14.509 10.2042 14.3935 10.1794 14.2697L10.0231 13.4879Z"
        fill="#4B0092"
      />
      <Path
        d="M9.01334 9.35145C9.03204 9.25797 9.11412 9.19067 9.20946 9.19067H9.98336C10.1096 9.19067 10.2042 9.30614 10.1795 9.4299L10.0231 10.2117C10.0044 10.3052 9.92234 10.3725 9.827 10.3725H9.0531C8.92689 10.3725 8.83224 10.257 8.85699 10.1333L9.01334 9.35145Z"
        fill="#4B0092"
      />
      <Rect
        x="11.35"
        y="11.3181"
        width="1.18182"
        height="1.18182"
        rx="0.2"
        fill="#4B0092"
      />
      <Rect
        x="8.75"
        y="11.3181"
        width="1.18182"
        height="1.18182"
        rx="0.2"
        fill="#4B0092"
      />
      <Rect
        x="6.14999"
        y="11.3181"
        width="1.18182"
        height="1.18182"
        rx="0.2"
        fill="#4B0092"
      />
      <Ellipse
        cx="16.7273"
        cy="11.909"
        rx="0.590909"
        ry="2.36364"
        fill="#4B0092"
      />
    </Svg>
  );
};

export default CompactacoesIcon;
