import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CDN } from '../../utils/Urls';
import { SatelliteValues } from '../api/ElementsFromMap';

export const STORAGE_AUTH_ALIAS = '@Satellite';

const cacheImagesFromSatellite = (satelliteState: SatelliteValues[]) => {
  if (Platform.OS != 'web') {
    console.warn(`[${Platform.OS}] Este ambiente ainda nao possui cache de imagens implementado`);
    return;
  }

  satelliteState.forEach((satelliteItem) => {
    satelliteItem.values.forEach((valueItem) => {
      if (valueItem?.images) {
        Object.keys(valueItem.images).forEach((key, index) => {
          if (valueItem.images[index]) {
            const imageUrl = `${CDN}${valueItem.images[index].web_map.image_url}`;
            new Image().src = imageUrl; // Force cache image in navigator
          }
        });
      }
    });
  });
};

export const GetSatelliteStorage = async (id: number): Promise<SatelliteValues[]> => {
  const Satellite = await AsyncStorage.getItem(`${STORAGE_AUTH_ALIAS}_${id}`);
  return !!Satellite ? JSON.parse(Satellite) : null;
};

export const SetSatelliteStorage = async (id: number, satelliteState: SatelliteValues[]) => {
  cacheImagesFromSatellite(satelliteState);
  await AsyncStorage.setItem(`${STORAGE_AUTH_ALIAS}_${id}`, JSON.stringify(satelliteState));
};

export const DeleteSatelliteStorage = async (id: number) => {
  await AsyncStorage.removeItem(`${STORAGE_AUTH_ALIAS}_${id}`);
};
