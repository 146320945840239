import { useEffect, useState, useContext, useMemo, useCallback } from 'react';

import { AppContext } from '../contexts';
import {
  getSatelliteFromAreaSelected,
  SatelliteValues,
  UnityFertility,
  ValueSatellite,
} from '../services/api/ElementsFromMap';
import { GetSatelliteStorage, SetSatelliteStorage } from '../services/database/SatelliteStorage';

export function useSatelliteFromArea() {
  const { areaState, areaDispatch, satelliteState, satelliteDispatch } = useContext(AppContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (satelliteState.satellites.length && update && areaState.selectedArea) {
      SetSatelliteStorage(areaState.selectedArea.id, satelliteState.satellites);
      setUpdate(false);
    }
  }, [update]);

  const getSatellitesNDVIs = async (areas: any) => {
    const recentArea = areas.sort((a, b) => (a.year > b.year ? -1 : 1))[0];

    const response = await getSatelliteFromAreaSelected(recentArea.id as number);
    const data = response.data.sort((a, b) => (a.year > b.year ? 1 : -1));

    if (data.length <= 0) {
      satelliteDispatch({
        type: 'SetSatellitesFromArea',
        payload: [],
      });
    }

    if (data.length > 0) {
      satelliteDispatch({
        type: 'SetSatellitesFromArea',
        payload: data,
      });
    }
    setUpdate(true);
  };

  const setSatelliteByArea = useCallback(async () => {
    setLoading(true);

    try {
      if (!areaState.selectedArea?.id) return;
      satelliteDispatch({
        type: 'resetSatellitesFromArea',
      });

      const stored = await GetSatelliteStorage(areaState.selectedArea?.id);
      if (stored && stored.length) {
        satelliteDispatch({
          type: 'SetSatellitesFromArea',
          payload: stored,
        });
      }

      await getSatellitesNDVIs(areaState.selectedArea.areas);
    } catch (err) {
      console.error(err);
      console.log('ERR:', err);
    } finally {
      setLoading(false);
    }
  }, [satelliteState]);

  const resetArea = () => {
    areaDispatch({
      type: 'resetAreaState',
    });
  };

  const setSelectedSatellite = useCallback(
    (satellite: ValueSatellite | undefined) => {
      satelliteDispatch({
        type: 'SetSelectedSatellite',
        payload: satellite,
      });
    },
    [satelliteState],
  );

  const satellites = useMemo(() => satelliteState.satellites, [satelliteState]);

  const selectedSatellite = useMemo(() => satelliteState.selectedSatellite, [satelliteState]);

  return {
    satellites,
    loading,
    selectedSatellite,
    setSelectedSatellite,
    resetArea,
    setSatelliteByArea,
    getSatellitesNDVIs,
  };
}
