import { FerlilitiesValues, UnityFertility } from '../../services/api/ElementsFromMap';

export type NotesState = {
  notes: [];
  // selectedFertility?: FerlilitiesValues;
};

/* export interface ResetFertilitiesFromAreaState {
    type: 'resetFertilitiesFromArea';
}
 */
export interface SetNotesFromArea {
  type: 'SetNotesFromArea';
  payload: [];
}
/* 
export interface SetSelectedFertilityState {
    type: 'SetSelectedFertility';
    payload: FerlilitiesValues;
} */

export type NotesActions = SetNotesFromArea;

export const DEFAULT_NOTES_STATE: NotesState = {
  notes: [],
  //   selectedFertility: undefined,
};

const NotesFromAreaReducer = (state: NotesState, action: NotesActions): NotesState => {
  switch (action.type) {
    /*         case 'resetFertilitiesFromArea': {
            return DEFAULT_NOTES_STATE;
        } */

    case 'SetNotesFromArea': {
      return {
        ...state,
        notes: action.payload,
      };
    }
    /* 
        case 'SetSelectedFertility': {
            return {
                ...state,
                selectedFertility: action.payload,
            };
        } */

    default:
      return state;
  }
};

export default NotesFromAreaReducer;
