import { StyleSheet, StatusBar } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 70,
    flexDirection: 'row',
    backgroundColor: 'transparent',
    position: 'absolute',
    zIndex: 2,
    flex: 1,
    marginTop: StatusBar.currentHeight || 30,
  },
  item: {
    backgroundColor: '#fff',
    marginVertical: 8,
    marginHorizontal: 8,
    marginRight: 16,
    height: 36,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    borderRadius: 8,
  },
  title: {
    marginRight: 8,
    fontSize: 14,
    textAlign: 'center',
  },

  Icons: {
    marginLeft: 16,
  },
});
