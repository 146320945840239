import React from 'react';

import { NotasScreen } from '../../../components/NotasScreen';
import { NotasFarmer } from '../../../components/NotasScreen/NotasFarmer';
import { NotasFarm } from '../../../components/NotasScreen/NotasFarm';
import { NotasArea } from '../../../components/NotasScreen/NotasArea';
import { NotasList } from '../../../components/NotasScreen/NotasList';

import { createStackNavigator } from '@react-navigation/stack';
import { NotasDetails } from '../../../components/NotasScreen/NotasDetails';

export function NotasStack() {
  const Stack = createStackNavigator();
  return (
    <Stack.Navigator>
      <Stack.Screen name="Notas" component={NotasScreen} />
      <Stack.Screen
        name="NotasFarmer"
        component={NotasFarmer}
        options={{
          headerTitle: 'Agricultores',
        }}
      />
      <Stack.Screen
        name="NotasFarm"
        component={NotasFarm}
        options={{
          headerTitle: 'Fazendas',
        }}
      />
      <Stack.Screen
        name="NotasArea"
        component={NotasArea}
        options={{
          headerTitle: 'Talhões',
        }}
      />
      <Stack.Screen
        name="NotasList"
        component={NotasList}
        options={{
          headerTitle: 'Ver notas',
        }}
      />
      <Stack.Screen
        name="NotasDetails"
        component={NotasDetails}
        options={{
          headerTitle: 'Detalhes da nota',
        }}
      />
    </Stack.Navigator>
  );
}
