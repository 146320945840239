import api from '..';
import { AxiosResponse } from 'axios';

export type Client = {
  id: number;
  name?: string;
  activated?: boolean;
  display_id?: number;
  farmer_id?: number;
  v2_id?: number;
  v2_sync?: string;
  sm_id?: number;
};

export type ClientParams = {
  lastSyncDate: string;
  userId: Number;
  userFbiToken?: number;
  userFbiEmail?: string;
  userFbiProfile?: string;
  userFbiUserName?: string;
};

export type ClientResponse = {
  clients: Client[];
};

export const getClients = (params: ClientParams): Promise<AxiosResponse<ClientResponse>> => {
  return api.get('sync', {
    params: {
      target: 'clients',
      ...params,
    },
  });
};
