import React from "react";
import Svg, { Path, SvgProps, Circle, Rect } from "react-native-svg";

const IconShowPassword: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 40 46"
      fill="none"
    >
      <Path
        d="M0 0L32 0C36.4183 0 40 3.58172 40 8L40 38C40 42.4183 36.4183 46 32 46L0 46L0 0Z"
        fill="#3966AE"
      />
      <Path
        d="M12.5 23C13.9971 25.6901 16.7952 27.5 20 27.5C23.2048 27.5 26.0029 25.6901 27.5 23"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <Path
        d="M27.5 23C26.0029 20.3099 23.2048 18.5 20 18.5C16.7952 18.5 13.9971 20.3099 12.5 23"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <Circle
        cx="20"
        cy="23"
        r="2.25"
        fill="white"
        stroke="white"
        strokeWidth="1.5"
      />
    </Svg>
  );
};

export default IconShowPassword;
