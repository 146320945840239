import React from "react";
import Svg, { Path, SvgProps, Rect, Circle } from "react-native-svg";

const TalhaoIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <Rect width="24" height="24" rx="4" fill="#D9EFED" />
      <Path
        d="M11.7002 11.5002V8.8335"
        stroke="#40B0A6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.7002 11.5L11.7002 9.5"
        stroke="#40B0A6"
        strokeLinecap="round"
      />
      <Path d="M17.3667 11.5L11.7 9.5" stroke="#40B0A6" strokeLinecap="round" />
      <Path
        d="M14.2813 8.09259C13.6913 8.68519 12.3147 8.83333 11.7002 8.83333C11.7002 8.21605 12.0543 7.36652 12.8064 6.61111C13.5438 5.87037 14.65 5.5 15.0188 5.5C15.0188 6.11728 15.2014 7.16837 14.2813 8.09259Z"
        stroke="#40B0A6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M9.11908 8.09259C9.70905 8.68519 11.0856 8.83333 11.7002 8.83333C11.7002 8.21605 11.3461 7.36652 10.594 6.61111C9.85654 5.87037 8.75035 5.5 8.38162 5.5C8.38162 6.11728 8.19895 7.16837 9.11908 8.09259Z"
        stroke="#40B0A6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.7002 16.5L11.7002 18.5L17.3669 16.5V14.1667M6.7002 14.1667L11.7002 16.1667L17.3669 14.1667M17.3669 14.1667V11.5L11.7002 13.5L6.7002 11.5"
        stroke="#40B0A6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.7002 11.5V16.5"
        stroke="#40B0A6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Circle cx="14.5" cy="13.5" r="0.5" fill="#40B0A6" />
      <Circle cx="12.5" cy="14.5" r="0.5" fill="#40B0A6" />
      <Circle cx="9.5" cy="13.5" r="0.5" fill="#40B0A6" />
    </Svg>
  );
};

export default TalhaoIcon;
