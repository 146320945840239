import { Alert, Platform } from 'react-native';

export const AlertMessage = (message: string) => {
  if (Platform.OS === 'web') {
    alert(message);
    return;
  }
  Alert.alert('Aviso!', message);
  return;
};
