import React from "react";
import Svg, { Path, SvgProps, Circle, Rect } from "react-native-svg";

const VoltarIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <Path d="M12 8H4" stroke={props.color} strokeLinecap="round" />
      <Path
        d="M7.33333 4.66667L4 8"
        stroke={props.color}
        strokeLinecap="round"
      />
      <Path
        d="M7.33333 11.3333L4 8"
        stroke={props.color}
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default VoltarIcon;
