import { FarmResponse, Farm } from '../../services/api/Farms';
import { DEFAULT_SYNC_DATE } from '../../utils/constantes';

export type FarmState = FarmResponse & {
  clientId?: number;
  lastSyncDate: string;
  selectedFarm?: Farm;
};

export type farmPayload = FarmResponse & {
  clientId?: number;
  selectedFarm?: Farm;
};

export interface ResetSession {
  type: 'resetFarmSession';
}

export interface SetSession {
  type: 'setFarmSession';
  payload: farmPayload;
}

export interface UpdateFarmState {
  type: 'updateFarmState';
  payload: farmPayload;
}

export interface SetSelectedFarm {
  type: 'setSelectedFarm';
  payload: Farm | undefined;
}

export interface resetSelectedFarm {
  type: 'resetSelectedFarm';
}

export type FarmActions = ResetSession | SetSession | UpdateFarmState | SetSelectedFarm | resetSelectedFarm;

export const DEFAULT_FARM_STATE: FarmState = {
  clientId: undefined,
  farms: [],
  selectedFarm: undefined,
  lastSyncDate: DEFAULT_SYNC_DATE,
};

const FarmReducer = (state: FarmState, action: FarmActions): FarmState => {
  switch (action.type) {
    case 'resetFarmSession': {
      return DEFAULT_FARM_STATE;
    }

    case 'setFarmSession': {
      return {
        ...state,
        clientId: action.payload.clientId,
        farms: action.payload.farms,
      };
    }

    case 'updateFarmState': {
      return {
        ...state,
        clientId: action.payload.clientId,
        farms: state.farms.concat(...action.payload.farms),
        lastSyncDate: new Date().toISOString(),
      };
    }

    case 'setSelectedFarm': {
      return {
        ...state,
        selectedFarm: action.payload,
      };
    }

    case 'resetSelectedFarm': {
      return {
        ...state,
        selectedFarm: undefined,
      };
    }

    default:
      return state;
  }
};

export default FarmReducer;
