import React from 'react';
import { Div, Text } from 'react-native-magnus';
import theme from '../../global/styles/theme';

export const Day = () => {
  return (
    <Div
      flexDir="row"
      borderBottomWidth={1}
      borderColor={theme.colors.grey2}
      w="100%"
      flex={1}
      pl={16}
      pr={16}
      alignItems="center"
      justifyContent="space-between"
    >
      <Text color={theme.colors.grey5} fontWeight="700">
        Data
      </Text>
      <Text fontWeight="700" color={theme.colors.blue2}>
        Hoje
      </Text>
    </Div>
  );
};
