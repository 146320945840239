import React, { useState, useEffect } from 'react';
import { Platform, View, TouchableOpacity } from 'react-native';
import * as Location from 'expo-location';
import * as turf from '@turf/turf';

// import map
import Map from '../../components/map/Map';
import { fromLonLat, get, Projection } from 'ol/proj';
import Constants from 'expo-constants';

// import config map
import mapConfig from '../../components/map/config.json';
import { Layers, TileLayer, VectorLayer, PngLayer } from '../../components/map/layers';
import { osm, vector } from '../../components/map/source';
import GeoJSON from 'ol/format/GeoJSON';
import { Controls } from '../../components/map/controls';
import FeatureStyles from '../../components/map/features/Styles';
import { ActivityIndicator, Text } from 'react-native-paper';
import { styles } from './styles';
import { Div } from 'react-native-magnus';
import theme from '../../global/styles/theme';
import { MapActions } from '../MapItensAgricultores';
import { useAreas } from '../../hooks/Area';
import { useFarms } from '../../hooks/Farm';
import { useFertilitiesFromArea } from '../../hooks/FertilityFromArea';
import Static from 'ol/source/ImageStatic';
import { Area } from '../../services/api/Areas';
import { Routes } from '../../screens/Tabs/HomeTab';
import { ElementsSubtitle } from '../ElementsSubtitles';

import { CDN } from '../../utils/Urls';
import { useMapElements } from '../../hooks/Map';
import { useSatelliteFromArea } from '../../hooks/SatelliteFromArea';
import VectorLayerUserPosition from '../map/layers/VectorLayerUserPosition';
import { DEFAULT_MAP_STATE } from '../../contexts/reducers/MapReducer';
import { TopFlatList } from '../FlatListTop';

// api key js MAP
//AIzaSyBcTe1FGUScRaAn99vqv3d8EHYM_0B2fEg
const markersLonLat = [mapConfig.kansasCityLonLat, mapConfig.blueSpringsLonLat];

export type CoordinatesProps = {
  type: string;
  features: [
    {
      type: string;
      properties: {
        id?: number;
        name: string;
        area?: Area;
      };
      geometry: {
        type: string;
        coordinates: any;
      };
    },
  ];
};

export enum MAP_TYPE {
  STREET = 'STREET',
  SATELLITE = 'SATELLITE',
}

type WatcherProps = {
  remove: Function;
};

export default function MapComponent({
  navigate,
  route,
  openModalReport,
  closeModalReport,
  selectedId,
  location,
  setLocation,
}: {
  navigate: (route: Routes) => void;
  route: Routes;
  openModalReport: () => void;
  closeModalReport: () => void;
  selectedId: TopFlatList;
  setLocation: ({ coords: { latitude, longitude } }) => void;
  location: {
    coords: {
      latitude: number;
      longitude: number;
    };
  };
}) {
  const [errorMsg, setErrorMsg] = useState('');
  const [cordinates, setCordinates] = useState<CoordinatesProps | undefined>(undefined);
  const zoom = 15;
  const [areaCoordinates, setAreaCoordinates] = useState<CoordinatesProps[] | undefined>();
  const { areas, setSelectedArea, selectedArea, selectedYearSafra, selectedAreasGroup } = useAreas();
  const { selectedFarm } = useFarms();
  const [isPositionWatched, setIsPositionWatched] = useState<boolean>(false);
  const [loadingWatchedPosition, setLoadingWatchedPosition] = useState<boolean>(false);
  const [hasToCenterFarm, setHasToCenterFarm] = useState<boolean>(false);
  const { element, setElement } = useMapElements();
  const { selectedFertility, fertilitiesDispatch } = useFertilitiesFromArea();
  const { selectedSatellite, getSatellitesNDVIs } = useSatelliteFromArea();
  const [watcher, setWatcher] = useState<WatcherProps>();
  const [mapType, setTypeMap] = useState(MAP_TYPE.STREET);

  useEffect(() => {
    setAreaCoordinates([]);
    if (!selectedFarm) {
      setMyCoordinatesCenter();
    }
  }, [selectedFarm]);

  useEffect(() => {
    let area: CoordinatesProps[] = [];
    if (areas.length && !selectedArea) {
      areas.map((value) => {
        area.push({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {
                id: value.id,
                name: value.name,
                area: value,
              },
              geometry: {
                type: 'MultiPolygon',
                coordinates: [value.the_geom.coordinates],
              },
            },
          ],
        });
      });

      const features = area.map((item) => {
        return item.features;
      });
      const coordinateCenterFarm = features.map((feat) =>
        feat.map((coords) => {
          const [coordinate] = coords.geometry.coordinates[0];
          return coordinate;
        }),
      );
      setAreaCoordinates(area);

      const centeredArea = turf.centroid({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'MultiPolygon',
              coordinates: coordinateCenterFarm,
            },
          },
        ],
      });

      const [longitude, latitude] = centeredArea.geometry.coordinates;

      if (location.coords.latitude !== latitude && location.coords.longitude !== longitude) {
        setLocation({
          coords: {
            latitude,
            longitude,
          },
        });
      }
    }
  }, [areas]);

  useEffect(() => {
    let area: CoordinatesProps[] = [];
    if (areas.length && selectedArea) {
      areas
        .filter((item) => item.year === selectedYearSafra)
        .map((value) => {
          area.push({
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {
                  id: value.id,
                  name: value.name,
                  area: value,
                },
                geometry: {
                  type: 'MultiPolygon',
                  coordinates: [value.the_geom.coordinates],
                },
              },
            ],
          });
        });

      const areaSelected: CoordinatesProps = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              id: selectedArea.id,
              name: selectedArea.name,
              area: selectedArea,
            },
            geometry: {
              type: 'MultiPolygon',
              coordinates: [selectedArea.the_geom.coordinates],
            },
          },
        ],
      };

      const coordinateCenterFarm = areaSelected.features.map((coords) => {
        const [coordinate] = coords.geometry.coordinates[0];
        return coordinate;
      });

      setAreaCoordinates(area);

      const centeredArea = turf.centroid({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'MultiPolygon',
              coordinates: [coordinateCenterFarm],
            },
          },
        ],
      });

      const [longitude, latitude] = centeredArea.geometry.coordinates;

      if (location.coords.latitude !== latitude && location.coords.longitude !== longitude) {
        setLocation({
          coords: {
            latitude,
            longitude,
          },
        });
      }
    }
  }, [selectedYearSafra]);

  const watchPosition = () => {
    setLoadingWatchedPosition(true);
    Location.watchPositionAsync(
      {
        accuracy: Location.Accuracy.Highest,
        distanceInterval: 1,
      },
      (loc) => {
        setLocation(loc);
        setCordinates({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {
                name: 'Localizacao',
              },
              geometry: {
                type: 'Point',
                coordinates: [loc.coords.longitude, loc.coords.latitude],
              },
            },
          ],
        });
      },
    )
      .then((locationWatcher) => {
        setIsPositionWatched(true);
        setWatcher(locationWatcher);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoadingWatchedPosition(false));
  };

  useEffect(() => {
    watchPosition();
  }, []);

  useEffect(() => {
    (async () => {
      if (Platform.OS === 'android' && !Constants.isDevice) {
        setErrorMsg('Oops, this will not work on Snack in an Android emulator. Try it on your device!');
        return;
      }
      const { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        setErrorMsg('Permission to access location was denied');
        return;
      }
    })();
  }, []);

  const setMyCoordinatesCenter = async () => {
    const loc = await Location.getCurrentPositionAsync();
    setLocation(loc);
    setCordinates({
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            name: 'Localizacao',
          },
          geometry: {
            type: 'Point',
            coordinates: [loc.coords.longitude, loc.coords.latitude],
          },
        },
      ],
    });
  };

  const toggleWatchPosition = async () => {
    if (isPositionWatched) {
      setLoadingWatchedPosition(true);
      await watcher?.remove();
      setIsPositionWatched(false);
      setLoadingWatchedPosition(false);
    } else {
      watchPosition();
    }
  };

  const changeMapType = () => {
    if (mapType === MAP_TYPE.STREET) {
      setTypeMap(MAP_TYPE.SATELLITE);
    }
    if (mapType === MAP_TYPE.SATELLITE) {
      setTypeMap(MAP_TYPE.STREET);
    }
  };

  const centerMap = () => {
    if (selectedArea) {
      setHasToCenterFarm(true);

      const coordinatesArea = selectedArea.the_geom.coordinates[0];
      const features = turf.points(coordinatesArea);
      const centeredArea = turf.center(features);
      const [longitude, latitude] = centeredArea.geometry.coordinates;

      setLocation({
        coords: {
          latitude,
          longitude,
        },
      });
    }
  };

  const selectGroupedArea = async () => {
    const findArea = selectedAreasGroup && selectedAreasGroup.find((item) => item.name === selectedArea?.name);
    if (findArea) {
      setElement(DEFAULT_MAP_STATE);
      if (selectedId === TopFlatList.Fertility) {
        fertilitiesDispatch(findArea.areas);
      }
      if (selectedId === TopFlatList.SatelliteMap) {
        getSatellitesNDVIs(findArea.areas);
      }
      setSelectedArea(findArea);
    }
  };

  useEffect(() => {
    if (selectedAreasGroup && selectedAreasGroup.length) {
      selectGroupedArea();
    }
  }, [selectedArea]);

  const mapClicked = (area: Area) => {
    if (!selectedArea || (!!area && !!area.id && !!selectedArea && selectedArea.id !== area.id)) {
      setSelectedArea(area);
      navigate(Routes.AREA_SELECTED);
    }
  };

  const isPointInsideLayer = (layerCoordinates: CoordinatesProps) => {
    const point = turf.point(cordinates?.features[0].geometry.coordinates);
    const polygon = turf.polygon([layerCoordinates.features[0].geometry.coordinates[0][0]]);

    const isPointInsideLayer: boolean = turf.booleanPointInPolygon(point, polygon);

    if (isPointInsideLayer) {
      const findArea = areas.find((item) => item.id === layerCoordinates.features[0].properties.id);

      setSelectedArea(findArea as Area);
      closeModalReport();
    }
  };

  useEffect(() => {
    if (selectedArea) {
      const coordinatesArea = selectedArea.the_geom.coordinates[0];
      const features = turf.points(coordinatesArea);
      const centeredArea = turf.center(features);
      const [longitude, latitude] = centeredArea.geometry.coordinates;

      setLocation({
        coords: {
          latitude,
          longitude,
        },
      });
    }
  }, [selectedArea]);

  if (!cordinates) {
    return (
      <Div flex={1} alignItems="center" justifyContent="center">
        <ActivityIndicator color={theme.colors.green1} />
      </Div>
    );
  }

  const hasElementSelectedWithHistogram = () => {
    if (element.histogram && element.histogram.length) {
      return {
        ...element,
        histogram: [
          {
            color: element.histogram && element.histogram.length ? element?.histogram[0]?.color : '',
            limit_superior: 0,
          },
          ...element.histogram,
        ],
      };
    }
    return {
      ...element,
      histogram: [],
    };
  };

  return (
    <View style={styles.container}>
      {(selectedFertility || selectedSatellite) && element && route === Routes.AREA_SELECTED && (
        <ElementsSubtitle elementSelected={hasElementSelectedWithHistogram()} />
      )}

      <Map
        center={fromLonLat([location.coords.longitude, location.coords.latitude])}
        zoom={zoom}
        hasToCenterFarm={hasToCenterFarm}
        setHasToCenterFarm={setHasToCenterFarm}
        mapType={mapType}
        setLocation={setLocation}
        isModalReportOpen={route === Routes.REPORT}
        location={location.coords}
        onClick={(area: Area) => {
          mapClicked(area);
        }}
        onLongPress={(evt: any) => {
          console.log('On LONG PRESS CALLBACK', evt);
        }}
      >
        <MapActions
          isPositionWatched={isPositionWatched}
          toggleWatchPosition={toggleWatchPosition}
          onPressCenterMap={setMyCoordinatesCenter}
          hasSelectedArea={!!selectedArea}
          openModalReport={openModalReport}
          centerMap={centerMap}
          mapType={mapType}
          changeMapType={changeMapType}
          loadingWatchedPosition={loadingWatchedPosition}
        />
        <VectorLayerUserPosition
          source={vector({
            features: new GeoJSON().readFeatures(cordinates, {
              featureProjection: get('EPSG:3857'),
            }),
          })}
          style={FeatureStyles.Point}
          zIndex={5}
        />

        {areaCoordinates && areaCoordinates.length
          ? areaCoordinates.map((value, index) => (
              <VectorLayer
                isPointInsideLayer={() => isPointInsideLayer(value)}
                key={index + index}
                point={cordinates}
                areaCoordinates={areaCoordinates}
                source={vector({
                  features: new GeoJSON().readFeatures(value, {
                    featureProjection: get('EPSG:3857'),
                  }),
                })}
                style={FeatureStyles.MultiPolygon(value.features[0].properties.name)}
                /* styleSelected={FeatureStyles.MultiPolygonSelected(
                                  selectedArea?.name || 'Talhão selecionado',
                              )} */
                zIndex={2}
              />
            ))
          : null}

        <Layers>
          {/* <TileLayer source={osm()} zIndex={0} /> */}
          {!!element && (
            <PngLayer
              zIndex={3}
              source={
                new Static({
                  url: `${CDN}${element.image_url}`,
                  projection: new Projection({
                    code: 'xkcd-image',
                    units: 'pixels',
                    extent: [element.bbox.xmin, element.bbox.ymin, element.bbox.xmax, element.bbox.ymax],
                  }),
                  imageExtent: [element.bbox.xmin, element.bbox.ymin, element.bbox.xmax, element.bbox.ymax],
                })
              }
              style={undefined}
            />
          )}

          {/* {!!showLayerPngImagemSatelite && (
                        <PngLayer
                            zIndex={1}
                            source={
                                new Static({
                                    attributions: '© <a href="https://xkcd.com/license.html">xkcd</a>',
                                    url: pngImagemSateliteSelecionado,
                                    projection: new Projection({
                                        code: 'xkcd-image',
                                        units: 'pixels',
                                        extent: [
                                            pngImagemSatelite.bbox.xmin,
                                            pngImagemSatelite.bbox.ymin,
                                            pngImagemSatelite.bbox.xmax,
                                            pngImagemSatelite.bbox.ymax,
                                        ],
                                    }),
                                    imageExtent: [
                                        pngImagemSatelite.bbox.xmin,
                                        pngImagemSatelite.bbox.ymin,
                                        pngImagemSatelite.bbox.xmax,
                                        pngImagemSatelite.bbox.ymax,
                                    ],
                                })
                            }
                            style={undefined}
                        ></PngLayer>
                    )} */}

          {/*                     {!!showLayer2 && (
                        <VectorLayer
                            source={vector({
                                features: new GeoJSON().readFeatures(geojsonObject, {
                                    featureProjection: get('EPSG:3857'),
                                }),
                            })}
                            style={FeatureStyles.MultiPolygon}
                        />
                    )} */}
        </Layers>
        <Controls>{/*fullscreen */}</Controls>
      </Map>
      <View style={styles.boxTwoo}></View>
    </View>
  );
}
