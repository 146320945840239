import api from '..';
import { AxiosResponse } from 'axios';

export const getNotesFromAreaSelected = async (areaSelectedId: number): Promise<AxiosResponse> => {
  return api.get(`anomalies/area/${areaSelectedId}/100/0`);
};

export const deleteNoteById = async (id: number): Promise<AxiosResponse> => {
  return api.delete(`anomalies/${id}`);
};
