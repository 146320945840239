import { SatelliteValues, ValueSatellite } from '../../services/api/ElementsFromMap';

export type SatelliteState = {
  satellites: SatelliteValues[];
  selectedSatellite?: ValueSatellite;
};

export interface ResetSatellitesFromAreaState {
  type: 'resetSatellitesFromArea';
}

export interface SetSatellitesFromAreaState {
  type: 'SetSatellitesFromArea';
  payload: SatelliteValues[];
}

export interface SetSelectedSatelliteState {
  type: 'SetSelectedSatellite';
  payload: ValueSatellite;
}

export type SatellitesActions = ResetSatellitesFromAreaState | SetSatellitesFromAreaState | SetSelectedSatelliteState;

export const DEFAULT_SATELLITE_STATE: SatelliteState = {
  satellites: [],
  selectedSatellite: undefined,
};

const SatelliteFromAreaReducer = (state: SatelliteState, action: SatellitesActions): SatelliteState => {
  switch (action.type) {
    case 'resetSatellitesFromArea': {
      return DEFAULT_SATELLITE_STATE;
    }

    case 'SetSatellitesFromArea': {
      return {
        ...state,
        satellites: action.payload,
      };
    }

    case 'SetSelectedSatellite': {
      return {
        ...state,
        selectedSatellite: action.payload,
      };
    }

    default:
      return state;
  }
};

export default SatelliteFromAreaReducer;
