import { Histogram } from '../../services/api/ElementsFromMap';

export type MapState = {
  name: string;
  histogram: Histogram[];
  image_url: string;
  bbox: {
    xmax: number;
    xmin: number;
    ymax: number;
    ymin: number;
  };
};

export interface SetSelectedElementState {
  type: 'SetSelectedElement';
  payload: MapState;
}

export interface ResetSelectedElementState {
  type: 'resetSelectedElementState';
}

export type MapActions = SetSelectedElementState | ResetSelectedElementState;

export const DEFAULT_MAP_STATE: MapState = {
  name: '',
  histogram: [],
  image_url: '',
  bbox: {
    xmax: 0,
    xmin: 0,
    ymax: 0,
    ymin: 0,
  },
};

const MapReducer = (state: MapState, action: MapActions): MapState => {
  switch (action.type) {
    case 'resetSelectedElementState': {
      return DEFAULT_MAP_STATE;
    }
    case 'SetSelectedElement': {
      return action.payload;
    }
  }
};

export default MapReducer;
