import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { Icon } from 'react-native-magnus';
import Centralizarmapa24px from '../../../assets/Svg/Centralizarmapa24px';
import { MAP_TYPE } from '../MapComponent';

type MapActionsProps = {
  onPressCenterMap: () => void;
  toggleWatchPosition: () => void;
  isPositionWatched: boolean;
  hasSelectedArea: boolean;
  openModalReport: () => void;
  centerMap: () => void;
  mapType: MAP_TYPE;
  changeMapType: () => void;
  loadingWatchedPosition: boolean;
};

export function MapActions({
  onPressCenterMap,
  toggleWatchPosition,
  isPositionWatched,
  hasSelectedArea,
  openModalReport,
  centerMap,
  mapType,
  changeMapType,
  loadingWatchedPosition,
}: MapActionsProps) {
  const isNotasScreenDisabled = true;
  return (
    <View style={styles.subContainerRight}>
      {hasSelectedArea && !isNotasScreenDisabled ? (
        <TouchableOpacity onPress={openModalReport}>
          <View style={styles.boxReport}>
            <Icon fontSize={16} name="addfile" fontFamily="AntDesign" color="#3966AE" />
          </View>
        </TouchableOpacity>
      ) : null}
      {hasSelectedArea && (
        <TouchableOpacity onPress={centerMap}>
          <View style={styles.boxReport}>
            <Icon fontSize={16} name="arrows" fontFamily="FontAwesome" color="#3966AE" />
          </View>
        </TouchableOpacity>
      )}
      <TouchableOpacity onPress={onPressCenterMap}>
        <View style={styles.boxCentralizarMap}>
          <Centralizarmapa24px fill="#fff" />
        </View>
      </TouchableOpacity>
      <TouchableOpacity disabled={loadingWatchedPosition} onPress={toggleWatchPosition}>
        <View style={styles.boxToggleGeolocation}>
          <Icon
            fontSize={16}
            name={isPositionWatched && !loadingWatchedPosition ? 'location-on' : 'location-off'}
            fontFamily="MaterialIcons"
            color="#3966AE"
          />
        </View>
      </TouchableOpacity>
      <TouchableOpacity onPress={changeMapType}>
        <View style={styles.boxToggleMap}>
          <Icon
            fontSize={16}
            name={mapType === MAP_TYPE.STREET ? 'satellite' : 'streetview'}
            fontFamily="MaterialIcons"
            color="#3966AE"
          />
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: 'blue',
    borderWidth: 2,
  },
  subContainerRight: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: 16,
    top: '-20%',
    zIndex: 2,
    height: '60%',
    justifyContent: 'flex-end',
  },
  boxNiveisMap: {
    width: 32,
    height: 32,
    zIndex: 2,
    backgroundColor: '#fff',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxReport: {
    width: 32,
    height: 32,
    zIndex: 2,
    backgroundColor: '#FFF',
    borderRadius: 8,
    bottom: 10,
    alignItems: 'center',
    alignSelf: 'flex-end',
    justifyContent: 'center',
    marginBottom: 10,
  },
  boxCentralizarMap: {
    width: 32,
    height: 32,
    zIndex: 2,
    backgroundColor: '#fff',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxToggleGeolocation: {
    width: 32,
    height: 32,
    zIndex: 2,
    backgroundColor: '#fff',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    top: 10,
    position: 'absolute',
  },
  boxToggleMap: {
    width: 32,
    height: 32,
    zIndex: 2,
    backgroundColor: '#fff',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    top: 50,
    position: 'absolute',
  },
});
