import { StyleSheet } from 'react-native';
import { ThemeProvider } from 'styled-components';
import theme from '../../global/styles/theme';

export const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
  },
  modalView: {
    height: 300,
    width: 360,
    margin: 20,
    backgroundColor: 'white',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    width: 140,
    backgroundColor: theme.colors.white,
    borderColor: theme.colors.blue2,
    borderWidth: 2,
  },
  buttonOpen: {
    backgroundColor: theme.colors.blue2,
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  TextGps: {
    color: theme.colors.grey6,
    fontFamily: theme.fonts.title700,
    fontSize: 24,
  },
  textStyleNegar: {
    color: theme.colors.grey6,
    fontFamily: theme.fonts.title700,
    textAlign: 'center',
  },
});
