import React from 'react';
import { TouchableOpacity, TouchableOpacityProps, Text, View } from 'react-native';
//import { Feather } from "@expo/vector-icons";

{
  /* import de styles */
}
import { styles } from './styles';

{
  /* import de svgs está criada como function, necessário uso de width,height como props */
}
import FavoriteIcon from '../../../assets/Svg/FavoriteIcon';
import FazendaIcon from '../../../assets/Svg/FazendaIcon';
import { useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Farm } from '../../services/api/Farms';

export type AgricultoresProps = {
  id: string;
  name: string;
  icon: string | null;
  owner: boolean;
};

type Props = TouchableOpacityProps & {
  data: Farm;
  handleSelectId: () => void;
};

export function Fazendas({ data, handleSelectId, ...rest }: Props) {
  return (
    <TouchableOpacity style={styles.container} onPress={handleSelectId}>
      <FazendaIcon width={24} height={24} />
      <View style={styles.content}>
        <View>
          <Text style={styles.title}>{data.name}</Text>

          {/* <Text style={styles.type}>{!!data.owner ? '00.00 ha' : '00.00 ha'}</Text> */}
        </View>
      </View>
    </TouchableOpacity>
  );
}
