import React from 'react';
import { FlatList } from 'react-native';
import { Div } from 'react-native-magnus';

import Text from '../../../Text';
import theme from '../../../../global/styles/theme';
import { useFertilitiesFromArea } from '../../../../hooks/FertilityFromArea';
import { FerlilitiesValues, ValueSatellite } from '../../../../services/api/ElementsFromMap';
import { FertilityTouchable } from './styles';
import { useSatelliteFromArea } from '../../../../hooks/SatelliteFromArea';

const ItemForRender = ({
  name,
  setSelectedElement,
  disabled,
}: {
  name: string;
  setSelectedElement: () => void;
  disabled?: boolean;
}) => {
  const { selectedFertility } = useFertilitiesFromArea();
  const { selectedSatellite } = useSatelliteFromArea();

  return (
    <FertilityTouchable
      disabled={disabled}
      isSelected={selectedFertility?.name === name || selectedSatellite?.name === name}
      onPress={setSelectedElement}
    >
      <Text fontSize={18} fontWeight="700" color={theme.colors.white}>
        {name.toUpperCase()}
      </Text>
    </FertilityTouchable>
  );
};

type CombinedType = FerlilitiesValues & ValueSatellite;

export const FlatListElements = ({
  elements,
  setSelectedElement,
}: {
  elements: CombinedType[];
  setSelectedElement: (element: FerlilitiesValues | ValueSatellite) => void;
}) => {
  if (elements && !elements.length) {
    return (
      <Div ml={8}>
        <Text fontWeight="700">Não foram encontrados dados neste talhão!</Text>
      </Div>
    );
  }

  return (
    <FlatList
      showsHorizontalScrollIndicator={false}
      horizontal={true}
      data={elements}
      keyExtractor={(item) => item.name}
      renderItem={({ item }) => (
        <ItemForRender
          disabled={item.values === null || item.images === null}
          setSelectedElement={() => setSelectedElement(item)}
          name={item.name}
        />
      )}
    />
  );
};
