import React, { useEffect, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';

import { AuthRoutes } from './auth.routes';
import { useAuth } from '../hooks/Auth';
import { PrivateRoutes } from './private.routes';

export function Routes() {
  const { isLogged } = useAuth();
  return <NavigationContainer>{isLogged ? <PrivateRoutes /> : <AuthRoutes />}</NavigationContainer>;
}
