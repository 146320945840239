import { StyleSheet, StatusBar } from 'react-native';
import theme from '../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: StatusBar.currentHeight || 0,
    backgroundColor: theme.colors.white,
  },
  textContainer: {
    color: theme.colors.grey6,
    fontFamily: theme.fonts.title700,
    marginTop: 16,
    fontSize: 12,
  },
  senhaAntigaContainer: {
    marginLeft: 16,
    marginTop: 16,
  },
  textInput: {
    marginTop: 8,
    width: '100%',
    marginRight: 16,
    height: 46,
    borderRadius: 8,
    padding: 8,
    backgroundColor: '#fff',
    borderWidth: 1,
  },
  inputContainer: {
    marginLeft: 16,
  },
  emailText: {
    color: theme.colors.grey6,
    fontFamily: theme.fonts.text400,
    fontWeight: 'bold',
    fontSize: 12,
  },
  TextRecuperar: {
    color: theme.colors.grey5,
    fontFamily: theme.fonts.text400,
    fontSize: 12,
    marginTop: 8,
  },
  antigaText: {
    color: theme.colors.grey6,
    fontFamily: theme.fonts.text400,
    fontSize: 12,
  },
  marginText: {
    marginTop: 16,
  },
  buttonContainer: {
    marginTop: 16,
    marginRight: 16,
  },
});
