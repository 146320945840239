import styled from 'styled-components/native';
import { StyleSheet } from 'react-native';

export const StyledView = styled.View`
  height: 210px;
  width: 160px;
  z-index: 2;
  position: absolute;
  left: 0;
  margin-left: 24px;
  flex: 1;
  background: transparent;
  border-radius: 12px;
  padding-bottom: 16px;
`;

export const styles = StyleSheet.create({
  containerScrollView: {
    marginTop: 4,
    marginBottom: 8,
    alignItems: 'flex-start',
    borderRadius: 12,
  },
});
