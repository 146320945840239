import styled from 'styled-components/native';
import theme from '../../../global/styles/theme';
export const Container = styled.View`
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  padding-top: 40px;
`;

export const StyledImage = styled.Image`
  width: 140px;
  height: 50px;
`;

export const Row = styled.View`
  flex-direction: row;
  padding: 40px 16px 0 16px;
  width: 100%;
`;

export const Circle = styled.View`
  width: 42px;
  height: 42px;
  border-radius: 21px;
  border: 1px solid ${theme.colors.green3};
  justify-content: center;
  align-items: center;
`;

export const NamedCircle = styled.View`
  width: 38px;
  height: 38px;
  border-radius: 19px;
  background: ${theme.colors.blue2};
  justify-content: center;
  align-items: center;
`;

export const LogoutButton = styled.TouchableOpacity`
  width: 46px;
  height: 46px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  border: 2px solid ${theme.colors.blue2};
`;
