import React, { useEffect, useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Search } from '../../Search';
import { List } from '../../List';
import { Area } from '../../../services/api/Areas';

import { styles } from '../style';
import { search } from '../../../utils/Search';
import { useNavigation } from '@react-navigation/native';
import { Talhoes } from '../../Talhoes';
import { useAreas } from '../../../hooks/Area';
import { useNotesFromArea } from '../../../hooks/Anomalies';

export const NotasArea = () => {
  const { setArea, areas, setSelectedArea } = useAreas();
  const { setNotesByArea } = useNotesFromArea();

  const navigation = useNavigation<any>();

  const [searchQuery, setSearchQuery] = React.useState('');

  useEffect(() => {
    setArea();
  }, []);

  const handleSearch = useMemo(() => search(searchQuery, areas), [areas, searchQuery]);

  async function handleSelectId(data: Area) {
    setSelectedArea(data);
    await setNotesByArea(data.id);
    navigation.navigate('Notas');
  }

  return (
    <View style={styles.fillBackground}>
      <View style={styles.content}>
        <View style={styles.subcontainer}>
          <TouchableOpacity>
            <Text style={styles.content__heading}>Talhões</Text>
          </TouchableOpacity>
        </View>
        <Search placeholder="Pesquisar por talhões" searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <Text style={styles.favoriteStyle}>Todos os talhões</Text>
        <List
          data={handleSearch}
          render={(item) => <Talhoes data={item as Area} handleSelectId={() => handleSelectId(item as Area)} />}
        />
      </View>
    </View>
  );
};
