import { StyleSheet } from 'react-native';
import theme from '../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  logoImage: {
    width: 240,
    height: 90,
  },
  logoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 76,
  },
  inputsContainer: {
    marginTop: 30,
    marginLeft: 16,
  },
  textEmail: {
    color: theme.colors.white,
    fontFamily: theme.fonts.title700,
  },
  textInput: {
    marginTop: 8,
    width: '100%',
    marginRight: 16,
    height: 46,
    borderRadius: 8,
    padding: 8,
    backgroundColor: '#fff',
    borderWidth: 1,
    // outlineStyle: 'none',
  },
  textSenha: {
    color: theme.colors.white,
    fontFamily: theme.fonts.title700,
    marginTop: 16,
  },
  buttonContainer: {
    marginTop: 50,
    marginLeft: 16,
    marginRight: 16,
  },
  esqueceuContainer: {
    marginTop: 60,
    marginLeft: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%',
  },
  textAjuda: {
    color: theme.colors.white,
    fontFamily: theme.fonts.title700,
    textDecorationLine: 'underline',
  },
  textEsqueci: {
    color: theme.colors.white,
    fontFamily: theme.fonts.title700,
    textDecorationLine: 'underline',
  },
  textInvalida: {
    alignSelf: 'flex-end',
    marginRight: 16,
    color: theme.colors.warninglight,
    zIndex: 2,
    fontSize: 12,
  },
});
