import api from '..';
import { AxiosResponse } from 'axios';

export type Histogram = {
  color: string;
  limit_superior: number;
};

export type UnityFertility = {
  name: string;
  values: {
    areas_in_interpolations: Array<number>;
    histogram: Histogram[];
    id: number;
    stats?: {
      coeficiente_variacao: number;
      desvio_padrao: number;
      max: number;
      media: number;
      min: number;
      tamanho: number;
    };
    web_map: {
      image_url: string;
      size: Array<number>;
      bbox: {
        xmax: number;
        xmin: number;
        ymax: number;
        ymin: number;
      };
    };
  }[];
};

export type FerlilitiesValues =
  | {
      name: string;
      values: UnityFertility[];
    }
  | undefined;

export type FertilitiesByAreaSelected = {
  response: FerlilitiesValues[];
};

export const getFertilitiesFromAreaSelected = (
  areaSelectedId: number,
): Promise<AxiosResponse<FertilitiesByAreaSelected>> => {
  return api.get(`/farms/area/${areaSelectedId}`);
};

export type image = {
  histogram: Histogram[];
  areas_in_index: number[];
  id: number;
  web_map: {
    image_url: string;
    bbox: {
      xmin: number;
      ymin: number;
      ymax: number;
      xmax: number;
    };
    size: [number, number];
  };
  area: {
    stats: {
      q1: number;
      q3: number;
      dose_media: number;
      area_aplicacao: number;
      min: number;
      max: number;
      tamanho: number;
      media_area_total: number;
      consumo_total: number;
      media: number;
      coeficiente_variacao: number;
      desvio_padrao: number;
    };
    id: number;
    block: string;
    name: string;
  };
};

export type ValueSatellite =
  | {
      images: image[];
      name: string;
    }
  | undefined;

export type SatelliteValues = {
  name: string;
  values: ValueSatellite[];
};

export type SatelliteByAreaSelected = {
  response: SatelliteValues[];
};

export const getSatelliteFromAreaSelected = (areaSelectedId: number): Promise<AxiosResponse<SatelliteValues[]>> => {
  return api.get(`/farms/satelite/${areaSelectedId}`);
};
