import { Client, ClientResponse } from '../../services/api/Clients';
import { DEFAULT_SYNC_DATE } from '../../utils/constantes';

export type ClientState = ClientResponse & {
  lastSyncDate: string;
  selectedClient?: Client;
};

export interface SetClient {
  type: 'setClientState';
  payload: ClientResponse;
}

export interface UpdateClient {
  type: 'updateClientState';
  payload: ClientResponse;
}

export interface SetSelectedClient {
  type: 'setSelectedClient';
  payload: Client;
}

export interface ResetClientSession {
  type: 'resetClientState';
}

export type ClientActions = SetClient | UpdateClient | SetSelectedClient | ResetClientSession;

export const DEFAULT_CLIENT_STATE: ClientState = {
  clients: [],
  selectedClient: undefined,
  lastSyncDate: DEFAULT_SYNC_DATE,
};

const ClientReducer = (state: ClientState, action: ClientActions): ClientState => {
  switch (action.type) {
    case 'setClientState': {
      return {
        ...state,
        clients: action.payload.clients,
        lastSyncDate: new Date().toISOString(),
      };
    }

    case 'updateClientState': {
      return {
        ...state,
        clients: state.clients.concat(...action.payload.clients),
        lastSyncDate: new Date().toISOString(),
      };
    }

    case 'setSelectedClient': {
      return {
        ...state,
        selectedClient: action.payload,
      };
    }

    case 'resetClientState': {
      return DEFAULT_CLIENT_STATE;
    }

    default:
      return state;
  }
};

export default ClientReducer;
