import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';
import theme from '../../../global/styles/theme';

export default {
  Point: new Style({
    image: new CircleStyle({
      radius: 10,
      fill: new Fill({
        color: 'white',
      }),
      stroke: new Stroke({
        color: theme.colors.green1,
        width: 4,
      }),
    }),
  }),
  Polygon: new Style({
    stroke: new Stroke({
      color: 'blue',
      lineDash: [4],
      width: 3,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 255, 0.1)',
    }),
  }),
  MultiPolygon: (text: string) =>
    new Style({
      stroke: new Stroke({
        color: 'blue',
        width: 1,
        lineDash: [4],
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 255, 0.1)',
      }),
      text: new Text({
        text: text,
        backgroundStroke: new Stroke({
          width: 20,
          color: 'white',
          lineCap: 'square',
        }),
      }),
    }),
  MultiPolygonSelected: (text: string) => {
    return new Style({
      stroke: new Stroke({
        color: '#3966AE',
        width: 1,
      }),
      fill: new Fill({
        color: '#3966AE',
      }),
      text: new Text({
        text: text,
        fill: new Fill({
          color: 'white',
        }),
        backgroundStroke: new Stroke({
          width: 20,
          color: 'rgba(0, 180, 255, 0.3)',
          lineCap: 'square',
        }),
      }),
    });
  },
};
