import { AuthResponse } from '../../services/api/Auth';

export type AuthState = AuthResponse;

export interface SetSession {
  type: 'setUserSession';
  payload: AuthState;
}

export interface ResetSession {
  type: 'resetUserSession';
}

export type AuthActions = SetSession | ResetSession;

export const DEFAULT_AUTH_STATE: AuthState = {
  token: '',
  userId: 0,
  grants: [],
  nome: '',
  email: '',
  clientId: 0,
};

const AuthReducer = (state: AuthState, action: AuthActions): AuthState => {
  switch (action.type) {
    case 'resetUserSession': {
      return DEFAULT_AUTH_STATE;
    }

    case 'setUserSession': {
      return action.payload;
    }

    default:
      return state;
  }
};

export default AuthReducer;
