import { StyleSheet, TouchableOpacityProps } from 'react-native';
import styled from 'styled-components/native';
import theme from '../../global/styles/theme';

interface TouchableAlterProps extends TouchableOpacityProps {
  orientation: 'left' | 'right';
}

export const styles = StyleSheet.create({
  content: {
    width: '100%',
    flex: 1,
    zIndex: 2,
  },
  subtitleText: {
    fontFamily: theme.fonts.title700,
    fontSize: 12,
    color: theme.colors.grey5,
    marginTop: 16,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: '700',
    marginLeft: 8,
    marginTop: 4,
  },
  subContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 16,
  },
  outroContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 16,
  },
});

export const TouchableAlter = styled.TouchableOpacity<TouchableAlterProps>`
  height: 46px;
  width: 40px;
  border-top-left-radius: ${(props) => (props.orientation === 'left' ? 8 : 0)}px;
  border-bottom-left-radius: ${(props) => (props.orientation === 'left' ? 8 : 0)}px;
  border-top-right-radius: ${(props) => (props.orientation === 'right' ? 8 : 0)}px;
  border-bottom-right-radius: ${(props) => (props.orientation === 'right' ? 8 : 0)}px;
  background: ${(props) => (!props.disabled ? `${theme.colors.blue2}` : `${theme.colors.grey2}`)};
  justify-content: center;
  align-items: center;
`;
