import React from "react";
import Svg, { Path, SvgProps, Circle } from "react-native-svg";

const Centralizarmapa24px: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <Circle cx="8" cy="7.99999" r="4.40001" stroke="#3966AE" />
      <Circle cx="7.99997" cy="8.00003" r="1.6" fill="#3966AE" />
      <Path d="M7.99998 2V3.6" stroke="#3966AE" strokeLinecap="round" />
      <Path d="M7.99998 12.4V14" stroke="#3966AE" strokeLinecap="round" />
      <Path d="M14 8L12.4 8" stroke="#3966AE" strokeLinecap="round" />
      <Path d="M3.59998 8L1.99998 8" stroke="#3966AE" strokeLinecap="round" />
    </Svg>
  );
};

export default Centralizarmapa24px;
