import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../contexts';
import { userRoles } from '../services/api/Auth';
import { Farm, GetFarms } from '../services/api/Farms';
import { GetFarmStorage, SetFarmStorage } from '../services/database/FarmStorage';
import { DEFAULT_SYNC_DATE } from '../utils/constantes';

export function useFarms() {
  const { farmState, farmDispatch, authState, clientState } = useContext(AppContext);
  const userGrants = authState.grants[0];
  const { role } = userGrants;
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (clientState.selectedClient && farmState.farms.length && update) {
      SetFarmStorage(clientState.selectedClient.id, farmState);
      setUpdate(false);
    }
  }, [update]);

  const setFarm = useCallback(async () => {
    if (role !== userRoles.FARMER && !clientState.selectedClient) return;
    farmDispatch({
      type: 'resetFarmSession',
    });
    const clientId = clientState?.selectedClient?.id || authState.clientId;

    const stored = await GetFarmStorage(clientId);

    if (stored && stored.farms.length) {
      farmDispatch({
        type: 'setFarmSession',
        payload: stored,
      });
    }

    const { data } = await GetFarms({
      userId: authState.userId,
      clientId: clientId,
      lastSyncDate: stored ? stored.lastSyncDate : DEFAULT_SYNC_DATE,
    });

    if (data && data.farms.length) {
      farmDispatch({
        type: 'updateFarmState',
        payload: {
          clientId: clientId,
          ...data,
        },
      });
      setUpdate(true);
      return;
    }
  }, [farmState]);

  const setSelectedFarm = (farm: Farm | undefined) => {
    farmDispatch({
      type: 'setSelectedFarm',
      payload: farm,
    });
  };

  const farms = useMemo(() => farmState.farms, [farmState]);

  const selectedFarm = useMemo(() => farmState.selectedFarm, [farmState]);

  return { farms, setFarm, selectedFarm, setSelectedFarm };
}
