import React, { useState } from 'react';
import { Alert } from 'react-native';
import { Div, ScrollDiv, Button, Icon } from 'react-native-magnus';

import Text from '../../components/Text';
import { MapState } from '../../contexts/reducers/MapReducer';
import theme from '../../global/styles/theme';
import { StyledView, styles } from './styles';

interface ElementsSubtitleProps {
  elementSelected: MapState;
}

export const ElementsSubtitle = ({ elementSelected }: ElementsSubtitleProps) => {
  const [isSubtitlesOpen, setIsSubtitlesOpen] = useState(true);

  if (!elementSelected.histogram || !elementSelected.histogram.length) {
    return null;
  }

  const formattedSubtitles = elementSelected?.histogram.map((item, index) => {
    return {
      color:
        elementSelected.histogram && elementSelected.histogram[index + 1] && elementSelected.histogram[index + 1].color,
      limiteSuperior: item.limit_superior.toFixed(2),
      limiteInferior:
        (elementSelected.histogram &&
          elementSelected.histogram[index + 1] &&
          elementSelected.histogram[index + 1].limit_superior.toFixed(2)) ||
        null,
    };
  });

  return (
    <StyledView>
      {isSubtitlesOpen && (
        <>
          <Div bg="white" h={28} w="100%" pt={6} roundedTop={12} shadow="xs" alignItems="center">
            <Text fontWeight="700">{elementSelected.name}</Text>
          </Div>
          <ScrollDiv roundedBottom={12} bg="white" contentContainerStyle={styles.containerScrollView}>
            {formattedSubtitles.map(
              (item, index) =>
                item.limiteInferior &&
                item.limiteSuperior && (
                  <Div
                    key={`${item.limiteSuperior}-${index}`}
                    flex={1}
                    flexDir="row"
                    pl={6}
                    pr={6}
                    alignItems="center"
                    mt={3}
                  >
                    <Div w={25} h={15} bg={item.color} mr={6} rounded={4} />
                    <Text>
                      {item.limiteSuperior.toString().replace('h', ',') +
                        ' ' +
                        '-' +
                        ' ' +
                        item.limiteInferior.toString().replace('h', ',')}
                    </Text>
                  </Div>
                ),
            )}
          </ScrollDiv>
        </>
      )}

      <Button
        onPress={() => setIsSubtitlesOpen(!isSubtitlesOpen)}
        alignItems="center"
        justifyContent="center"
        bg="white"
        rounded={12}
        w={40}
        h={40}
        ml={12}
        mt={4}
      >
        <Icon
          fontSize={18}
          name={isSubtitlesOpen ? 'left' : 'right'}
          fontFamily="AntDesign"
          color={theme.colors.blue2}
        />
      </Button>
    </StyledView>
  );
};
