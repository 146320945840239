import React from "react";
import Svg, { Path, SvgProps, Circle, Rect } from "react-native-svg";

const SateliteIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <Rect width="24" height="24" rx="4" fill="#F8D6D2" />
      <Path
        d="M19.0001 7.75659L16.2433 4.99981L12.7973 8.44579L15.5541 11.2026L19.0001 7.75659Z"
        stroke="#DC3220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M11.2027 15.5542L8.44591 12.7974L4.99993 16.2434L7.75672 19.0002L11.2027 15.5542Z"
        stroke="#DC3220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13.4396 11.2897L12.7319 10.582C12.1463 9.99641 11.1968 9.99641 10.6112 10.582C10.0256 11.1676 10.0256 12.1171 10.6112 12.7027L11.3189 13.4104C11.9046 13.9961 12.854 13.9961 13.4396 13.4104C14.0253 12.8248 14.0253 11.8754 13.4396 11.2897Z"
        stroke="#DC3220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 13H16C16 14.6569 14.6569 16 13 16V15C14.1046 15 15 14.1046 15 13Z"
        fill="#DC3220"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 13H17.5C17.5 15.4853 15.4853 17.5 13 17.5V16.5C14.933 16.5 16.5 14.933 16.5 13Z"
        fill="#DC3220"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 13H19C19 16.3137 16.3137 19 13 19V18C15.7614 18 18 15.7614 18 13Z"
        fill="#DC3220"
      />
    </Svg>
  );
};

export default SateliteIcon;
