import { StyleSheet, Dimensions } from 'react-native';
import theme from '../../global/styles/theme';

const Screen = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
};

export const styles = StyleSheet.create({
  content: {
    zIndex: 3,
    width: '100%',
    flex: 1,
  },
  subtitleText: {
    fontFamily: theme.fonts.title700,
    fontSize: 12,
    color: theme.colors.grey5,
    marginTop: 16,
  },

  content__modal: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 6 },
    shadowOpacity: 0.45,
    shadowRadius: 16,
  },

  content__subheading: {
    marginBottom: 2,

    fontSize: 14,
    fontWeight: '600',
    color: '#ccc',
  },

  content__heading: {
    fontSize: 14,
    marginLeft: 8,
    fontFamily: theme.fonts.title700,
  },

  content__description: {
    paddingTop: 10,
    paddingBottom: 10,

    fontSize: 15,
    fontWeight: '200',
    lineHeight: 22,
    color: '#666',
  },
  cart_layout: {
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: 56,
  },
  cart_detail: {
    paddingLeft: 20,
    paddingRight: 20,
    height: 52,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon_claw: {
    position: 'absolute',
    top: 2,
    bottom: 0,
    left: Screen.width / 2 - 16,
    right: Screen.width / 2 - 16,
    width: 32,
    height: 6,
  },
  icon_checkout: {
    width: 16,
    height: 16,
  },
  icon_cart: {
    width: 22,
    height: 23,
  },

  favoriteStyle: {
    fontSize: 12,
    fontWeight: '700',
    color: '#737373',
    marginTop: 22,
    marginLeft: 16,
  },

  item: {
    width: 150,

    backgroundColor: '#3966ae',
    padding: 20,
    marginVertical: 8,
    marginHorizontal: 16,
    borderRadius: 8,
  },
  title: {
    fontSize: 14,
    fontWeight: '700',
    color: '#fff',
    marginLeft: 16,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: '700',
    marginLeft: 8,
    marginTop: 4,
  },
  subcontainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 16,
  },
  favoritosContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 16,
  },
  flatlistContainer: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 16,
    marginTop: 8,
    marginLeft: 16,
  },
});
