import React, { useEffect, useMemo, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Search } from '../../Search';
import { List } from '../../List';
import { Fazendas } from '../../Fazendas';
import { Farm } from '../../../services/api/Farms';

import { useFarms } from '../../../hooks/Farm';

import { styles } from '../style';
import { search } from '../../../utils/Search';
import { useNavigation } from '@react-navigation/native';
import { useAreas } from '../../../hooks/Area';

export const NotasFarm = () => {
  const { setFarm, farms, setSelectedFarm } = useFarms();
  const { setSelectedArea } = useAreas();

  const navigation = useNavigation<any>();

  const [searchQuery, setSearchQuery] = React.useState('');

  useEffect(() => {
    setFarm();
  }, []);

  const handleSearch = useMemo(() => search(searchQuery, farms), [farms, searchQuery]);

  function handleSelectId(data: Farm) {
    setSelectedFarm(data);
    setSelectedArea(undefined);
    navigation.navigate('Notas');
  }

  return (
    <View style={styles.fillBackground}>
      <View style={styles.content}>
        <View style={styles.subcontainer}>
          <TouchableOpacity>
            <Text style={styles.content__heading}>Fazendas</Text>
          </TouchableOpacity>
        </View>
        <Search placeholder="Pesquisar por fazendas" searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <Text style={styles.favoriteStyle}>Todos as fazendas</Text>
        <List
          data={handleSearch}
          render={(item) => <Fazendas data={item as Farm} handleSelectId={() => handleSelectId(item as Farm)} />}
        />
      </View>
    </View>
  );
};
