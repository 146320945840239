import React from 'react';
import { Div, Icon, Text } from 'react-native-magnus';
import { TouchableOpacity, StyleSheet, ActivityIndicator } from 'react-native';
import theme from '../../global/styles/theme';

type ModalReportProps = {
  text: string;
  loading: boolean;
  handleSubmitReport: () => void;
  closeModalReport: () => void;
};

export const ModalReportHeader = ({ text, loading, handleSubmitReport, closeModalReport }: ModalReportProps) => {
  return (
    <Div
      flexDir="row"
      justifyContent="space-between"
      alignItems="center"
      w="100%"
      flex={1}
      borderBottomWidth={1}
      borderBottomColor={theme.colors.grey2}
      pb={16}
    >
      <Div w={120} flexDir="row" mt={26} ml={12}>
        <TouchableOpacity onPress={closeModalReport}>
          <Icon name="close" fontFamily="EvilIcons" fontSize={24} />
        </TouchableOpacity>
        <Text ml={12} mt={3} fontWeight="bold">
          Nova nota 
        </Text>
      </Div>
      <TouchableOpacity disabled={loading} onPress={handleSubmitReport} style={styles.buttonSave}>
        {loading ? (
          <ActivityIndicator color={theme.colors.blue2} />
        ) : (
          <Text fontWeight="bold" color={theme.colors.blue2}>
            Salvar
          </Text>
        )}
      </TouchableOpacity>
    </Div>
  );
};

const styles = StyleSheet.create({
  buttonSave: {
    marginRight: 16,
    marginTop: 24,
    marginBottom: 6,
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
