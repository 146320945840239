import * as React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import { MaisScreen } from '../screens/Tabs/MaisTab';
import { NotasStack } from '../screens/Tabs/NotasTab';
import { BuscarScreen } from '../screens/Tabs/BuscarTab';
import { AgricultorScreen } from '../screens/Tabs/HomeTab';

import HomeBottonTabsIcon from '../../assets/Svg/HomeBottonTabsIcon';
import MaisBottonTabsIcon from '../../assets/Svg/MaisBottonTabsIcon';
import NotasBottonTabsIcon from '../../assets/Svg/NotasBottonTabsIcon';
import BuscarBottonTabsIcon from '../../assets/Svg/BuscarBottonTabsIcon';
import NovidadesBottonTabsIcon from '../../assets/Svg/NovidadesBottonTabsIcon';

import theme from '../global/styles/theme';

const Tab = createBottomTabNavigator();

const title = (text: string) => `InCeres Conveniência | ${text}`;

export function PrivateRoutes() {
  return (
    <Tab.Navigator
      screenOptions={{
        tabBarActiveTintColor: '#3966AE',
        tabBarLabelStyle: { fontSize: 10, fontFamily: theme.fonts.text400 },
        tabBarLabelPosition: 'below-icon',
        tabBarStyle: { borderTopRightRadius: 8, borderTopLeftRadius: 8 },
        headerShown: false,
      }}
    >
      <Tab.Screen
        name="Map"
        component={AgricultorScreen}
        options={{
          title: title('Início Mapa'),
          tabBarLabel: 'Início',
          tabBarIcon: ({ color }) => <HomeBottonTabsIcon width={24} height={24} color={color} />,
        }}
      />
      <Tab.Screen
        name="Notas"
        component={NotasStack}
        options={{
          title: title('Notas'),
          tabBarLabel: 'Notas',
          tabBarIcon: ({ color }) => <NotasBottonTabsIcon width={24} height={24} color={color} />,
        }}
      />
      <Tab.Screen
        name="Buscar"
        component={BuscarScreen}
        options={{
          title: title('Buscar'),
          tabBarLabel: 'Buscar',
          tabBarIcon: ({ color }) => <BuscarBottonTabsIcon width={24} height={24} color={color} />,
        }}
      />

      <Tab.Screen
        name="Novidades"
        component={BuscarScreen}
        options={{
          title: title('Novidades'),
          tabBarLabel: 'Novidades',
          tabBarIcon: ({ color }) => <NovidadesBottonTabsIcon width={24} height={24} color={color} />,
        }}
      />
      <Tab.Screen
        name="Mais"
        component={MaisScreen}
        options={{
          title: title('Mais'),
          tabBarLabel: 'Mais',
          tabBarIcon: ({ color }) => <MaisBottonTabsIcon width={24} height={24} color={color} />,
        }}
      />
    </Tab.Navigator>
  );
}
