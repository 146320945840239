import { StyleSheet } from 'react-native';
import theme from '../../global/styles/theme';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 1,
    backgroundColor: theme.colors.grey2,
    marginBottom: 2,
    marginVertical: 1,
    alignSelf: 'flex-end',
  },
});
