import React from 'react';
import Svg, { Path, SvgProps, Rect, Circle } from 'react-native-svg';

const TalhaoListIcon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width={props.width} height={props.height} viewBox="0 0 24 24" fill="none">
            <Rect width="24" height="24" rx="4" fill="#F9F2E1" />
            <Path
                d="M8.45 10.8999L6.44999 14.9L12.45 18.4L17.45 8.90002L12.45 5.90002L10.45 9.90002L8.45 10.8999Z"
                stroke="#E1BE6A"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="0.1 1.1"
            />
        </Svg>
    );
};

export default TalhaoListIcon;
