import React from "react";
import Svg, { Path, SvgProps, Rect } from "react-native-svg";

const UserIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <Rect width="24" height="24" rx="4" fill="#DDF0FB" />
      <Path
        d="M15.3334 9.33333C15.3334 11.1743 13.841 12.6667 12.0001 12.6667C10.1591 12.6667 8.66675 11.1743 8.66675 9.33333C8.66675 7.49238 10.1591 6 12.0001 6C13.841 6 15.3334 7.49238 15.3334 9.33333Z"
        fill="#1A85FF"
        stroke="#1A85FF"
      />
      <Path
        d="M17.3334 17.9998H12.0001H6.66675C6.66675 15.0543 9.05456 12.6665 12.0001 12.6665C14.9456 12.6665 17.3334 15.0543 17.3334 17.9998Z"
        fill="#1A85FF"
        stroke="#1A85FF"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default UserIcon;
