import { useCallback, useState, useEffect } from 'react';
import { BodyProblemReport, getProblemsToReport, sendProblemsToReport } from '../services/api/ProblemsReport';
import { AlertMessage } from '../utils/AlertMessage';

export interface ProblemReport {
  categoryId: number;
  category_id: number;
  createdAt: string;
  id: number;
  deletedAt: string | null;
  name: string;
  updatedAt: string | null;
}

export const useProblemsReport = () => {
  const [problems, setProblems] = useState<ProblemReport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getProblems = useCallback(async () => {
    try {
      const response = await getProblemsToReport();
      const data: ProblemReport[] = response.data;
      const indexOfRemovedObject = data.findIndex((item) => {
        return item.name === 'Gafanhotos';
      });
      data.splice(indexOfRemovedObject, 1);

      setProblems(data);
    } catch (err) {
      setProblems([]);
      console.log(err);
      console.error(err);
    }
  }, []);

  const sendProblemReport = async (body: BodyProblemReport, closeModalReport: Function) => {
    const message = 'Nota criada com sucesso!';
    try {
      setLoading(true);
      await sendProblemsToReport(body);
      AlertMessage(message);
    } catch (err) {
      const errorMessage = 'Desculpe, ocorreu um erro ao atribuir uma nota!';
      AlertMessage(errorMessage);
      console.error(err);
    } finally {
      closeModalReport();
      setLoading(false);
    }
  };

  useEffect(() => {
    getProblems();
  }, [getProblems]);

  return { problems, sendProblemReport, loading };
};
