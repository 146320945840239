import { useContext, useMemo } from 'react';
import { AppContext } from '../contexts';
import { MapState } from '../contexts/reducers/MapReducer';

export function useMapElements() {
  const { mapState, mapDispatch } = useContext(AppContext);

  const setElement = (element: MapState) => {
    mapDispatch({
      type: 'SetSelectedElement',
      payload: element,
    });
  };

  const resetMapState = () => {
    mapDispatch({
      type: 'resetSelectedElementState',
    });
  };

  const element = useMemo(() => mapState, [mapState]);

  return { setElement, resetMapState, element };
}
