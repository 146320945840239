import React, { useEffect, useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Search } from '../../Search';
import { Client } from '../../../services/api/Clients';
import { List } from '../../List';
import { Agricultores } from '../../Agricultores';

import { useClient } from '../../../hooks/Client';

import { styles } from '../style';
import { search } from '../../../utils/Search';
import { useNavigation } from '@react-navigation/native';
import { useFarms } from '../../../hooks/Farm';
import { useAreas } from '../../../hooks/Area';

export const NotasFarmer = () => {
  const { setClients, clients, setSelectedClient } = useClient();
  const { setSelectedFarm } = useFarms();
  const { setSelectedArea } = useAreas();
  const navigation = useNavigation<any>();

  const [searchQuery, setSearchQuery] = React.useState('');

  useEffect(() => {
    setClients();
  }, []);

  const handleSearch = useMemo(() => search(searchQuery, clients), [clients, searchQuery]);

  function handleAgricultor(data: Client) {
    setSelectedClient(data);
    setSelectedFarm(undefined);
    setSelectedArea(undefined);
    navigation.navigate('Notas');
  }

  return (
    <View style={styles.fillBackground}>
      <View style={styles.content}>
        <View style={styles.subcontainer}>
          <TouchableOpacity>
            <Text style={styles.content__heading}>Agricultores</Text>
          </TouchableOpacity>
        </View>
        <Search placeholder="Pesquisar por agricultores" searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <Text style={styles.favoriteStyle}>Todos os agricultores</Text>
        <List
          data={handleSearch}
          render={(item) => (
            <Agricultores data={item as Client} handleSelectId={() => handleAgricultor(item as Client)} />
          )}
        />
      </View>
    </View>
  );
};
