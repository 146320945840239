import { useCallback, useContext, useMemo, useState } from 'react';
import { AppContext } from '../contexts';
import { AuthState } from '../contexts/reducers/AuthReducer';
import api from '../services/api';
import { authenticate, AuthRequest, recoverPassword } from '../services/api/Auth';
import { DeleteAuthStorage, GetAuthStorage, SetAuthStorage } from '../services/database/authStorage';

import * as Sentry from '@sentry/browser';
import { Alert, Platform } from 'react-native';
import { AlertMessage } from '../utils/AlertMessage';

type ErrorLoginProps = {
  response: {
    status: number;
    data: {
      name: string;
    };
  };
};

type ErrorMessage = {
  BlockedUserProfile: string;
  InvalidUserProfile: string;
};

export function useAuth() {
  const { authState, authDispatch, clientDispatch, farmDispatch, areaDispatch } = useContext(AppContext);

  const MAP_ERRORS: ErrorMessage = {
    BlockedUserProfile:
      'Você errou a senha várias vezes e seu usuário foi bloqueado. Entre em contato com o suporte para mais informações!',
    InvalidUserProfile: 'Usuário e/ou senha inválidos!',
  };

  const signIn = useCallback(
    async (loginData: AuthRequest): Promise<AuthState | undefined> => {
      const formattedLoginData = {
        email: loginData.email.trim(),
        password: loginData.password.trim(),
      };
      try {
        const { data } = await authenticate(formattedLoginData);
        if (!!data && !!data.token) {
          SetAuthStorage(data);
          setSessionFromLogin(data);
          Sentry.setUser({ email: loginData.email });
        }
        return data;
      } catch (err) {
        const error = err as ErrorLoginProps;
        const { name } = error.response.data;
        AlertMessage(MAP_ERRORS[name as keyof ErrorMessage] || 'Ocorreu um erro ao tentar fazer o login!');
        return;
      }
    },
    [authState],
  );

  const setSessionFromLogin = useCallback(
    (loginData: AuthState) => {
      // TODO: REMOVER QUANDO ESTIVER RETORNANDO NA API
      loginData.userId = !!loginData.userId ? loginData.userId : 5283;
      setAxiosToken(loginData.token);
      authDispatch({
        type: 'setUserSession',
        payload: loginData,
      });
    },
    [authState],
  );

  const setAxiosToken = useCallback(
    (token: string) => {
      if (!!token) {
        api.defaults.headers.Authorization = `Bearer ${token}`;
      } else {
        api.defaults.headers.Authorization = null;
      }
    },
    [authState],
  );

  const getAuth = useCallback(async () => {
    const storage = await GetAuthStorage();
    if (!!!authState.token && !!storage) {
      setSessionFromLogin(storage);
      return;
    }
  }, [authState]);

  const isLogged = useMemo((): boolean => {
    getAuth();
    return !!authState.token;
  }, [authState]);

  const resetDispatchs = () => {
    authDispatch({
      type: 'resetUserSession',
    });
    clientDispatch({
      type: 'resetClientState',
    });
    farmDispatch({
      type: 'resetFarmSession',
    });
    areaDispatch({
      type: 'resetAreaState',
    });
  };

  const signOut = useCallback((): void => {
    DeleteAuthStorage();

    setAxiosToken('');
    resetDispatchs();
  }, [authState]);

  const forgotPassword = useCallback(async (email: string) => {
    return recoverPassword({ email })
      .then(() => 'Verifique o seu E-mail')
      .catch(() => 'Usuário não encontrado, contate o suporte !');
  }, []);

  /**
   * Obtém os dados do usuário armazenados no storage do aplicativo
   * @returns Dados do usuário armazenados localmente
   */
  const userData = useMemo(() => authState, [authState]);

  const userGrants = useMemo(() => authState.grants[0], [authState]);

  return {
    signIn,
    signOut,
    isLogged,
    setAxiosToken,
    setSessionFromLogin,
    userData,
    userGrants,
    forgotPassword,
  };
}
