import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CDN } from '../../utils/Urls';
import { FerlilitiesValues } from '../api/ElementsFromMap';

export const STORAGE_AUTH_ALIAS = '@Fertility';

const cacheImagesFromFertilities = (fertilitiesState: FerlilitiesValues[]) => {
  if (Platform.OS != 'web') {
    console.warn(`[${Platform.OS}] Este ambiente ainda nao possui cache de imagens implementado`);
    return;
  }

  fertilitiesState.forEach((fertilityItem) => {
    fertilityItem.values.forEach((valueItem) => {
      if (valueItem.values) {
        Object.keys(valueItem.values).forEach((key, index) => {
          if (valueItem.values[index]) {
            const imageUrl = `${CDN}${valueItem.values[index].web_map.image_url}`;
            new Image().src = imageUrl; // Force cache image in navigator
          }
        });
      }
    });
  });
};

export const GetFertilitiesStorage = async (id: number): Promise<FerlilitiesValues[]> => {
  const fertilities = await AsyncStorage.getItem(`${STORAGE_AUTH_ALIAS}_${id}`);
  return !!fertilities ? JSON.parse(fertilities) : null;
};

export const SetFertilitiesStorage = async (id: number, fertilitiesState: FerlilitiesValues[]) => {
  cacheImagesFromFertilities(fertilitiesState);
  await AsyncStorage.setItem(`${STORAGE_AUTH_ALIAS}_${id}`, JSON.stringify(fertilitiesState));
};

export const DeleteFertilitiesStorage = async (id: number) => {
  await AsyncStorage.removeItem(`${STORAGE_AUTH_ALIAS}_${id}`);
};
