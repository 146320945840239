import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { StyleSheet, Dimensions, Animated, TouchableOpacity } from 'react-native';
import { Icon } from 'react-native-magnus';
import theme from '../../../global/styles/theme';

const { width, height } = Dimensions.get('screen');

const DEFAULT_MODAL_HEIGHT = '70%';

const CustomBottomSheet = (props, ref) => {
  const [alignment] = useState(new Animated.Value(0));
  const [visible, setVisible] = useState(false);
  const [modalHeight, setModalHeight] = useState(DEFAULT_MODAL_HEIGHT);
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    setVisible(props.visible || false);
  }, [props.visible]);

  useImperativeHandle(ref, () => ({
    open: () => {
      setVisible(true);
    },
    close: () => {
      setVisible(false);
    },
    slideUp: (modalHeight: string) => {
      if (modalHeight) setModalHeight(modalHeight);
      slideUpBottomSheet();
    },
    slideDown: () => {
      setModalHeight(DEFAULT_MODAL_HEIGHT);
      slideDownBottomSheet();
    },
  }));

  const slideUpBottomSheet = () => {
    setIsOpened(true);
    Animated.timing(alignment, {
      toValue: 1,
      duration: 250,
      useNativeDriver: true,
    }).start();
  };

  const slideDownBottomSheet = () => {
    setIsOpened(false);
    Animated.timing(alignment, {
      toValue: 0,
      duration: 250,
      useNativeDriver: true,
    }).start();
  };

  const bottomSheetInterpolate = alignment.interpolate({
    inputRange: [0, 1],
    outputRange: [-height / 3, 0],
  });

  const bottomSheetStyleAnimated = {
    bottom: bottomSheetInterpolate,
  };

  const styles = StyleSheet.create({
    container: {
      backgroundColor: '#fff',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      height: modalHeight,
      // width: width / 1.1,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      marginHorizontal: 'auto',
      alignItems: 'center',
    },
    grabber: {
      marginVertical: 5,
      paddingLeft: 20,
      paddingRight: 20,
      width: width / 1.2,
      height: 20,
      borderTopWidth: 5,
      borderTopColor: 'transparent',
      borderRadius: 5,
    },
    grabberIn: {
      marginLeft: 'auto',
      marginRight: 12,
      marginTop: 12,
      height: 40,
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

  return (
    <Animated.View style={[styles.container, bottomSheetStyleAnimated, { display: visible ? 'flex' : 'none' }]}>
      <TouchableOpacity
        style={styles.grabberIn}
        onPress={() => (isOpened ? slideDownBottomSheet() : slideUpBottomSheet())}
      >
        <Icon name={isOpened ? 'arrowdown' : 'arrowup'} fontFamily="AntDesign" fontSize={28} color={theme.colors.blue1} />
      </TouchableOpacity> 
      {visible ? props.children : null}
    </Animated.View>
  );
};

export default forwardRef(CustomBottomSheet);
