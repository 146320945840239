import styled from 'styled-components/native';
import theme from '../../global/styles/theme';

export const ChangeCamera = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  background: white;
  position: absolute;
  padding: 8px;
  box-shadow: 0px 15px 20px rgba(00, 00, 157, 0.4);
  width: 60px;
  bottom: 0;
`;

export const CancelTakePicture = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  background: white;
  position: absolute;
  padding: 8px;
  box-shadow: 0px 15px 20px rgba(00, 00, 157, 0.4);
  width: 60px;
  top: 0;
  right: 0;
`;

export const TimeRecorder = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  background: red;
  position: absolute;
  padding: 8px;
  width: 120px;
  height: 60px;
  top: 0;
`;

export const TakePic = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  background: white;
  position: absolute;
  padding: 8px;
  right: 0;
  bottom: 0;
  box-shadow: 0px 15px 20px rgba(00, 00, 157, 0.4);
  width: 60px;
`;

export const RemoveImage = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  background: ${theme.colors.blue2};
  position: absolute;
  padding: 8px;
  right: 0;
  box-shadow: 0px 15px 20px rgba(00, 00, 157, 0.4);
  width: 20px;
`;
