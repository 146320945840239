import { useContext, useEffect } from 'react';
import MapContext from '../MapContext';
import OLVectorLayer from 'ol/layer/Vector';
import Select from 'ol/interaction/Select';

import FeatureStyles from '../features/Styles';
import { useFertilitiesFromArea } from '../../../hooks/FertilityFromArea';

const VectorLayerUserPosition = ({ source, style, zIndex = 0, styleSelected = null }: any) => {
  const { map } = useContext(MapContext);
  const selectSingleClick = new Select({ style: styleSelected });

  useEffect(() => {
    if (!map) return;

    let vectorLayer = new OLVectorLayer({
      source,
      style,
    });

    map.addLayer(vectorLayer);
    vectorLayer.setZIndex(zIndex);
    map.addInteraction(selectSingleClick);

    return () => {
      if (map) {
        map.removeLayer(vectorLayer);
      }
    };
  }, [map, source]);

  return null;
};

export default VectorLayerUserPosition;
