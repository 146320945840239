import React, { useEffect, useState } from 'react';
import { TouchableOpacity, FlatList, Text, View } from 'react-native';

import { DATA } from './data';
import { styles } from './styles';

interface TopFlatListTestProps {
  title?: string;
  id?: string;
  selected?: boolean;
  onSelect: (id: any) => void;
  icon?: React.ReactNode;
  DATA?: React.ReactNode;
}

export enum TopFlatList {
  Fertility = 'Fertilidade',
  SatelliteMap = 'Imagens de satélite',
}

function Item({ id, title, selected, icon, onSelect }: TopFlatListTestProps, data = { DATA }) {
  function selectColor(titleColor: string) {
    switch (titleColor) {
      case TopFlatList.Fertility:
        return '#40B0A6';
      case TopFlatList.SatelliteMap:
        return '#E69F00';
      case '3':
        return '#D35FB7';
      case '4':
        return '#E66100';
      case '5':
        return '#0072B2';
      case '6':
        return '#DC3220';
      case '7':
        return '#4B0092';
      case '8':
        return '#56B4E9';
    }
  }

  return (
    <TouchableOpacity
      onPress={() => {
        onSelect(title);
      }}
      style={[
        styles.item,

        { backgroundColor: !!selected ? selectColor(title || '') : '#fff' },

        { transform: !!selected ? [{ scale: 1.1 }] : [{ scale: 1 }] },
      ]}
    >
      <View style={styles.Icons}>{icon}</View>
      <Text style={[styles.title, { color: selected ? '#fff' : '#000' }]}> {title} </Text>
    </TouchableOpacity>
  );
}

export default function FlatListTop({ selectedId, setSelectedId }: any) {
  return (
    <View style={styles.container}>
      <FlatList
        showsHorizontalScrollIndicator={false}
        horizontal={true}
        data={DATA}
        renderItem={({ item }) => (
          <Item
            DATA={DATA}
            icon={item.icon}
            title={item.title}
            selected={selectedId === item.title}
            onSelect={setSelectedId}
          />
        )}
        keyExtractor={(item) => item.id}
        extraData={selectedId}
      />
    </View>
  );
}
