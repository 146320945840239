import axios from 'axios';
import { URL } from '../../utils/Urls';

export const baseURL = URL;

const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response.status === 401) {
      console.error('Error 401: ', error.response);
      const requestConfig = error.config;
      return axios(requestConfig);
    }
    return Promise.reject(error);
  },
);

export default api;
