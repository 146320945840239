import React, { useState } from 'react';
import { TextInputProps } from 'react-native';

import { ContainerWrapper, Container, Error, IconContent, VisibilityWrapper, IconContainer } from './styles';

export interface InputProps extends TextInputProps {
  icon?: string;
  error?: string;
  color?: 'white' | 'gray';
  secureTextEntry?: boolean;
  disabled?: boolean;
  refInput?: (value: any) => void;
}

export function InputPassword({ icon, error, color, refInput, secureTextEntry, disabled, ...props }: InputProps) {
  const [hiddenValue, setHiddenValue] = useState<boolean>(!!secureTextEntry);

  return (
    <>
      <ContainerWrapper disabled={disabled} color={color} error={error}>
        <Container ref={refInput} color={color} editable={disabled} secureTextEntry={hiddenValue} {...props} />

        {secureTextEntry && (
          <VisibilityWrapper
            onPress={() => {
              setHiddenValue(!hiddenValue);
            }}
          >
            <IconContainer>
              <IconContent name={hiddenValue ? 'eye-slash' : 'eye'} hasError={!!error} />
            </IconContainer>
          </VisibilityWrapper>
        )}
      </ContainerWrapper>

      {error && <Error>{error}</Error>}
    </>
  );
}
