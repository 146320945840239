import React from 'react';
import { ImageBackground } from 'react-native';
import Logo from '../../../assets/Svg/Logo';
import { styles } from './styles';

const LogoImg = '../../../assets/logo.png';

export function SplashScreen() {
  return (
    <ImageBackground source={require('../../../assets/Bgsplash.png')} style={styles.container}>
      <Logo height={65} width={148} />
    </ImageBackground>
  );
}
