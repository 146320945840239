import React from "react";
import Svg, { Path, SvgProps, Circle } from "react-native-svg";

const HomeBottonTabsIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <Path
        d="M6 12V20H10V16H14V20H18V12H21L12 4L3 12H6Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default HomeBottonTabsIcon;
