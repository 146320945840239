import React, { useState } from 'react';
import { TouchableOpacity, TouchableOpacityProps, Text, View } from 'react-native';
import { styles } from './styles';
import UserIcon from '../../../assets/Svg/UserIcon';
import FavoriteIcon from '../../../assets/Svg/FavoriteIcon';
import { Client } from '../../services/api/Clients';

export type AgricultoresProps = {
  id: string;
  name: string;
  icon: string | null;
  owner: boolean;
  smId: number;
};

type Props = TouchableOpacityProps & {
  data: Client;
  handleSelectId: () => void;
};

export function Agricultores({ data, handleSelectId, ...rest }: Props) {
  return (
    <TouchableOpacity style={styles.container} onPress={handleSelectId}>
      <UserIcon height={24} width={24} />

      <View style={styles.content}>
        <View>
          <Text style={styles.title}>{data.name}</Text>
          {/* <Text style={styles.type}>{'0 Fazendas'}</Text> */}
        </View>
      </View>
    </TouchableOpacity>
  );
}
