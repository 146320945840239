import React from "react";
import Svg, { Path, SvgProps, Circle, Rect } from "react-native-svg";

const MapasFlatListIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <Rect width="24" height="24" rx="4" fill="#FAECCC" />
      <Path
        d="M10 16.0909L6 17V7.90909L10 7M10 16.0909L14 17M10 16.0909V7M14 17L18 16.0909V7L14 7.90909M14 17V7.90909M14 7.90909L10 7"
        stroke="#E69F00"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default MapasFlatListIcon;
