import React from 'react';
import { Div, Icon } from 'react-native-magnus';

import Text from '../../../components/Text';
import theme from '../../../global/styles/theme';

import { useAuth } from '../../../hooks/Auth';

import { Container, StyledImage, Row, NamedCircle, Circle, LogoutButton } from './styles';

const nameToInitials = (fullName: string) => {
  const namesArray = fullName.trim().split(' ');
  if (namesArray.length === 1) return `${namesArray[0].charAt(0).toUpperCase()}`;
  else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0).toUpperCase()}`;
};

export const MaisScreen = () => {
  const { userData, signOut } = useAuth();

  return (
    <Container>
      <StyledImage source={require('../../../../assets/logo-inceres.png')} />
      <Row>
        <Circle>
          <NamedCircle>
            <Text color={theme.colors.white}>{nameToInitials(userData.nome)}</Text>
          </NamedCircle>
        </Circle>
        <Div pl={16} pt={3}>
          <Text fontSize={14} fontWeight="700">
            {userData.nome}
          </Text>
          <Text fontSize={10} color={theme.colors.grey5}>
            {userData.email}
          </Text>
        </Div>
        <Div ml="auto">
          <LogoutButton onPress={signOut}>
            <Icon name="logout" fontFamily="MaterialIcons" color={theme.colors.blue2} fontSize={16} />
            <Text fontSize={8} fontWeight="700" color={theme.colors.blue2}>
              Sair
            </Text>
          </LogoutButton>
        </Div>
      </Row>
    </Container>
  );
};
