import React from "react";
import Svg, { Path, SvgProps, Circle, Rect } from "react-native-svg";

const NotasBottonTabsIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <Rect
        x="3.5"
        y="14"
        width="12"
        height="9"
        rx="1"
        transform="rotate(-90 3.5 14)"
        stroke={props.color}
        strokeWidth="0.996216"
        strokeLinejoin="round"
      />
      <Path
        d="M3 12L4 12"
        stroke={props.color}
        strokeWidth="0.996216"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M3 10L4 10"
        stroke={props.color}
        strokeWidth="0.996216"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M3 8L4 8"
        stroke={props.color}
        strokeWidth="0.996216"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M3 6L4 6"
        stroke={props.color}
        strokeWidth="0.996216"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M3 4L4 4"
        stroke={props.color}
        strokeWidth="0.996216"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M5.5 5L8.5 5"
        stroke={props.color}
        strokeWidth="0.996216"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M5.5 7L10.5 7"
        stroke={props.color}
        strokeWidth="0.996216"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M5.5 9L10.5 9"
        stroke={props.color}
        strokeWidth="0.996216"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M5.5 11L10.5 11"
        stroke={props.color}
        strokeWidth="0.996216"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default NotasBottonTabsIcon;
